import React, { memo } from 'react';
import { ResModalsProps } from './ResModals.type';
import { NestedModal } from '@Components/modals';
import { AuthModalsContainer } from '@Components/modals/modalsContent/Unlock/AuthModalsContainer';

export const ResModals = memo((props: ResModalsProps) => {
  const { uuid, isUnlockModal, setIsUnlockModal, isPromoModal, setIsPromoModal } = props;

  return (
    <>
      <NestedModal open={isUnlockModal} setIsConfirmModal={setIsUnlockModal}>
        <AuthModalsContainer uuid={uuid} forcedType={'phone'} eventType={'registration'} />
      </NestedModal>

      <NestedModal open={isPromoModal} setIsConfirmModal={setIsPromoModal}>
        <AuthModalsContainer uuid={uuid} forcedType={'promo'} setIsPromoModal={setIsPromoModal} />
      </NestedModal>
    </>
  );
});

ResModals.displayName = 'ResModals';
