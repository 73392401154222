import React, { memo } from 'react';
import { TextInput } from '@Components/inputs/TextInput/TextInput';
import arrowBackDesktop from '@Assets/images/icons/arrowBack.svg';
import arrowBackMobile from '@Assets/images/icons/arrowBackMobile.svg';
import { Box } from '@mui/material';
import { PromoCodeProps } from './Promo.type';
import { PromoSXMedia } from './stylesSX';

export const PromoCode = memo((props: PromoCodeProps) => {
  const { promo, setPromo, onBack, isPromoTypeProps, errMessage, resetError, isDesktop } = props;

  const onBackClick = () => {
    onBack('payment');
  };

  const setPromoValue = (value: string) => {
    resetError();
    setPromo(value);
  };

  return (
    <Box sx={PromoSXMedia.container(isDesktop, !!errMessage)}>
      {!isPromoTypeProps && (
        <Box onClick={onBackClick} sx={PromoSXMedia.backImgBox(isDesktop)}>
          <img src={isDesktop ? arrowBackDesktop : arrowBackMobile} alt='arrow back' />
        </Box>
      )}

      {!errMessage && <Box sx={PromoSXMedia.messageBox(isDesktop)} />}

      <TextInput
        value={promo}
        setValue={setPromoValue}
        message={errMessage}
        placeholder={'Вставьте промокод'}
        resetError={resetError}
      />
    </Box>
  );
});

PromoCode.displayName = 'PromoCode';
