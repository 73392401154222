import React, { FC } from 'react';
import { ProfNameTooltip } from '@Components/tooltips/profName/ProfNameTooltip';
import { Box } from '@mui/material';
import { ProfIcon } from '@Components/profIcon';
import { CardSX } from '@Components/pogressBar/Card/stylesSX';
import { ICard } from './Card.type';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const Card: FC<ICard> = ({ card }) => {
  const { title, icon_image, icon_color } = card;
  const { width: displayWidth } = useWindowDimensions();
  const shadowSize = { bot: 2, right: 2 };
  const marginRight = displayWidth < 410 ? '-8px' : '8px';
  return (
    <ProfNameTooltip title={title} placement={'top-start'}>
      <Box
        sx={[
          CardSX.iconBox,
          {
            marginRight: marginRight,
          },
        ]}>
        <ProfIcon icon={icon_image} color={icon_color} shadowSize={shadowSize} />
      </Box>
    </ProfNameTooltip>
  );
};
