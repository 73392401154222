import React, { memo, useEffect, useState } from 'react';

import { useActions, useAppSelector } from '@Hooks/';
import {
  useLazyAuthSmsQuery,
  useLazyCheckSmsQuery,
  useLazyGetPaymentUrlQuery,
  useLazyPromoAnonQuery,
  useLazyPromoQuery,
  useLazySmsRequestQuery,
  useLazyUserPriceQuery,
} from '@Store/registration';
import { queryEffect } from '@Components/modals/modalsContent/Unlock/lib/queryEffect';
import { phoneNumberRequestFormat } from '@Components/modals/modalsContent/Unlock/lib/phoneNumberRequestFormat';
import { AuthModals } from '@Components/modals/modalsContent/Unlock/AuthModals/AuthModals';
import { ActiveModalsTypes, AuthModalsContainerProps } from './AuthModalsContainer.type';
import { useBtnName, useTitleModal } from '@Components/modals/modalsContent/Unlock/lib';
import { isDisabledBtn } from '@Components/modals/modalsContent/Unlock/lib/disabledBtn';
import { useNavigate } from 'react-router-dom';
import { errorHandle } from '@Components/modals/modalsContent/Unlock/lib/errorHandle';
import { ThemeLoader } from '@Components/loader/LoaderContainer';
import { LoaderContainer } from '@Components/loader/LoaderContainer/LoaderContainer';

export const AuthModalsContainer = memo((props: AuthModalsContainerProps) => {
  const { uuid, forcedType, setIsPromoModal, eventType = 'authorization' } = props;

  const [activeTypeModal, setActiveTypeModal] = useState<ActiveModalsTypes>('phone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsValue, setSmsValue] = useState('');
  const [promo, setPromo] = useState('');
  const [errMessage, setErrMessage] = useState();
  const [isBtnActive, setIsBtnActive] = useState<boolean | undefined>();

  const { setTokens, setIsPaid } = useActions();
  const { isLogged } = useAppSelector(state => state.auth);

  const navigate = useNavigate();

  const [getPaymentUrl, { data: paymentUrl }] = useLazyGetPaymentUrlQuery();

  const [
    smsRequest,
    {
      isLoading: isLoadingSmsRequest,
      isError: isErrRegSms,
      error: errRegSms,
      isSuccess: isSuccessRegSms,
      data: smsRequestData,
    },
  ] = useLazySmsRequestQuery();

  const [
    authSmsRequest,
    {
      data: authSmsData,
      isLoading: isLoadingAuthSmsRequest,
      isError: isErrAuthSms,
      error: authSmsError,
      isSuccess: isSuccessAuthSms,
    },
  ] = useLazyAuthSmsQuery();

  const [
    checkSms,
    {
      data: tokens,
      isLoading: isLoadingCheckSms,
      isError: isCheckSmsErr,
      error: checkSmsErr,
      isSuccess: isSuccessCheckSms,
    },
  ] = useLazyCheckSmsQuery();

  const [
    promoAnon,
    {
      isLoading: isLoadingPromoAnon,
      isError: isPromoAnonErr,
      error: promoAnonErr,
      isSuccess: isSuccessPromoAnon,
    },
  ] = useLazyPromoAnonQuery();

  const [
    promoAuth,
    {
      isLoading: isLoadingPromoAuth,
      isError: isPromoAuthErr,
      error: promoAuthErr,
      isSuccess: isSuccessPromoAuth,
    },
  ] = useLazyPromoQuery();

  const [
    userPrice,
    {
      data: userPriceData,
      isLoading: isLoadingPrice,
      isError: isUserPriceErr,
      error: userPriceErr,
      isSuccess: isSuccessUserPrice,
    },
  ] = useLazyUserPriceQuery();

  const isLoading =
    isLoadingPrice ||
    isLoadingPromoAuth ||
    isLoadingPromoAnon ||
    isLoadingCheckSms ||
    isLoadingAuthSmsRequest ||
    isLoadingSmsRequest;

  useEffect(() => {
    if (isSuccessUserPrice && userPriceData) {
      setIsPaid(userPriceData.paid);
      !userPriceData.paid ? onTransitionHandle('payment') : navigate('/');
    }
    if (isUserPriceErr) {
      errorHandle(userPriceErr, setErrMessage);
    }
  }, [userPriceData]);

  useEffect(() => {
    // Перенаправить пользователя на страницу оплаты
    if (paymentUrl) {
      window.location.href = paymentUrl.confirmation_url;
    }
  }, [paymentUrl]);

  useEffect(() => {
    if (isSuccessCheckSms) {
      setErrMessage(undefined);
      eventType === 'registration' && onTransitionHandle('payment');
    }
    if (isCheckSmsErr) {
      errorHandle(checkSmsErr, setErrMessage);
    }
  }, [isSuccessCheckSms, isCheckSmsErr]);

  useEffect(() => {
    if (isSuccessAuthSms) {
      errMessage && setErrMessage(undefined);
      onTransitionHandle('sms');
    }
    if (isErrAuthSms) {
      errorHandle(authSmsError, setErrMessage);
    }
  }, [isSuccessAuthSms, isErrAuthSms, authSmsError, authSmsData]);

  useEffect(() => {
    if (isSuccessRegSms) {
      setErrMessage(undefined);
      onTransitionHandle('sms');
    }
    if (isErrRegSms) {
      authSmsRequest({ phone: phoneNumberRequestFormat(phoneNumber) });
      // errorHandle(errRegSms, setErrMessage);
    }
  }, [isSuccessRegSms, isErrRegSms, errRegSms, smsRequestData]);

  useEffect(() => {
    queryEffect(
      isSuccessPromoAnon,
      isPromoAnonErr,
      promoAnonErr,

      setErrMessage,
      onTransitionHandle,
      eventType,
      activeTypeModal,
      setIsPromoModal,
    );
  }, [isPromoAnonErr, isSuccessPromoAnon]);

  useEffect(() => {
    queryEffect(
      isSuccessPromoAuth,
      isPromoAuthErr,
      promoAuthErr,

      setErrMessage,
      onTransitionHandle,
      eventType,
      activeTypeModal,
      setIsPromoModal,
    );
  }, [isPromoAuthErr, isSuccessPromoAuth]);

  useEffect(() => {
    if (tokens) {
      setTokens(tokens);
      eventType === 'authorization' && userPrice('');
    }
  }, [tokens]);

  useEffect(() => {
    if (forcedType) {
      setActiveTypeModal(forcedType);
    }
  }, [forcedType]);

  useEffect(() => {
    const disabled = isDisabledBtn(activeTypeModal, phoneNumber, smsValue, !!errMessage);
    setIsBtnActive(disabled);
  }, [activeTypeModal, errMessage, phoneNumber, smsValue]);

  const onPaymentHandle = () => {
    window.location.href = 'http://itorient.ru/fullserviceisontheway';
    // Запросить ссылку оплаты
    // getPaymentUrl('');
  };

  const onSmsHandle = () => {
    const phoneNumberFormat = phoneNumberRequestFormat(phoneNumber);
    checkSms({
      phone: phoneNumberFormat,
      pin: smsValue,
    });
  };

  const onPromoHandle = () => {
    isLogged
      ? promoAuth({ promo })
      : promoAnon({
          promo,
          uuid,
        });
  };

  const onPhoneHandle = () => {
    const phoneNumberFormat = phoneNumberRequestFormat(phoneNumber);
    if (activeTypeModal === 'phone') smsRequest({ uuid, phone: phoneNumberFormat });
    if (activeTypeModal === 'auth') authSmsRequest({ phone: phoneNumberFormat });
  };

  const bthHandle = (activeTypeModal: ActiveModalsTypes) => {
    setIsBtnActive(true);
    switch (activeTypeModal) {
      case 'auth':
      case 'phone':
        return onPhoneHandle();
      case 'sms':
        return onSmsHandle();
      case 'promo':
        return onPromoHandle();
      case 'payment':
        return onPaymentHandle();
      default:
        return;
    }
  };

  const onTransitionHandle = (type: ActiveModalsTypes) => {
    setActiveTypeModal(type);
  };

  const resetError = () => {
    setErrMessage(undefined);
  };

  const titleContent = useTitleModal(eventType, activeTypeModal);
  const buttonName = useBtnName(activeTypeModal);
  const onBtnClick = () => bthHandle(activeTypeModal);

  return (
    <LoaderContainer isLoading={isLoading} theme={ThemeLoader.Dark}>
      <AuthModals
        title={titleContent}
        buttonName={buttonName}
        activeTypeModal={activeTypeModal}
        errMessage={errMessage}
        resetError={resetError}
        onBtnClick={onBtnClick}
        disableBtn={isBtnActive}
        onTransitionHandle={onTransitionHandle}
        phoneNumber={phoneNumber}
        promo={promo}
        promoTextValue={userPriceData?.promo}
        forcedType={forcedType}
        setPhoneNumber={setPhoneNumber}
        setPromo={setPromo}
        setSmsValue={setSmsValue}
        smsValue={smsValue}
      />
    </LoaderContainer>
  );
});

AuthModalsContainer.displayName = 'AuthModalsContainer';
