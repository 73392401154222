import {
  ActiveModalsTypes,
  EventTypes,
} from '@Components/modals/modalsContent/Unlock/AuthModalsContainer.type';
import { useTranslation } from 'react-i18next';

export const useTitleModal = (eventType: EventTypes, activeTypeModal: ActiveModalsTypes): string => {
  const { t } = useTranslation();
  switch (activeTypeModal) {
    case 'phone':
    case 'auth':
      if (eventType === 'authorization') {
        return t('Авторизация');
      }
      return t('Осталось только');
    case 'sms':
      if (eventType === 'authorization') {
        return t('Авторизация');
      }
      return t('Регистрация');
    case 'payment':
      return t('Оплата');
    case 'promo':
      return t('Примените промокод');
    default:
      return '';
  }
};
