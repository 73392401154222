import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAnonPromoQuery,
  IAuthSmsQuery,
  IAuthTokens,
  ICheckSms,
  IPaymentUrl,
  IPromoQuery,
  IRegistration,
  IRegistrationQuery,
  IUserPrice,
  IUuid,
} from '@Models/';
import { customQuery } from '@Services/';

export const registrationApi = createApi({
  reducerPath: 'registration',
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  baseQuery: customQuery,
  tagTypes: ['registration'],
  endpoints: build => ({
    smsRequest: build.query<IRegistration, IRegistrationQuery>({
      query: ({ uuid, phone }) => ({
        url: `api/v1/user/registration`,
        params: {
          uuid,
        },
        method: 'POST',
        body: {
          phone,
        },
      }),
      providesTags: ['registration'],
    }),
    authSms: build.query<IAuthTokens, IAuthSmsQuery>({
      query: ({ phone }) => ({
        url: `api/v1/user/auth-sms`,
        method: 'POST',
        body: {
          phone,
        },
      }),
    }),
    checkSms: build.query<IAuthTokens, ICheckSms>({
      query: ({ phone, pin }) => ({
        url: `api/v1/user/check-sms`,
        method: 'POST',
        body: {
          phone,
          pin,
        },
      }),
    }),
    getPaymentUrl: build.query<IPaymentUrl, string>({
      query: () => ({
        url: `/api/v1/payment/get-payment-url`,
      }),
    }),
    userPrice: build.query<IUserPrice, string>({
      query: () => ({
        url: `/api/v1/payment/user-price`,
      }),
    }),
    userPriceAnon: build.query<IUserPrice, IUuid>({
      query: ({ uuid }) => ({
        url: `/api/v1/payment/user-price-anon`,
        params: {
          uuid,
        },
      }),
    }),
    promo: build.query<string, IPromoQuery>({
      query: ({ promo }) => ({
        url: `/api/v1/payment/promo-register`,
        method: 'POST',
        body: {
          promo,
        },
      }),
    }),
    promoAnon: build.query<string, IAnonPromoQuery>({
      query: ({ uuid, promo }) => ({
        url: `/api/v1/payment/promo-anon`,
        method: 'POST',
        params: {
          uuid,
        },
        body: {
          promo,
        },
      }),
    }),
  }),
});

export const {
  useLazySmsRequestQuery,
  useLazyCheckSmsQuery,
  useLazyAuthSmsQuery,
  useLazyPromoAnonQuery,
  useLazyUserPriceAnonQuery,
  useUserPriceQuery,
  useLazyUserPriceQuery,
  useLazyPromoQuery,
  useLazyGetPaymentUrlQuery,
} = registrationApi;
