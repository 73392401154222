export const HeaderMobileSX = {
  block: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
  },
  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    borderBottom: '3px solid #363D98',
  },
  img: {
    width: '123px',
    height: '23px',
  },
  menu: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    marginRight: '9px',
  },
};
