import { RouteProps } from 'react-router-dom';
import { DemoTest } from '@Pages/Testing/DemoTest';
import { Results } from '@Pages/Results';

export enum AppRoutes {
  MAIN_DEMO = 'main_demo',
  DEMO_RESULT = 'demo_result',
}

export const RoutePathDemo: Record<AppRoutes, string> = {
  [AppRoutes.MAIN_DEMO]: '/demo-test',
  [AppRoutes.DEMO_RESULT]: '/demo_result',
};

export const routeConfigDemo: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN_DEMO]: {
    path: RoutePathDemo.main_demo,
    element: <DemoTest />,
  },
  [AppRoutes.DEMO_RESULT]: {
    path: RoutePathDemo.demo_result,
    element: <Results />,
  },
};
