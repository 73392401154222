import React, { memo } from 'react';
import { Box } from '@mui/material';
import { PedestalMobileSX } from './stylesSX';
import { PedestalMobileProps } from './PedestalMobile.type';
import pedestal from '@Assets/images/pedestal_mobile.svg';

export const PedestalMobile = memo((props: PedestalMobileProps) => {
  const { mobileIcons, onProfClick } = props;

  return (
    <Box sx={PedestalMobileSX.container}>
      <img src={pedestal} alt='loading...' />
      <Box sx={PedestalMobileSX.iconsBox}>
        {mobileIcons?.map((url, idx) => {
          return (
            <Box key={url + idx} onClick={onProfClick} sx={PedestalMobileSX.iconBox}>
              <img src={url} alt='loading...' />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

PedestalMobile.displayName = 'PedestalMobile';
