export const ChooseDirectionSX = {
  prof_btn_block: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  prof_btn: {
    width: '100%',
    minWidth: '44px',
    height: '5vh',
    display: 'flex',
    alignItems: 'center',
    color: 'neutral.dark',
    backgroundColor: 'neutral.white',
    border: '2px solid #000000',
    borderRadius: '41px',
    cursor: 'pointer',
    transition: 'all 0.5s',
    padding: '5px 8px',
    '&:hover': {
      top: '2px',
      left: '2px',
      border: '2px solid',
      borderColor: '',
      backgroundColor: 'neutral.white',
    },
  },

  titleBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    fontFamily: 'Fira Sans, sans-serif',
  },
  titleBox: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '17px',

    textTransform: 'initial',

    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '8px',
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px 0 34px',
  },
  btnName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '184px',
    fontSize: '0.8rem',
  },
};

export const ChooseDirectionSXMedia = {
  titleBox: (isDesktop: boolean) => ({}),
};
