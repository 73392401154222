export const NavigateContentSX = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    // bottom: '78px',
    bottom: '13%',
    width: '100%',
  },
  buttonsBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '366px',
  },
};
