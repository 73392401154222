export const PlayBlockSX = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 200,
    background: 'rgb(18 17 17 / 50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
