type LocalDataType = 'leftVideoProgress' | 'rightVideoProgress'

export const resetLocalData = (type?: LocalDataType) => {
  if(type){
    switch (type) {
      case 'leftVideoProgress':
        return localStorage.setItem('leftVideoProgress', JSON.stringify(0));
      case 'rightVideoProgress':
        return localStorage.setItem('rightVideoProgress', JSON.stringify(0));
      default:
        return

    }
  }else{
    localStorage.setItem('rightVideoProgress', JSON.stringify(0));
    localStorage.setItem('leftVideoProgress', JSON.stringify(0));
  }

};
