import { titleBoxHeight } from '@Pages/Testing/Desktop/lib/titleBoxHeight';

export const MobileTestingSXMedia = {
  container: (displayHeight: number) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'absolute',
    bottom: '0px',
    height: '91%',
    maxHeight: `calc(100vh - ${titleBoxHeight(displayHeight, 4)})`,
  }),

  progressBarBox: (displayHeight: number) => ({
    position: 'absolute',
    top: titleBoxHeight(displayHeight, 2),
    zIndex: 255,
  }),
};
