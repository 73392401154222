import React, { memo } from 'react';
import { Cloud } from './cloud/Cloud';
import { cloudData } from './stylesSX';

export const Clouds = memo(() => (
  <>
    {cloudData.map(({ top, right, bottom, left, size }, i) => (
      <Cloud key={i} top={top} right={right} bottom={bottom} left={left} size={size} />
    ))}
  </>
));

Clouds.displayName = 'Clouds';
