import React, { memo, useEffect, useState } from 'react';
import { PlayersDeckContainerProps } from './PlayersDeckContainer.type';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { adaptiveDimensionsPlayer } from './lib/adaptiveDimensionsPlayer';
import { PlayersDeck } from '@Components/animatePlayersDeck/PlayersDeck/PlayersDeck';
import { useMediaQuery } from '@mui/material';
import { ScreenDesktopDimensions } from '@Constants/';

export const PlayersDeckContainer = memo((props: PlayersDeckContainerProps) => {
  const {
    videos,
    onEnded,
    isPlayed,
    playbackControl,
    isAnimateMode,
    onPausePlayer,
    isLeft,
  } = props;

  const [dimensionsPlayer, setDimensionsPlayer] = useState({
    width: '0px',
    height: '0px',
  });

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const transformValue = (min: number, max: number, index: number) => {
    const randomValue = Math.floor(Math.random() * (max - min) + min);
    return index === 0 ? `rotate(0deg)` : `rotate(${randomValue}deg)`;
  };

  useEffect(() => {
    setDimensionsPlayer(adaptiveDimensionsPlayer(displayWidth, displayHeight, isDesktop));
  }, [displayHeight, displayWidth, isDesktop]);

  return (
    <PlayersDeck
      videos={videos}
      onEnded={onEnded}
      isPlayed={isPlayed}
      playbackControl={playbackControl}
      transformValue={transformValue}
      dimensionsPlayer={dimensionsPlayer}
      isAnimateMode={isAnimateMode}
      onPausePlayer={onPausePlayer}
      isLeft={isLeft}
      displayHeight={displayHeight}
    />
  );
});

PlayersDeckContainer.displayName = 'PlayersDeckContainer';
