import React, { memo, useLayoutEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ButtonsBlockSX, ButtonsBlockSXMedia } from './stylesSX';
import { ButtonsBlockProps } from './ButtonsBlock.type';
import { ChooseDirection, NextBtn } from '@Components/btns';
import { HeartInCircle } from '@Components/btns/ChooseDirection/ChooseDirectionContents/heartInCircle';
import { disappearance } from '../../../../utils/helpers/animate/animates';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { containerWidth } from '@Components/players/DesktopPlayer/ButtonsBlock/lib/containerAdaptiveWidth';
import { MobileNextBtn } from '@Components/btns/MobileNextBtn';
import { Tags } from '@Pages/Testing/Mobile/Tags/Tags';
import { adaptiveDimensionsPlayer } from '@Components/animatePlayersDeck/lib/adaptiveDimensionsPlayer';
import { heartInCircleAdaptive } from '@Components/players/DesktopPlayer/lib/heartInCircleAdaptive';
import { IsTables } from '../../../../utils/helpers/isTables';
import { ScreenDesktopDimensions } from '@Constants/';

export const ButtonsBlock = memo((props: ButtonsBlockProps) => {
  const {
    onProfHandle,
    videoWatchedCounter,
    isAnimateMode,
    icon_color,
    isNextBtnActive,
    onNextClick,
    videosList,
    testingType,
    tags,
  } = props;
  const [btnText, setBtnText] = useState('');
  const { width: displayWidth, height: displayHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (videosList.length > 1) {
      setBtnText(videosList[1].desc);
    }
  }, [videosList]);

  const isDesktopType = testingType === 'desktop';
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const heartSXData = heartInCircleAdaptive(displayHeight, displayWidth, isDesktop);
  const widthContainer = containerWidth(displayWidth, displayHeight, isDesktop);
  const buttonsBlockTop = adaptiveDimensionsPlayer(displayWidth, displayHeight, isDesktop);

  // const btnText = videosList.length > 1 ? videosList[1].desc : '';

  const onNextBtnClick = () => {
    onNextClick('click');
  };
  return (
    <>
      <Box
        sx={ButtonsBlockSXMedia.container(
          displayHeight,
          displayWidth,
          isDesktop,
          buttonsBlockTop.height,
          widthContainer,
        )}>
        {isDesktopType ? (
          <>
            <Box sx={ButtonsBlockSXMedia.chooseDirectionBox(displayHeight, isDesktop)}>
              <ChooseDirection
                onBtnClick={onProfHandle}
                disabled={isAnimateMode || videoWatchedCounter < 3}
                type={'desktop'}
                isAnimateMode={isAnimateMode}
                sxData={{
                  color: icon_color,
                  maxWidth: heartSXData.maxWidth,
                  height: heartSXData.height,
                  borderRadius: '50%',
                  backgroundColor: 'neutral.white',
                }}>
                <HeartInCircle videoWatchedCounter={videoWatchedCounter} />
              </ChooseDirection>
            </Box>

            <Box
              sx={[
                ButtonsBlockSX.nextBtn,
                ButtonsBlockSXMedia.nextBtn(displayHeight),
                disappearance(isAnimateMode),
              ]}>
              {displayHeight > 700 ? (
                <NextBtn disabled={isNextBtnActive} onClick={onNextBtnClick}
                         nameBtn={btnText} />
              ) : (
                <MobileNextBtn disabled={isNextBtnActive} onClick={onNextBtnClick} />
              )}
            </Box>
          </>
        ) : (
          <Box sx={disappearance(isAnimateMode)}>
            {IsTables(displayHeight, displayWidth)
              ? <NextBtn disabled={isNextBtnActive} onClick={onNextBtnClick}
                         nameBtn={btnText} />
              : <MobileNextBtn disabled={isNextBtnActive} onClick={onNextBtnClick} />
            }
          </Box>
        )}
      </Box>

      {isDesktopType && tags && (
        <Box
          sx={ButtonsBlockSXMedia.tagsBlock(
            buttonsBlockTop.height,
            heartSXData.height,
            displayHeight,
            isDesktop,
          )}>
          <Box sx={ButtonsBlockSXMedia.tagsBox(isAnimateMode)}>
            <Tags tags={tags} />
          </Box>
        </Box>
      )}
    </>
  );
});

ButtonsBlock.displayName = 'ButtonsBlock';
