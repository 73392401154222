export const InFullVersionMessageSXMedia = {
  container: (isDesktop: boolean) => ({
    fontStyle: 'normal',
    fontWeight: 700,

    color: 'neutral.dark',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: isDesktop ? '34px' : '18px',
  }),
  title: (isDesktop: boolean) => ({
    fontWeight: 700,
    fontSize: isDesktop ? '32px' : '18px',
    lineHeight: isDesktop ? '38px' : '25px',
    marginTop: isDesktop ? '50px' : '29px',
  }),
  message: (isDesktop: boolean) => ({
    fontWeight: 500,
    fontSize: isDesktop ? '24px' : '12px',
    lineHeight: isDesktop ? '37px' : '14px',
    height: isDesktop ? '189px' : '87px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  }),
  btnBox: (isDesktop: boolean) => ({
    marginBottom: isDesktop ? 0 : '20px',
  }),
  btnData: (isDesktop: boolean) => ({
    color: '#F0671A',
    backgroundColor: 'transparent',
    maxWidth: isDesktop ? '382px' : '135px',
    height: isDesktop ? '79px' : '32px',
    borderRadius: '150px',
  }),
  btnContent: (isDesktop: boolean) => ({
    fontWeight: 700,
    fontSize: isDesktop ? '24px' : '10px',
    lineHeight: isDesktop ? '24px' : '11px',
  }),
};
