import { keyframes } from '@mui/system';

const showIcons = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const DesktopProfChoiceAnimationSX = {
  backgroundIcons: {
    animation: `${showIcons} .5s ease-in forwards`,
  },
  massageBox: {
    fontWeight: 700,
    fontSize: '64px',
    lineHeight: '77px',

    position: 'absolute',
    top: '30vh',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  mobileMessageBox: {
    fontSize: '31px',
    lineHeight: '37px',
  },
};
