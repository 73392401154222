import { keyframes } from '@mui/system';

import {
  FocusTypes,
  ICoordinates,
  TypesEvents,
} from '@Pages/Results/DesktopResults/Pedestal/Pedestal.type';

export const translateYAnimation = (
  type: FocusTypes,
  typeEvent: TypesEvents,
  positions: ICoordinates,
) => {
  let coordinates = {
    start: 0,
    end: 0,
  };
  const movementDistance = -2;
  if (typeEvent === 'focus') {
    const { end } = coordinatesAnimations(type, positions);
    const startValue = end;
    const endValue = end + movementDistance;
    coordinates = { start: startValue, end: endValue };
  }
  if (typeEvent === 'start') {
    const { start, end } = coordinatesAnimations(type, positions);
    coordinates = { start, end };
  }

  return keyframes`
    0% {
      transform: translateY(${coordinates.start}%);
    }
    100% {
      transform: translateY(${coordinates.end}%);
    }
  `;
};

const coordinatesAnimations = (
  type: FocusTypes,
  coordinates: ICoordinates,
): {
  start: number;
  end: number;
} => {
  switch (type) {
    case 'fall':
      return { start: -10, end: coordinates.fall };
    case 'fall1':
      return { start: -10, end: coordinates.fall1 };
    case 'fall2':
      return { start: -10, end: coordinates.fall2 };
    case 'fall3':
      return { start: -10, end: coordinates.fall3 };
    default:
      return { start: 0, end: 0 };
  }
};
