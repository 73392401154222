import React, { memo } from 'react';
import { Box } from '@mui/material';

import { MobileResultsProps } from './MobileResults.type';
import { MobileResultsSX } from './stylesSX';
import { ResDescriptions } from '@Pages/Results/ResDescriptios';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ResButtons } from '@Pages/Results/ResButtons';
import { ResModals } from '../ResModals';
import { useGetResultDemoQuery } from '@Store/result/resultDemo.api';
import { PedestalMobile } from '@Pages/Results/MobileResults/PedestalMobile';

export const MobileResults = memo((props: MobileResultsProps) => {
  const {
    isUnlockModal,
    setIsUnlockModal,
    onProfClick,
    isPromoModal,
    setIsPromoModal,
    userPriceAnonData,
    onPromoClick,
    onUnlockClick,
    isAnimationBtn,
    uuid,
  } = props;

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const { data: resData } = useGetResultDemoQuery(uuid);

  const mobileIcons = resData?.map(({ mobile_icon_report }) => mobile_icon_report);

  return (
    <Box sx={MobileResultsSX.container}>
      <ResDescriptions displayHeight={displayHeight} displayWidth={displayWidth} />

      <PedestalMobile onProfClick={onProfClick} mobileIcons={mobileIcons} />

      <Box sx={MobileResultsSX.buttonsBox}>
        <ResButtons
          userPriceAnon={userPriceAnonData}
          onPromoClick={onPromoClick}
          onUnlockClick={onUnlockClick}
          displayWidth={displayWidth}
          displayHeight={displayHeight}
          isAnimationBtn={isAnimationBtn}
          type={'mobile'}
        />
      </Box>

      <ResModals
        uuid={uuid}
        isUnlockModal={isUnlockModal}
        setIsUnlockModal={setIsUnlockModal}
        isPromoModal={isPromoModal}
        setIsPromoModal={setIsPromoModal}
      />
    </Box>
  );
});

MobileResults.displayName = 'MobileResults';
