export interface IHeartInCircleAdaptive {
  height: string;
  maxWidth: string;
}

export const heartInCircleAdaptive = (
  displayHeight: number,
  displayWidth: number,
  isDesktop: boolean,
): IHeartInCircleAdaptive => {
  switch (true) {
    // case displayHeight < 776 || displayWidth < 600:
    /*    case displayHeight === 454 && displayWidth === 320:
      return {
        height: '45px',
        maxWidth: '45px',
      };*/
    /* case displayHeight < 425 && !isDesktop:
      return {
        height: '35px',
        maxWidth: '35px',
      };*/
    case displayHeight < 480 && !isDesktop:
      return {
        height: '45px',
        maxWidth: '45px',
      };
    case displayHeight < 500 && !isDesktop:
      return {
        height: '50px',
        maxWidth: '50px',
      };
    case displayHeight < 540 && !isDesktop:
      return {
        height: '55px',
        maxWidth: '55px',
      };
    case displayHeight < 570 && !isDesktop:
      return {
        height: '60px',
        maxWidth: '60px',
      };
    case displayHeight < 650 && !isDesktop:
      return {
        height: '66px',
        maxWidth: '66px',
      };
    case displayHeight < 720 && !isDesktop:
      return {
        height: '76px',
        maxWidth: '76px',
      };
    case displayHeight < 800 && !isDesktop:
      return {
        height: '86px',
        maxWidth: '86px',
      };

    case displayHeight < 701:
      return {
        height: '66px',
        maxWidth: '66px',
      };

    case displayHeight < 930:
      return {
        height: '92px',
        maxWidth: '92px',
      };
    case displayHeight < 1080:
      return {
        height: '100px',
        maxWidth: '100px',
      };
    default:
      return {
        height: '115px',
        maxWidth: '115px',
      };
  }
};
