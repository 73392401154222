import { RouteProps } from 'react-router-dom';
import { Results } from '@Pages/Results';
import { MainPage } from '@Pages/MainPage';

export enum AppRoutes {
  MAIN = 'main',
  RESULT = 'result',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: '/',
  [AppRoutes.RESULT]: '/result',
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.RESULT]: {
    path: RoutePath.result,
    element: <Results />,
  },
};
