import React, { memo } from 'react';
import { Box } from '@mui/material';
import { IIconEl } from './IconEl.type';
import shining from '@Assets/images/shining.svg';
import { IconElSX } from '@Components/profChoiceAnimationBackground/iconEl/stylesSX';

export const IconEl = memo((props: IIconEl) => {
  const { top, right, bottom, left, rotate, img } = props;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: `${top}%`,
        right: `${right}%`,
        bottom: `${bottom}%`,
        left: `${left}%`,
        transform: `rotate(${rotate}deg)`,
      }}>
      <Box sx={IconElSX.shiningBox}>
        <img src={shining} alt='shining' style={IconElSX.shiningBox.shining} />
      </Box>
      {img}
    </Box>
  );
});
IconEl.displayName = 'IconEl';
