import React, { memo } from 'react';
import { TextInput } from '@Components/inputs/TextInput/TextInput';
import { Box, useMediaQuery } from '@mui/material';
import { PhoneNumberInputSX, PhoneNumberInputSXMedia } from './stylesSX';
import { formatPhoneNumber } from './lib/formatPhoneNumber';
import { PhoneNumberInputProps } from './PhoneNumberInput.type';
import { ScreenDesktopDimensions } from '@Constants/';

export const PhoneNumberInput = memo((props: PhoneNumberInputProps) => {
  const { value, setValue, errMessage, resetError } = props;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const handleInput = (value: string) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    value === '' ? setValue('') : setValue(formattedPhoneNumber);
  };

  return (
    <Box sx={PhoneNumberInputSX.container}>
      <Box sx={[PhoneNumberInputSX.mask, PhoneNumberInputSXMedia.mask(isDesktop)]}>+7</Box>
      <TextInput
        value={value}
        setValue={handleInput}
        placeholder={''}
        type={'phone'}
        message={errMessage}
        resetError={resetError}
        autofocus
      />
    </Box>
  );
});

PhoneNumberInput.displayName = 'PhoneNumberInput';
