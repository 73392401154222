import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { TitleQuestionSXMedia } from './stylesSX';
import cls from './TitleQuestion.module.css';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { ScreenDesktopDimensions } from '@Constants/';
import { useTranslation } from 'react-i18next';

export const TitleQuestion = memo(() => {
  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const { t }= useTranslation()

  return (
    <Box sx={TitleQuestionSXMedia.titleBoxSx(displayHeight, displayWidth, isDesktop)}>
      <div className={cls.title}>{t('Что вам')}</div>
      <div className={cls.boxWord}>{t('нравится')}</div>
      <div className={cls.title}>{t('больше ?')}</div>
    </Box>
  );
});

TitleQuestion.displayName = 'TitleQuestion';
