import React, { memo } from 'react';
import { Box } from '@mui/material';
import heartIcon from '@Assets/images/heart_XL.svg';
import { HeartCircleSX } from './stylesSX';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { iconWidth } from '@Components/btns/ChooseDirection/ChooseDirectionContents/heartInCircle/lib/iconWidth';
import { HeartInCircleProps } from './HeartInCircle.type';

export const HeartInCircle = memo((props: HeartInCircleProps) => {
  const { videoWatchedCounter } = props;
  const { height: displayHeight, width: displayWidth } = useWindowDimensions();

  const imgWidth = iconWidth(displayWidth, displayHeight);

  return (
    <>
      {videoWatchedCounter >= 3 ? (
        <Box sx={HeartCircleSX.imgBox}>
          <img src={heartIcon} alt='icon' width={imgWidth} />
        </Box>
      ) : (
        <Box>{videoWatchedCounter}/3</Box>
      )}
    </>
  );
});

HeartInCircle.displayName = 'HeartInCircle';
