import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DesktopTesting, MobileTesting } from '@Pages/Testing';
import { useMediaQuery } from '@mui/material';
import { ScreenDesktopDimensions, UUID } from '@Constants/';
import { useNavigate } from 'react-router-dom';
import { UseLocalStorage } from '@Helpers/';
import { IUuid } from '@Models/';
import { useLazyFetchPairForTestingQuery } from '@Store/demoTesting';
import { useLazyGetResultDemoQuery } from '@Store/result/resultDemo.api';
import { useLazyGetUuidQuery } from '@Store/uuid';
import { useLazyFetchProgressBarQuery } from '@Store/progressBar';
import { RoutePath } from '../../utils/routeConfig/routeConfig';
import { IAnimateChosenProf } from '@Components/players/DesktopPlayer/DesktopPlayer.types';

export const MainPage = () => {
  const [isLeftPlayed, setIsLeftPlayed] = useState(false);
  const [isRightPlayed, setIsRightPlayed] = useState(false);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [isAnimateMode, setIsAnimateMode] = useState(false);
  const [isAnimateChosenProf, setIsAnimateChosenProf] = useState<IAnimateChosenProf>({
    left: false,
    right: false,
  });

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const navigate = useNavigate();
  const uuidLS = useMemo(
    () =>
      new UseLocalStorage<IUuid>(UUID, {
        uuid: '',
      }),
    [],
  );
  const uuid = uuidLS.get().uuid;

  const [fetchProfs, { data: dataProfs }] = useLazyFetchPairForTestingQuery();
  const [getResults, { data: results }] = useLazyGetResultDemoQuery();
  const [getUserId, { data: userid }] = useLazyGetUuidQuery();
  const [fetchProgressBarData, { data: progressBarData }] = useLazyFetchProgressBarQuery();

  useEffect(() => {
    if (uuid && !results) {
      fetchProgressBarData(uuid);
    }
    if (dataProfs && dataProfs.length < 2) {
      getResults(uuid);
    }
  }, [dataProfs, fetchProgressBarData, getResults, results, uuid]);

  useEffect(() => {
    results && navigate(RoutePath.result);
  }, [navigate, results]);

  useEffect(() => {
    uuid ? fetchProfs(uuid) : getUserId('');
  }, [fetchProfs, getUserId, uuid]);

  useEffect(() => {
    if (userid) {
      uuidLS.set(userid);
      fetchProfs(userid.uuid);
    }
  }, [fetchProfs, userid, uuidLS]);

  const playbackControlHandle = useCallback(
    (isLeft: boolean) => {
      if (isLeft) {
        setIsLeftPlayed(!isLeftPlayed);
        setIsRightPlayed(false);
      }
      if (!isLeft) {
        setIsRightPlayed(!isRightPlayed);
        setIsLeftPlayed(false);
      }
    },
    [isLeftPlayed, isRightPlayed],
  );

  const stopPlayback = () => {
    setIsLeftPlayed(false);
    setIsRightPlayed(false);
  };

  return (
    <>
      {isDesktop ? (
        <DesktopTesting
          isAnimateMode={isAnimateMode}
          uuid={uuid}
          isProgressBar={isProgressBar}
          progressBarData={progressBarData}
          dataProfs={dataProfs}
          isLeftPlayed={isLeftPlayed}
          isRightPlayed={isRightPlayed}
          playbackControlHandle={playbackControlHandle}
          isAnimateChosenProf={isAnimateChosenProf}
          setIsAnimateChosenProf={setIsAnimateChosenProf}
          stopPlayback={stopPlayback}
        />
      ) : (
        <MobileTesting
          isAnimateMode={isAnimateMode}
          uuid={uuid}
          isProgressBar={isProgressBar}
          progressBarData={progressBarData}
          dataProfs={dataProfs}
          isLeftPlayed={isLeftPlayed}
          isRightPlayed={isRightPlayed}
          playbackControlHandle={playbackControlHandle}
          isAnimateChosenProf={isAnimateChosenProf}
          setIsAnimateChosenProf={setIsAnimateChosenProf}
          stopPlayback={stopPlayback}
        />
      )}
    </>
  );
};
