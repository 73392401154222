import React from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import { useAuth } from '@Hooks/';
import { redirectPage } from '@Constants/';
import { MainLayout } from '@Components/layouts';

export const AuthorizedWrapper = () => {
  const { isPaid } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();

  if (!isPaid) {
    return <Navigate to={redirectPage} replace state={{ path: location.pathname }} />;
  }

  return <MainLayout>{outlet}</MainLayout>;
};
