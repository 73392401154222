import React, { PropsWithChildren } from 'react';
import { ChooseDirectionSX } from './StylesSX';
import { Box, Button, ButtonProps, useMediaQuery } from '@mui/material';
import { disappearance } from '../../../utils/helpers/animate/animates';
import { IChooseDirection } from './ChooseDirection.type';
import { styled } from '@mui/material/styles';
import { ScreenDesktopDimensions } from '@Constants/';

export const ChooseDirection = (props: PropsWithChildren<IChooseDirection>) => {
  const { onBtnClick, type, isAnimateMode, children, sxData, disabled = false } = props;
  const { color, backgroundColor, maxWidth, height, borderRadius } = sxData;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const ColorButton = styled(Button)<ButtonProps>(() => ({
    width: '100% !important',
    fontFamily: 'Fira Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: isDesktop ? '24px' : '10px',
    lineHeight: isDesktop ? '24px' : '11px',
    color: '#000000',
    textTransform: 'capitalize',
  }));

  if (borderRadius) {
    ChooseDirectionSX.prof_btn.borderRadius = borderRadius;
  }

  ChooseDirectionSX.prof_btn.width = type === 'mobile' ? '100%' : '237px';
  if (maxWidth) ChooseDirectionSX.prof_btn_block.width = maxWidth;
  if (height) ChooseDirectionSX.prof_btn.height = height;

  ChooseDirectionSX.prof_btn['&:hover'].borderColor = color;
  if (backgroundColor) ChooseDirectionSX.prof_btn.backgroundColor = backgroundColor;
  if (disabled)
    ChooseDirectionSX.prof_btn['&:hover'] = {
      top: '',
      left: '',
      border: '',
      borderColor: '',
      backgroundColor: 'neutral.white',
    };

  return (
    <Box sx={[ChooseDirectionSX.prof_btn_block, disappearance(isAnimateMode)]}>
      <ColorButton
        variant='contained'
        fullWidth={true}
        sx={[
          ChooseDirectionSX.prof_btn,
          disabled
            ? {
                boxShadow: `3px 3px 0px ${color}`,
                ['&:hover']: { boxShadow: `3px 3px 0px ${color}` },
                opacity: 0.5,
              }
            : {
                boxShadow: `3px 3px 0px ${color}`,
                ['&:hover']: { boxShadow: `1px 1px 0px ${color}` },
              },
        ]}
        onClick={disabled ? undefined : onBtnClick}>
        {children}
      </ColorButton>
    </Box>
  );
};
