import React, { memo, useState } from 'react';
import { Box } from '@mui/material';
import logo from '@Assets/images/logo.svg';
import { HeaderDesktopSX, HeaderDesktopSXMedia } from './stylesSX';
import { NestedModal } from '@Components/modals';
import { IHeaderDesktop } from '../Header.type';
import { AuthModalsContainer } from '@Components/modals/modalsContent/Unlock/AuthModalsContainer';
import { useActions, useAuth } from '@Hooks/';
import { MetrikValues, sendMetrik } from '../../../utils/yaMetrica/metriks';
import { useTranslation } from 'react-i18next';

export const HeaderDesktop = memo((props: IHeaderDesktop) => {
  const { uuid, displayHeight } = props;
  const [isSignInModal, setIsSignInModal] = useState(false);
  const { t }= useTranslation()

  const { isPaid } = useAuth();
  const { deleteTokens } = useActions();

  const onSignInClick = () => {
    isPaid ? deleteTokens() : setIsSignInModal(prev => !prev);
  };

  return (
    <Box sx={HeaderDesktopSX.block}>
      <Box sx={HeaderDesktopSXMedia.contentBox(displayHeight)}>
        <img
          src={logo}
          alt='logo'
          style={HeaderDesktopSX.img}
          onClick={() => sendMetrik('reachGoal', MetrikValues.LoginClicked)}
        />
        <Box sx={HeaderDesktopSX.profDream}>{t('Выбираем профессии мечты')}</Box>
 {/*       <Box onClick={onSignInClick} sx={HeaderDesktopSX.log_in}>
          {isPaid ? t('Выйти')  : t('Войти') }
        </Box>*/}
        <NestedModal open={isSignInModal} setIsConfirmModal={setIsSignInModal}>
          <AuthModalsContainer uuid={uuid} forcedType={'auth'} />
        </NestedModal>
      </Box>
    </Box>
  );
});

HeaderDesktop.displayName = 'HeaderDesktop';
