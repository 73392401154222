import React, { memo, ReactElement, useEffect, useState } from 'react';
import { IProgressBar, IProgressProfs } from '@Components/pogressBar/ProgressBar.types';
import { Box } from '@mui/material';
import { animationType } from '@Components/pogressBar/lib/animationType';
import { animationCardsHTML } from '@Components/pogressBar/lib/animationCardsHTML';
import { sortedLocationIdx } from '@Components/pogressBar/lib/sortedLocationIdx';
import { scaleProgress } from '@Components/pogressBar/secondary/scaleProgress';
import { ProgressBarDemo } from '@Components/pogressBar/ProgressbarDemo/ProgressBarDemo';
import { useAuth } from '@Hooks/';
import { ProgressBar } from '@Components/pogressBar/Progressbar/ProgressBar';
import { ProgressBarDemoMobile } from '@Components/pogressBar/ProgressBarDemoMobile/ProgressBarDemoMobile';

export const ProgressBarContainer = memo((props: IProgressBar) => {
  const { progressBarData, testingType = 'desktop' } = props;
  const [progressValue, setProgressValue] = useState(0);
  const [totalProfs, setTotalProfs] = useState(10);
  const [profsLvlOne, setProfsLvlOne] = useState<IProgressProfs[] | undefined>();
  const [sortedCard, setSortedCard] = useState<IProgressProfs | null>(null);
  const [allCards, setAllCards] = useState<IProgressProfs[] | null>();
  const [animationBlock, setAnimationBlock] = useState<ReactElement[] | null>(null);
  const [newCard, setNewCard] = useState<ReactElement>(<Box />);

  const { isPaid } = useAuth();

  useEffect(() => {
    if (progressBarData) {
      setProfsLvlOne(progressBarData.progress_bar);
      setTotalProfs(progressBarData.count_sorted_profession);
    }
  }, [progressBarData]);

  useEffect(() => {
    if (progressBarData) {
      const profBlockWidth = '39px';
      const { progress_bar, sorted_value } = progressBarData;

      if (sorted_value) {
        setSortedCard(sorted_value);

        if (animationBlock && progress_bar) {
          const animationTypeData = animationType(sorted_value.id, progress_bar, []);
          setNewCard(animationCardsHTML(sorted_value, animationTypeData, profBlockWidth));
        }
      }

      if (!sorted_value && !!newCard) {
        setNewCard(<Box />);
      }

      if (allCards && sortedCard && progress_bar) {
        const idxSortedCardOldCards = sortedLocationIdx(allCards, sortedCard.id);
        const idxSortedCardNewCards = sortedLocationIdx(progress_bar, sortedCard.id);

        const isChangedLocation = idxSortedCardOldCards !== idxSortedCardNewCards;

        if (isChangedLocation) {
          setAllCards(progress_bar);
          setAnimationBlock(
            progress_bar.map(card => {
              const animateTypeData = animationType(card.id, progress_bar, allCards);
              return animationCardsHTML(card, animateTypeData, profBlockWidth);
            }),
          );
        }
      } else {
        if (progress_bar.length === 2) {
          const html = progress_bar.map(card =>
            animationCardsHTML(
              card,
              {
                type: 'blockMoveToRight',
                factor: 2,
              },
              profBlockWidth,
            ),
          );
          setAnimationBlock(html);
        } else {
          const html = progress_bar.map(card =>
            animationCardsHTML(
              card,
              {
                type: 'oldPosition',
                factor: 0,
              },
              profBlockWidth,
            ),
          );
          setAnimationBlock(html);
        }

        setAllCards(progress_bar);
      }
    }
  }, [allCards, progressBarData, sortedCard]);

  useEffect(() => {
    if (profsLvlOne) {
      const profsLvlOneLength = profsLvlOne ? profsLvlOne.length : 0;
      const progressPercentageLvlOne = scaleProgress(profsLvlOneLength, totalProfs);
      setProgressValue(progressPercentageLvlOne);
    }
  }, [profsLvlOne, totalProfs]);

  return (
    <>
      {isPaid ? (
        <ProgressBar
          animationBlock={animationBlock}
          progressValue={progressValue}
          newCard={newCard}
        />
      ) : testingType === 'desktop' ? (
        <ProgressBarDemo
          animationBlock={animationBlock}
          progressValue={progressValue}
          newCard={newCard}
          testingType={testingType}
        />
      ) : (
        <ProgressBarDemoMobile
          animationBlock={animationBlock}
          progressValue={progressValue}
          newCard={newCard}
        />
      )}
    </>
  );
});

ProgressBarContainer.displayName = 'ProgressBarContainer';
