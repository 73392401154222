import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { NextBtnSXMedia } from './stylesSX';
import { INextBtn } from './NextBtn.types';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IsTables } from '../../../utils/helpers/isTables';
import { ScreenDesktopDimensions } from '@Constants/';
import { useTranslation } from 'react-i18next';

export const NextBtn = memo((props: INextBtn) => {
  const { nameBtn, onClick, disabled } = props;

  const { t } = useTranslation()
  const { width: displayWidth, height: displayHeight } = useWindowDimensions();

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const isTablet = IsTables(displayHeight, displayWidth);

  return (
    <Box
      sx={[
        NextBtnSXMedia.follow_btn(isTablet),
        isTablet && NextBtnSXMedia.follow_btn_transform(displayWidth, displayHeight, isDesktop),
      ]}
      onClick={disabled ? onClick : undefined}>
      <Box sx={NextBtnSXMedia.farther()}>{t('Далее')}</Box>

      <Box sx={NextBtnSXMedia.follow_btn_title(isTablet)}>{nameBtn}</Box>
    </Box>
  );
});

NextBtn.displayName = 'NextBtn';
