export const TooltipSX = {
  imgBox: {
    height: '35px',
    display: 'flex',
  },
  infoIcon: {
    marginRight: '13px',
    cursor: 'pointer',
    width: '20px',
  },
};
