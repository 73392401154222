import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ResButtonsSX, ResButtonsSXMedia } from './stylesSX';
import { ResButtonsProps } from './ResButtons.type';
import { ChooseDirection } from '@Components/btns';
import checkGreen from '@Assets/images/icons/check_green.svg';
import { useTranslation } from 'react-i18next';

export const ResButtons = memo((props: ResButtonsProps) => {
  const {
    onUnlockClick,
    onPromoClick,
    userPriceAnon,
    displayWidth,
    displayHeight,
    isAnimationBtn,
    type = 'desktop',
  } = props;

  const { t }= useTranslation()

  return (
    <Box sx={ResButtonsSXMedia.container(type)}>
      <Box
        sx={[
          {
            maxWidth: ResButtonsSXMedia.btnMaxWidth(displayWidth),
          },
          ResButtonsSXMedia.btnAnimation(isAnimationBtn),
        ]}>
        {userPriceAnon && (
          <ChooseDirection
            type={'desktop'}
            onBtnClick={onUnlockClick}
            sxData={ResButtonsSXMedia.btnUnlockSXData(displayWidth)}>
            <Box sx={[ResButtonsSX.btnContent, ResButtonsSXMedia.btnFontSize(displayWidth)]}>
              <>{t('Разблокировать за')} {userPriceAnon.total_price}₽</>
            </Box>
          </ChooseDirection>
        )}
      </Box>

      <Box sx={ResButtonsSXMedia.btnBox(type, displayHeight, displayWidth)}>
        <ChooseDirection
          type={'desktop'}
          onBtnClick={onPromoClick}
          sxData={ResButtonsSXMedia.btnPromoSXData(displayWidth, type)}>
          <Box sx={[ResButtonsSX.btnContent, ResButtonsSXMedia.btnFontSize(displayWidth)]}>
            {userPriceAnon?.active_promo ? (
              <Box sx={ResButtonsSX.promoBtnBox}>
                <Box sx={ResButtonsSX.promoTitle}>{userPriceAnon?.promo}</Box>
                <img src={checkGreen} alt='check' />
              </Box>
            ) : (
              <Box>{t('У меня есть промокод')}</Box>
            )}
          </Box>
        </ChooseDirection>
      </Box>
    </Box>
  );
});

ResButtons.displayName = 'ResButtons';
