import React, { FC, PropsWithChildren } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { desktopHeaderSX, mobileHeaderSX } from './stylesSX';
import { HeightsPercent, ScreenDesktopDimensions } from '@Constants/';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ProfChoiceAnimationBackground } from '@Components/profChoiceAnimationBackground/ProfChoiceAnimationBackground';
import { HeaderDesktop } from '@Components/headers/headerDesktop';
import { IHeaderContainer } from '@Components/headers/HeaderContainer.type';
import { headerHeight } from '@Components/headers/lib/headerHeight';
import { HeaderMobile } from '@Components/headers/HeaderMobile/HeaderMobile';
import { mainBackground } from '@Components/headers/lib/mainBackground';

export const HeaderContainer: FC<PropsWithChildren<IHeaderContainer>> = props => {
  const { children, isAnimateMode, backgroundColors, uuid } = props;

  const { height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const sx = isDesktop ? desktopHeaderSX : mobileHeaderSX;

  if (!isDesktop) {
    mobileHeaderSX.container.height = `${displayHeight}px `;
    mobileHeaderSX.appbar.height = `${displayHeight * HeightsPercent.header}px `;
  }

  const background = mainBackground(isDesktop, backgroundColors);

  return (
    <Box sx={[background, sx.container]}>
      <ProfChoiceAnimationBackground isAnimateMode={isAnimateMode} />
      {isDesktop ? (
        <HeaderDesktop uuid={uuid} displayHeight={displayHeight} />
      ) : (
        <HeaderMobile uuid={uuid} displayHeight={displayHeight} />
      )}
      <Box
        sx={[
          sx.appContent,
          {
            height: isDesktop
              ? `calc(100vh - ${headerHeight(displayHeight)} - 3px)`
              : '--webkit-fill-available',
          },
        ]}>
        {children}
      </Box>
    </Box>
  );
};
