import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthorizedWrapper, NoAuthorizedWrapper } from '@Components/wrappers';
import { routeConfigDemo } from '../../utils/routeConfig/routeConfigDemo';
import { Loader } from '@Components/loader/Loader/Loader';
import { NotFoundPage } from '@Pages/NotFoundPage/NotFoundPage';
import { routeConfig } from '../../utils/routeConfig/routeConfig';

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<AuthorizedWrapper />}>
        {Object.values(routeConfig).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<Loader />}>
                <div className='page-wrapper'>{element}</div>
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route element={<NoAuthorizedWrapper />}>
        {Object.values(routeConfigDemo).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<Loader />}>
                <div className='page-wrapper'>{element}</div>
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
