import { keyframes } from '@mui/system';

const animateShinning = keyframes`
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.5);
  }
  30% {
    transform: scale(1.2);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.8);
  }
  90% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

export const IconElSX = {
  shiningBox: {
    position: 'absolute',
    animation: `${animateShinning} 3s ease-in forwards`,
    shining: {
      width: '100%',
    },
  },
};
