export const ProfessionSX = {
  container: {
    height: '100%',
    margin: '45px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    '::-webkit-scrollbar': {
      width: 0,
    },
  },

  video_block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 22px',
    height: '100%',

    position: 'relative',
  },

  video_desc: {
    transform: 'translate(10px, -99px)',
    color: 'neutral.white',
  },

  follow_btn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0',
    height: '60px',
  },

  prof_description: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#7B7B7B',
  },
};
