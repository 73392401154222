import React, { memo, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { adaptiveDataPedestal, boxSX, boxSXPosition, PedestalSX } from './stylesSX';
import { PedestalProps } from './Pedestal.type';
import tablet from '@Assets/images/tablet.svg';
import pedestal from '@Assets/images/pedestal.svg';
import { ResDataBox } from '@Pages/Results/DesktopResults/Pedestal/ResDataBox/ResDataBox';
import { useGetResultDemoQuery } from '@Store/result/resultDemo.api';
import { animationSX } from '@Pages/Results/DesktopResults/Pedestal/lib/animationSX';
import { MetrikValues, sendMetrik } from '../../../../utils/yaMetrica/metriks';

export const Pedestal = memo((props: PedestalProps) => {
  const { uuid, displayWidth, displayHeight, onProfClick } = props;
  const [isStartAnimation, setIsStartAnimation] = useState(true);

  const { data: resData } = useGetResultDemoQuery(uuid);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (resData) {
      sendMetrik('reachGoal', MetrikValues.DemoResultReached, {
        prof_1: resData[0].title,
        prof_2: resData[1].title,
        prof_3: resData[2].title,
        prof_4: resData[3].title,
      });
    }
  }, [resData]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setIsStartAnimation(false);
    }, 2000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [uuid]);

  animationSX(displayWidth, isStartAnimation);

  const containerPosition = adaptiveDataPedestal(displayWidth, displayHeight);

  return (
    <Box
      sx={[
        PedestalSX.container,
        {
          right: containerPosition.right,
          bottom: containerPosition.bottom,
        },
      ]}>
      <img
        src={tablet}
        alt='tablet'
        style={{
          position: 'absolute',
          left: '33%',
          top: '-19%',
          width: '23%',
        }}
      />

      <img src={pedestal} alt='pedestal' width={containerPosition.width} />
      {resData &&
        resData.map(({ icon_report, title, id }, i) => {
          return (
            <Box key={id} sx={[boxSX(i), boxSXPosition(i, displayWidth)]} onClick={onProfClick}>
              <ResDataBox
                title={title}
                iconReport={icon_report}
                index={i}
                displayWidth={displayWidth}
              />
            </Box>
          );
        })}
    </Box>
  );
});

Pedestal.displayName = 'Pedestal';
