import React, { memo } from 'react';
import { Box } from '@mui/material';
import { DesktopResultsProps } from './DesktopResults.type';
import { Clouds } from '@Pages/Results/DesktopResults/clouds';
import { Pedestal } from '@Pages/Results/DesktopResults/Pedestal';
import { ResDescriptions } from '@Pages/Results/ResDescriptios';
import { ResButtons } from '@Pages/Results/ResButtons';
import { ResModals } from '../ResModals';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const DesktopResults = memo((props: DesktopResultsProps) => {
  const {
    uuid,
    onProfClick,
    userPriceAnonData,
    onPromoClick,
    onUnlockClick,
    isAnimationBtn,
    isUnlockModal,
    setIsUnlockModal,
    isPromoModal,
    setIsPromoModal,
  } = props;

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();

  return (
    <Box>
      <Clouds />
      <Pedestal
        uuid={uuid}
        displayWidth={displayWidth}
        displayHeight={displayHeight}
        onProfClick={onProfClick}
      />
      <ResDescriptions displayHeight={displayHeight} displayWidth={displayWidth} />
      <ResButtons
        userPriceAnon={userPriceAnonData}
        onPromoClick={onPromoClick}
        onUnlockClick={onUnlockClick}
        displayWidth={displayWidth}
        isAnimationBtn={isAnimationBtn}
        displayHeight={displayHeight}
      />

      <ResModals
        uuid={uuid}
        isUnlockModal={isUnlockModal}
        setIsUnlockModal={setIsUnlockModal}
        isPromoModal={isPromoModal}
        setIsPromoModal={setIsPromoModal}
      />
    </Box>
  );
});

DesktopResults.displayName = 'DesktopResults';
