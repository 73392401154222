export const desktopHeaderSX = {
  container: {
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: '100% 103%',
    display: 'flex',
    justifyContent: 'center',
  },

  appContent: {
    maxWidth: '1440px',
    width: '100%',
    // position: 'absolute',
    // bottom: 0,
    /*
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',*/

    '::-webkit-scrollbar': {
      width: 0,
    },
  },
  appbarBox: {
    // flexGrow: 2,
  },
  appbar: {
    background: 'linear-gradient(-75deg, #F8BBED 44%, #998CEC 40%)',
    opacity: 0.5,
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const mobileHeaderSX = {
  container: {
    width: '100%',
    minWidth: '100vw',
    height: '',
  },
  appContent: {},
  appbarBox: {
    // flexGrow: 1,
  },
  appbar: {
    height: '5%',
    background: '#EFEFEF',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  log_in: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#454AC8',
    textDecoration: 'underline',
  },
};
