export enum mobileMode {
  width = 1000,
  height = 768,
}

export enum ScreenDesktopDimensions {
  minWidth = '(min-width: 1000px)',
  minHeight = '(min-height: 768px)',
}

export enum HeightsPercent {
  header = 0.07,
  video_block = 0.2,
}
