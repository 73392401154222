import { MetrikValues, sendMetrik } from '../../../../utils/yaMetrica/metriks';

export const setCurrentNumLike = (profId: number | undefined) => {
  const currentNumLike = localStorage.getItem('CountClickLike');
  const newValueCurrentNumLike = currentNumLike ? +currentNumLike + 1 : 1;
  localStorage.setItem('CountClickLike', JSON.stringify(newValueCurrentNumLike));
  sendMetrik('reachGoal', MetrikValues.LikeButtonClicked, {
    profId,
    step: newValueCurrentNumLike,
  });
};
