import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProfIconSX } from './stylesSX';
import { IProfIcon } from './ProfIcon.types';

export const ProfIcon = memo((props: IProfIcon) => {
  const { color, icon, shadowSize = { bot: 4, right: 4 } } = props;

  const activeStyles = {
    backgroundColor: color,
    boxShadow: `${shadowSize.right}px ${shadowSize.bot}px 0px 0px #000000`,
  };

  return (
    <Box sx={[ProfIconSX.profIconBox, activeStyles]}>
      <img
        src={icon}
        alt='profIcon'
        style={{
          width: '80%',
          height: '80%',
          position: 'absolute',
          top: '10%',
          left: '10%',
        }}
      />
    </Box>
  );
});

ProfIcon.displayName = 'ProfIcon';
