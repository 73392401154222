import React, { memo } from 'react';
import { Box } from '@mui/material';
import check from '@Assets/images/checkBlue.svg';
import { IInFullVersionBlock } from './InFullVersionBlock.type';
import { InFullVersionBlockSXMedia } from '@Pages/Results/ResDescriptios/InFullVersionBlock/stylesSX';

export const InFullVersionBlock = memo((props: IInFullVersionBlock) => {
  const { blockText, displayHeight, isDesktop } = props;

  return (
    <Box sx={InFullVersionBlockSXMedia.paragraphBox(displayHeight, isDesktop)}>
      <img src={check} alt='check' style={{ width: isDesktop ? '15px' : ' 9px' }} />
      <Box sx={InFullVersionBlockSXMedia.paragraph(isDesktop)}>{blockText}</Box>
    </Box>
  );
});

InFullVersionBlock.displayName = 'InFullVersionBlock';
