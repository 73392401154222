import React, { memo } from 'react';
import star from '@Assets/images/Star.svg';
import starLittle from '@Assets/images/star-litlte.svg';
import ribbon from '@Assets/images/ribbon.svg';
import ribbonLittle from '@Assets/images/little-ribbon.svg';
import { IImgBlock } from './ImgBlock.type';
import { IconEl } from '@Components/profChoiceAnimationBackground/iconEl';

export const ImgBlock = memo(({ dataPositions, type }: IImgBlock) => {
  const imgSrc = (type: string) => {
    switch (type) {
      case 'star':
        return star;
      case 'little-star':
        return starLittle;
      case 'ribbon':
        return ribbon;
      case 'ribbonLittle':
        return ribbonLittle;
      default:
        return;
    }
  };
  const src = imgSrc(type);

  return (
    <>
      {dataPositions?.map(({ top, right, left, bottom, size, rotate }, i) => (
        <IconEl
          key={i}
          top={top}
          right={right}
          left={left}
          bottom={bottom}
          rotate={rotate}
          img={<img src={src} alt='star' style={{ width: size }} />}
        />
      ))}
    </>
  );
});

ImgBlock.displayName = 'ImgBlock';
