import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { IUuid } from '@Models/';

export const uuidApi = createApi({
  reducerPath: 'uuid',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['uuid'],
  endpoints: build => ({
    getUuid: build.query<IUuid, string>({
      query: () => ({
        url: 'api/v1/get-uuid',
      }),
    }),
  }),
});

export const { useLazyGetUuidQuery } = uuidApi;
