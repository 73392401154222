import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProgressBarProps } from '../ProgressBar.types';
import { ProgressBarDemoSX } from './stylesSX';
import lock from '@Assets/images/icons/lock.svg';
import flag from '@Assets/images/flag.svg';
import { CSSTransition } from 'react-transition-group';
import { ProfNameTooltip } from '@Components/tooltips/profName/ProfNameTooltip';

export const ProgressBarDemo = memo((props: ProgressBarProps) => {
  const { newCard, progressValue, animationBlock, testingType } = props;

  return (
    <Box sx={ProgressBarDemoSX.container}>
      <CSSTransition in={!!newCard} timeout={1500} mountOnEnter unmountOnExit>
        {newCard}
      </CSSTransition>
      <Box sx={ProgressBarDemoSX.block}>
        <Box
          sx={[
            ProgressBarDemoSX.progressBox,
            {
              width: `${progressValue ? progressValue : 0}%`,
              transition: '1s',
            },
          ]}>
          <Box sx={ProgressBarDemoSX.numBlock}>1</Box>
          <Box sx={ProgressBarDemoSX.iconsBlock}>{animationBlock}</Box>
          <Box sx={ProgressBarDemoSX.iconFlag}>
            <img src={flag} alt='flag' />
          </Box>
        </Box>
      </Box>

      {testingType === 'desktop' && (
        <Box sx={[ProgressBarDemoSX.block, ProgressBarDemoSX.blockTwo]}>
          <Box sx={[ProgressBarDemoSX.progressBox, { width: '0%' }]}>
            <Box sx={[ProgressBarDemoSX.numBlock, { color: '#8B8B8B' }]}>2</Box>
            <Box sx={ProgressBarDemoSX.iconsBlock}>
              <ProfNameTooltip title={'После приобретения подписки'} placement={'top-start'}>
                <Box sx={ProgressBarDemoSX.lockBox}>
                  <img src={lock} alt='lock' style={ProgressBarDemoSX.lockBox.lock} />
                </Box>
              </ProfNameTooltip>
              <Box sx={ProgressBarDemoSX.iconFlag2}>
                <img src={flag} alt='flag' />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});

ProgressBarDemo.displayName = 'ProgressBarDemo';
