import btnSvg from '@Assets/images/next_btn.svg';
import { adaptiveDimensionsPlayer } from '@Components/animatePlayersDeck/lib/adaptiveDimensionsPlayer';

export const NextBtnSXMedia = {
  follow_btn: (isTablet: boolean) => {
    return {
      width: isTablet ? '162px' : '100%',
      height: isTablet ? '94px' : '100%',
      cursor: 'pointer',
      marginBottom: '10px',
      background: `url(${btnSvg} ) no-repeat`,
      backgroundSize: '100%',
    };
  },
  follow_btn_transform: (displayWidth: number, displayHeight: number, isDesktop: boolean) => {
    const playerWidth = adaptiveDimensionsPlayer(displayWidth, displayHeight, isDesktop).width;
    return {
      transform: `translateX(calc(${playerWidth} / 2 - 35px))`,
    };
  },
  farther: () => ({
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',

    color: 'neutral.dark',
    padding: '8px 13px 0px',
  }),
  follow_btn_title: (isTablet: boolean) => ({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: 'neutral.dark',
    margin: '5px 5px 5px 13px',
    maxWidth: isTablet ? '114' : '170px',
    maxHeight: '51px',
    display: `-webkit-box; -webkit-line-clamp: ${isTablet ? 2 : 3}; -webkit-box-orient: vertical`,
    overflow: 'hidden',
  }),
};
