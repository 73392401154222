import { Box, useMediaQuery } from '@mui/material';
import clearIcon from '@Assets/images/icons/closeL.svg';
import React, { ChangeEvent, memo, useEffect, useRef } from 'react';
import { TextInputSXMedia } from './stylesSX';
import { TextInputProps } from './TextInput.type';
import cls from './TextInput.module.css';
import { ScreenDesktopDimensions } from '@Constants/';

export const TextInput = memo((props: TextInputProps) => {
  const {
    sxData,
    value,
    setValue,
    message,
    placeholder,
    type = 'text',
    resetError,
    autofocus,
    ...otherProps
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      ref.current?.focus();
    }
  }, [autofocus]);

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onClearClick = () => {
    setValue('');
    resetError();
    ref.current?.focus();
  };

  return (
    <Box>
      {/* {message && <Box sx={TextInputSXMedia.messageBox(isDesktop)}>{message}</Box>}*/}

      <Box sx={[sxData ? sxData : {}, TextInputSXMedia.container(isDesktop, message)]}>
        {value.length > 0 && (
          <Box onClick={onClearClick} sx={TextInputSXMedia.clearIconBox(isDesktop)}>
            <img
              src={clearIcon}
              alt='clear'
              width={TextInputSXMedia.iconPx(isDesktop)}
              height={TextInputSXMedia.iconPx(isDesktop)}
            />
          </Box>
        )}
        <input
          ref={ref}
          value={value}
          onChange={e => onInputChange(e)}
          type={type}
          placeholder={placeholder}
          className={`${cls.input} ${isDesktop ? cls.font_m : cls.font_l}`}
          {...otherProps}
        />
      </Box>
    </Box>
  );
});

TextInput.displayName = 'TextInput';
