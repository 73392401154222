import { createApi } from '@reduxjs/toolkit/query/react';
import { IAuthParams, IAuthTokens } from '@Models/';
import { customQuery } from '@Services/';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customQuery,
  tagTypes: ['Auth'],
  endpoints: build => ({
    login: build.mutation<IAuthTokens, IAuthParams>({
      query: ({ username, password }: IAuthParams) => ({
        url: `/api/v1/user/refresh-token`,
        headers: { Authorization: `Basic ${window.btoa(`${username}:${password}`)}` },
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const { useLoginMutation } = authApi;
