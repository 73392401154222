import { headerHeight } from '@Components/headers/lib/headerHeight';
import { titleBoxHeight } from '@Pages/Testing/Desktop/lib/titleBoxHeight';

export const DesktopTestingSX = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    maxHeight: '100vh',
  },
};

export const DesktopTestingSXMedia = {
  progressBarBox: (displayHeight: number) => ({
    position: 'absolute',
    top: titleBoxHeight(displayHeight, 2),
  }),

  players_block: (displayHeight: number) => ({
    fontFamily: 'Fira Sans,sans-serif',
    fontStyle: 'normal',

    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    overflow: 'hidden',

    position: 'absolute',
    bottom: '0',
    height: `calc(100% - ${headerHeight(displayHeight)} - ${titleBoxHeight(displayHeight, 2)})`,
  }),
};
