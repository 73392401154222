import { IsTables } from '../../../../../utils/helpers/isTables';

export const VideoDescriptionSXMedia = {
  container: (isDesktop: boolean, displayHeight: number, displayWidth: number) => {
    const margin = () => {
      if (IsTables(displayHeight, displayWidth)) {
        return '58px';
      }
      if (displayHeight < 700) {
        return isDesktop ? '36px' : '20px';
      }
      return isDesktop ? '58px' : '20px';
    };
    return {
      marginBottom: margin(),
      position: 'absolute',
      bottom: '0',
      left: '17px',
      paddingRight: '5px',
      color: 'neutral.white',
      zIndex: 201,
    };
  },

  desc: (isDesktop: boolean, displayHeight: number, displayWidth: number) => {
    const fontData = () => {
      switch (true) {
        case IsTables(displayHeight, displayWidth):
          return {
            fontSize: '18px',
            lineHeight: '19px',
          };
        case displayHeight < 470:
          return {
            fontSize: '10px',
            lineHeight: '11px',
          };
        case displayHeight < 510:
          return {
            fontSize: '12px',
            lineHeight: '13px',
          };
        case displayHeight < 600:
          return {
            fontSize: '14px',
            lineHeight: '15px',
          };
        case displayHeight < 700:
          return isDesktop
            ? {
                fontSize: '16px',
                lineHeight: '17px',
              }
            : {
                fontSize: '14px',
                lineHeight: '17px',
              };
        default:
          return isDesktop
            ? {
                fontSize: '18px',
                lineHeight: '19px',
              }
            : {
                fontSize: '14px',
                lineHeight: '17px',
              };
      }
    };

    return {
      fontSize: fontData().fontSize,
      lineHeight: fontData().lineHeight,
    };
  },

  title: (isDesktop: boolean, displayHeight: number, displayWidth: number) => {
    const fontData = () => {
      switch (true) {
        case IsTables(displayHeight, displayWidth):
          return {
            fontSize: '14px',
            lineHeight: '17px',
          };
        case displayHeight < 470:
          return {
            fontSize: '8px',
            lineHeight: '9px',
          };
        case displayHeight < 600:
          return {
            fontSize: '10px',
            lineHeight: '12px',
          };
        case displayHeight < 700:
          return isDesktop
            ? {
                fontSize: '12px',
                lineHeight: '14px',
              }
            : {
                fontSize: '10px',
                lineHeight: '14px',
              };
        default:
          return isDesktop
            ? {
                fontSize: '14px',
                lineHeight: '17px',
              }
            : {
                fontSize: '10px',
                lineHeight: '14px',
              };
      }
    };

    return {
      fontSize: fontData().fontSize,
      lineHeight: fontData().lineHeight,
      fontWeight: 500,
      marginTop: displayHeight < 470 ? '4px' : '9px',
    };
  },
};
