import { DimensionsPlayer } from '@Components/animatePlayersDeck/PlayersDeckContainer.type';

const mobileWidthPlayer_320 = {
  width: '296px',
  height: '525px',
};

export const adaptiveDimensionsPlayer = (
  displayWidth: number,
  displayHeight: number,
  isDesktop: boolean,
): DimensionsPlayer => {
  switch (true) {
    case displayHeight === 454 && displayWidth === 320:
      return {
        width: '135px',
        height: '240px',
      };
    case displayHeight === 608 && displayWidth === 360:
      return {
        width: '208px',
        height: '370px',
      };
    case displayHeight === 640 && displayWidth === 375:
      return {
        width: '221px',
        height: '393px',
      };
    case displayHeight === 672 && displayWidth === 390:
      return {
        width: '237px',
        height: '423px',
      };
    case displayHeight === 754 && displayWidth === 428:
      return {
        width: '272px',
        height: '484px',
      };
    case displayWidth === 768 && displayHeight === 935 && !isDesktop:
      return {
        width: '344px',
        height: '612px',
      };
    case displayHeight === 792 && displayWidth === 360:
      return {
        width: '291px',
        height: '519px',
      };
    case displayHeight === 827 && displayWidth === 412:
      return {
        width: '291px',
        height: '519px',
      };
    case (displayHeight === 1091 && displayWidth === 820) ||
      (displayHeight === 1106 && displayWidth === 834):
      return {
        width: '416px',
        height: '740px',
      };
    case displayHeight === 1192 && displayWidth === 800:
      return {
        width: '453px',
        height: '767px',
      };

    case displayHeight < 413 && !isDesktop:
      return {
        width: '114px',
        height: '203px',
      };
    case displayHeight < 430 && !isDesktop:
      return {
        width: '120px',
        height: '213px',
      };
    case displayHeight < 440 && !isDesktop:
      return {
        width: '125px',
        height: '223px',
      };
    case displayHeight < 452 && !isDesktop:
      return {
        width: '131px',
        height: '233px',
      };
    case displayHeight < 465 && !isDesktop:
      return {
        width: '137px',
        height: '243px',
      };
    case displayHeight < 480 && !isDesktop:
      return {
        width: '142px',
        height: '253px',
      };
    case displayHeight < 490 && !isDesktop:
      return {
        width: '146px',
        height: '263px',
      };
    case displayHeight < 500 && !isDesktop:
      return {
        width: '150px',
        height: '273px',
      };
    case displayHeight < 525 && !isDesktop:
      return {
        width: '158px',
        height: '283px',
      };
    case displayHeight < 550 && !isDesktop:
      return {
        width: '165px',
        height: '293px',
      };
    case displayHeight < 570 && !isDesktop:
      return {
        width: '174px',
        height: '313px',
      };
    case displayHeight < 600 && !isDesktop:
      return {
        width: '185px',
        height: '333px',
      };
    case displayHeight < 620 && !isDesktop:
      return {
        width: '198px',
        height: '353px',
      };
    case displayHeight < 650 && !isDesktop:
      return {
        width: '209px',
        height: '373px',
      };
    case displayHeight < 710 && !isDesktop:
      return {
        width: '221px',
        height: '393px',
      };
    case displayHeight < 770 && !isDesktop:
      return {
        width: '255px',
        height: '454px',
      };
    case displayHeight < 790 && !isDesktop:
      return {
        width: '255px',
        height: '470px',
      };
    case displayHeight < 810 && !isDesktop:
      return {
        width: '275px',
        height: '490px',
      };
    case displayHeight < 825 && !isDesktop:
      return {
        width: '285px',
        height: '515px',
      };
    case displayHeight < 880 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '297px',
          height: '530px',
        };
      }
      return mobileWidthPlayer_320;
    case displayHeight < 920 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '320px',
          height: '570px',
        };
      }
      return mobileWidthPlayer_320;
    case displayHeight < 965 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '343px',
          height: '610px',
        };
      }
      return mobileWidthPlayer_320;
    case displayHeight < 1020 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '365px',
          height: '650px',
        };
      }
      return mobileWidthPlayer_320;
    case displayHeight < 1080 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '388px',
          height: '690px',
        };
      }
      return mobileWidthPlayer_320;
    case displayHeight < 1125 && !isDesktop:
      if (displayWidth > 475) {
        return {
          width: '420px',
          height: '747px',
        };
      }
      return mobileWidthPlayer_320;

    case displayHeight < 490:
      return {
        width: '147px',
        height: '260px',
      };
    case displayHeight < 510:
      return {
        width: '158px',
        height: '280px',
      };
    case displayHeight < 530:
      return {
        width: '169px',
        height: '300px',
      };
    case displayHeight < 550:
      return {
        width: '180px',
        height: '320px',
      };
    case displayHeight < 570:
      return {
        width: '191px',
        height: '340px',
      };
    case displayHeight < 590:
      return {
        width: '202px',
        height: '360px',
      };
    case displayHeight < 610:
      return {
        width: '213px',
        height: '380px',
      };
    case displayHeight < 630:
      return {
        width: '225px',
        height: '400px',
      };
    case displayHeight < 650:
      return {
        width: '236px',
        height: '420px',
      };
    case displayHeight < 690:
      return {
        width: '247px',
        height: '440px',
      };
    case displayHeight < 710:
      return {
        width: '258px',
        height: '460px',
      };
    case displayHeight < 730:
      return {
        width: '269px',
        height: '480px',
      };
    case displayHeight < 800:
      return {
        width: '281px',
        height: '500px',
      };
    case displayHeight < 820:
      return {
        width: '292px',
        height: '520px',
      };
    case displayHeight < 840:
      return {
        width: '303px',
        height: '540px',
      };
    case displayHeight < 860:
      return {
        width: '314px',
        height: '560px',
      };
    case displayHeight < 880:
      return {
        width: '326px',
        height: '580px',
      };
    case displayHeight < 900:
      return {
        width: '337px',
        height: '600px',
      };
    case displayHeight < 920:
      return {
        width: '349px',
        height: '620px',
      };
    case displayHeight < 940:
      return {
        width: '359px',
        height: '640px',
      };

    case displayHeight < 960:
      return {
        width: '371px',
        height: '660px',
      };
    case displayHeight < 980:
      return {
        width: '382px',
        height: '680px',
      };
    case displayHeight < 1000:
      return {
        width: '393px',
        height: '700px',
      };
    case displayHeight < 1030:
      return {
        width: '405px',
        height: '720px',
      };
    case displayHeight < 1050:
      return {
        width: '420px',
        height: '747px',
      };

    default:
      return {
        width: '453px',
        height: '767px',
      };
  }
};
