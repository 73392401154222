import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ResDescriptionsSX, ResDescriptionsSXMedia } from './stylesSX';
import { InFullVersionBlock } from '@Pages/Results/ResDescriptios/InFullVersionBlock';
import { ScreenDesktopDimensions } from '@Constants/';
import { useTranslation } from 'react-i18next';

export interface ResDescriptionsProps {
  displayWidth: number;
  displayHeight: number;
}

export const ResDescriptions = memo((props: ResDescriptionsProps) => {
  const { displayWidth, displayHeight } = props;
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const { t }= useTranslation()

  return (
    <Box>
      <Box sx={ResDescriptionsSX.titleBox}>
        <Box sx={ResDescriptionsSXMedia.title(isDesktop)}>
          {t('Разблокируйте полную версию')}
        </Box>
      </Box>

      <Box
        sx={ResDescriptionsSXMedia.inFullDescriptionsBox(displayWidth, displayHeight, isDesktop)}>
        <Box sx={ResDescriptionsSXMedia.info(displayWidth, displayHeight, isDesktop)}>
          {t('Это набор компетенций')} <br /> {t('В каждом из')} <br /> {t('Самые крутые и')} <br />
          {t('Пройдите профориентацию до конца')}
        </Box>
        <Box sx={ResDescriptionsSXMedia.inFullVersionBox(displayWidth, isDesktop, displayHeight)}>
          <Box sx={ResDescriptionsSXMedia.inFullVersion(isDesktop, displayHeight)}>
            {t('Что в полной версии сервиса?')}
          </Box>

          <InFullVersionBlock
            isDesktop={isDesktop}
            displayHeight={displayHeight}
            blockText={t('востребованные IT профессии') }
          />
          <InFullVersionBlock
            isDesktop={isDesktop}
            displayHeight={displayHeight}
            blockText={t('видео с действующими экспертами')}
          />
          <InFullVersionBlock
            isDesktop={isDesktop}
            displayHeight={displayHeight}
            blockText={t('Смежные профессии')}
          />
          <InFullVersionBlock
            isDesktop={isDesktop}
            displayHeight={displayHeight}
            blockText={t('Подборка обучающих курсов')}
          />

          <Box sx={ResDescriptionsSXMedia.other(isDesktop, displayHeight)}>{t('И многое другое!')}</Box>
        </Box>
      </Box>
    </Box>
  );
});

ResDescriptions.displayName = 'ResDescriptions';
