import React, { memo } from 'react';
import { Box } from '@mui/material';
import { NavigateContentSX } from './stylesSX';
import { NavigateContentProps } from './NavigateContent.type';
import { NavigateProfBtn } from '@Pages/Testing/Mobile/NavigateContent/NavigateProfBtn/NavigateProfBtn';

export const NavigateContent = memo((props: NavigateContentProps) => {
  const {
    dataProfs,
    setIsLeftPanel,
    isLeftPanel,
    isAnimateChosenProf,
    animateChosenProf,
    isShowTooltip,
    setIsShowTooltip,
  } = props;

  const { left, right } = isAnimateChosenProf;

  const onBtnClick = () => {
    setIsLeftPanel(!isLeftPanel);
  };

  return (
    <Box sx={NavigateContentSX.container}>
      {dataProfs && (
        <Box sx={NavigateContentSX.buttonsBox}>
          {!right && (
            <NavigateProfBtn
              title={dataProfs[0].title}
              onBtnClick={onBtnClick}
              disabled={!isLeftPanel}
              color={dataProfs[0].icon_color}
              description={dataProfs[0].desc}
              isAnimateChosenProf={isAnimateChosenProf}
              animateChosenProf={animateChosenProf}
              isLeft={isLeftPanel}
              isShowTooltip={isShowTooltip}
              setIsShowTooltip={setIsShowTooltip}
            />
          )}

          {!left && (
            <NavigateProfBtn
              title={dataProfs[1].title}
              onBtnClick={onBtnClick}
              disabled={isLeftPanel}
              color={dataProfs[1].icon_color}
              description={dataProfs[1].desc}
              isAnimateChosenProf={isAnimateChosenProf}
              animateChosenProf={animateChosenProf}
              isLeft={isLeftPanel}
              isShowTooltip={isShowTooltip}
              setIsShowTooltip={setIsShowTooltip}
            />
          )}
        </Box>
      )}
    </Box>
  );
});

NavigateContent.displayName = 'NavigateContent';
