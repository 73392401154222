import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthTokens } from '@Models/';
import { LS_TOKENS_KEY } from '@Constants/';
import { UseLocalStorage } from '@Helpers/';

interface AuthState {
  tokens: IAuthTokens;
  isLogged: boolean;
  isPaid: boolean;
}

const authLC = new UseLocalStorage<IAuthTokens>(LS_TOKENS_KEY, {
  access_token: '',
  refresh_token: '',
});

const initialState: AuthState = {
  tokens: authLC.get(),
  isLogged: !!authLC.get()?.access_token,
  isPaid: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsPaid(state, action: PayloadAction<boolean>) {
      state.isPaid = action.payload;
    },
    setTokens(state, action: PayloadAction<IAuthTokens>) {
      state.tokens = action.payload;
      state.isLogged = true;
      authLC.set(action.payload);
    },
    deleteAccessToken(state) {
      state.tokens = { ...state.tokens, access_token: '' };
      authLC.set(state.tokens);
    },
    deleteTokens(state) {
      state.tokens = { access_token: '', refresh_token: '' };
      state.isLogged = false;
      state.isPaid = false;
      authLC.set({ access_token: '', refresh_token: '' });
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
