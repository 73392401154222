import React, { memo } from 'react';
import { Box } from '@mui/material';
import { EnterSmsProps } from './EnterSms.type';
import { EnterSmsSXMedia } from './stylesSX';
import { TextInput } from '@Components/inputs/TextInput/TextInput';
import { useTranslation } from 'react-i18next';

export const EnterSms = memo((props: EnterSmsProps) => {
  const {
    phone,
    smsValue,
    setSmsValue,
    onTransition,
    forcedType,
    errMessage,
    resetError,
    isDesktop,
  } = props;

  const { t } = useTranslation()

  const onResendCodeClick = () => {
    forcedType && onTransition(forcedType);
  };

  return (
    <Box sx={EnterSmsSXMedia.container(isDesktop, !!errMessage)}>
      {!errMessage && (
        <Box sx={[EnterSmsSXMedia.description(isDesktop), { alignItems: 'center' }]}>
          {t('Код подтверждения отправлен на')} +7 {phone}
        </Box>
      )}

      <TextInput
        value={smsValue}
        setValue={setSmsValue}
        placeholder={t('Введите код из СМС')}
        type={'phone'}
        message={errMessage}
        resetError={resetError}
      />

      <Box sx={EnterSmsSXMedia.resendCode(isDesktop)} onClick={onResendCodeClick}>
        {t('Отправить код повторно')}
      </Box>
    </Box>
  );
});

EnterSms.displayName = 'EnterSms';
