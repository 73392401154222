export const PlayersDeckSX = {
  container: {
    playerBox: {
      position: 'absolute',
      overflow: 'hidden',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '16px',
      border: '4px solid ',
      borderColor: '#000000',
      transitionDuration: '1s',
      backgroundColor: 'neutral.dark',
      cursor: 'pointer',
    },
  },
};
