import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { authActions } from '@Store/auth';
import { IAuthTokens } from '@Models/';
import { RootState } from '../store';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || 'http://localhost/',
  prepareHeaders: (headers, { getState }) => {
    let jwtToken = (getState() as RootState).auth.tokens.access_token;
    if (!jwtToken) {
      jwtToken = (getState() as RootState).auth.tokens.refresh_token;
    }
    if (jwtToken) {
      headers.set('Authorization', `Bearer ${jwtToken}`);
    }
    return headers;
  },
});
export const customQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        api.dispatch(authActions.deleteAccessToken());
        const refreshResult = await baseQuery(
          {
            url: 'api/v1/user/refresh-token',
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const tokens = refreshResult.data as IAuthTokens;
          api.dispatch(authActions.setTokens(tokens));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(authActions.deleteTokens());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
