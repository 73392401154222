import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { IProgressProfs } from '@Components/pogressBar/ProgressBar.types';

export const resultDemoApi = createApi({
  reducerPath: 'resultDemo',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['resultDemo'],
  endpoints: build => ({
    getResultDemo: build.query<IProgressProfs[], string>({
      // getResultDemo: build.query<string, string>({
      query: uuid => ({
        url: 'api/v1/get-report',
        // url: '/api/v1/get-animation-report',
        params: {
          uuid,
        },
      }),
      providesTags: ['resultDemo'],
    }),
  }),
});

export const { useLazyGetResultDemoQuery, useGetResultDemoQuery } = resultDemoApi;
