import { titleBoxHeight } from '@Pages/Testing/Desktop/lib/titleBoxHeight';

export const TitleQuestionSXMedia = {
  titleBoxSx: (displayHeight: number, displayWidth: number, isDesktop: boolean) => {
    const fontData = () => {
      switch (true) {
        case !isDesktop:
          return {
            fontSize: '6vw',
            lineHeight: displayWidth > 700 ? '6vw' : '10vw',
          };
        default:
          return {
            fontSize: '64px',
            lineHeight: '77px',
          };
      }
    };

    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'absolute',

      fontFamily: 'Fira Sans,sans-serif',
      fontStyle: 'normal',
      fontWeight: 900,
      top: titleBoxHeight(displayHeight),
      fontSize: fontData().fontSize,
      lineHeight: fontData().lineHeight,
    };
  },
};
