export const PedestalMobileSX = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconsBox: {
    position: 'absolute',
    bottom: '66px',
  },
  iconBox: {
    height: '257px',
    bottom: '66px',
  },
};
