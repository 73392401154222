import { headerHeight } from '@Components/headers/lib/headerHeight';

export const profDreamWidth = 328;

export const HeaderDesktopSX = {
  block: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  profDream: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#CBCBCB',
    width: `${profDreamWidth}px`,
    position: 'absolute',
    left: `calc(50% - ${profDreamWidth / 2}px)`,
  },
  img: {
    width: '123px',
    height: '23px',
    marginLeft: '99px',
  },
  log_in: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    color: 'neutral.dark',
    marginRight: '99px',
    cursor: 'pointer',
  },
};
export const HeaderDesktopSXMedia = {
  contentBox: (displayHeight: number) => {
    return ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#FFFFFF',
      borderBottom: '3px solid #363D98',
      position: 'relative',
      height: headerHeight(displayHeight),
    });
  },
};
