import React, { FC, PropsWithChildren } from 'react';
import { AppLoader, ThemeLoader } from '@Components/loader/LoaderContainer/AppLoader';

export interface LoaderContainerProps {
  isLoading: boolean;
  theme?: ThemeLoader;
}

export const LoaderContainer: FC<PropsWithChildren<LoaderContainerProps>> = props => {
  const { isLoading, theme, children } = props;

  return <>{isLoading ? <AppLoader isReady={isLoading} theme={theme} /> : <>{children}</>}</>;
};
