import { keyframes } from '@mui/system';
import { IElementPositions } from '@Components/profChoiceAnimationBackground/ProfChoiceAnimationBackground.type';

const showUp = keyframes`
  15% {
    background: #050505;
    opacity: 1;
  }
  75%{
    background: #050505;
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;
/* const showUp = keyframes`

  100% {
    background: #050505;
    opacity: 1;
  }
`;*/

export const ProfChoiceAnimationBackgroundSX = {
  backgroundAnimate: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    animation: `${showUp} 3s ease-in forwards`,
    overflow: 'hidden',
  },
  none: {
    display: 'none',
    overflow: 'hidden',
  },
};

export const starsPositions: IElementPositions[] = [
  { top: '', right: '', left: '20', bottom: '20', size: '112px', rotate: '' },
  { top: '', right: '', left: '10', bottom: '45', size: '110px', rotate: '24' },
  { top: '-3', right: '', left: '15', bottom: '', size: '70px', rotate: '6.66' },
  { top: '-1', right: '45', left: '', bottom: '', size: '96px', rotate: '6.66' },
  { top: '10', right: '20', left: '', bottom: '', size: '110px', rotate: '6.66' },
  { top: '35', right: '30', left: '', bottom: '', size: '96px', rotate: '' },
  { top: '55', right: '10', left: '', bottom: '', size: '100px', rotate: '6.66' },
];

export const starsLittlePositions: IElementPositions[] = [
  { top: '10', right: '', left: '5', bottom: '', size: '110px', rotate: '-24' },
  { top: '', right: '', left: '5', bottom: '20', size: '80px', rotate: '-24' },
  { top: '25', right: '15', left: '', bottom: '', size: '62px', rotate: '' },
  { top: '', right: '20', left: '', bottom: '10', size: '34px', rotate: '30' },
];

export const ribbonPositions: IElementPositions[] = [
  { top: '14', right: '', left: '25', bottom: '', size: '54px', rotate: '-43' },
  { top: '52', right: '', left: '30', bottom: '', size: '54px', rotate: '5' },
  { top: '20', right: '35', left: '', bottom: '', size: '54px', rotate: '43' },
  { top: '', right: '25', left: '', bottom: '30', size: '54px', rotate: '' },
];
export const ribbonLittlePositions: IElementPositions[] = [
  { top: '', right: '', left: '18', bottom: '10', size: '28px', rotate: '' },
  { top: '27', right: '', left: '33', bottom: '', size: '28px', rotate: '' },
  { top: '', right: '30', left: '', bottom: '27', size: '28px', rotate: '' },
  { top: '32', right: '10', left: '', bottom: '', size: '28px', rotate: '' },
];
