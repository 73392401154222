import React, { memo } from 'react';
import { Box } from '@mui/material';
import { SlideSX, SlideSXMedia } from './stylesSX';
import { ISlideProps } from './Slide.type';
import { ThemeLoader } from '@Components/loader/LoaderContainer';
import { LoaderContainer } from '@Components/loader/LoaderContainer/LoaderContainer';
import { isDesc } from '@Components/modals/modalsContent/Сlue/lib/isDesc';

export const Slide = memo((props: ISlideProps) => {
  const { displayWidth, displayHeight, isDesktop, gifs, isLoadingGifs,desc,subDesc } = props;
  return (
    <Box sx={SlideSX.slideBox}>
      <LoaderContainer isLoading={isLoadingGifs} theme={ThemeLoader.Dark}>
        {gifs && (
          <Box sx={SlideSXMedia.gif(isDesktop, displayHeight)}>
            <img
              src={isDesktop ? gifs[1].path : gifs[0].path}
              alt='loading...'
              style={{
                maxWidth: '100%',
                maxHeight: SlideSXMedia.gifMaxHeight(displayHeight, displayWidth, isDesktop),
                border: '3px solid black',
              }}
            />
          </Box>
        )}
      </LoaderContainer>
      {isDesc(desc, subDesc) &&
      <Box sx={SlideSXMedia.description(displayHeight, displayWidth, isDesktop)}>
        {desc}
        <br />
        <br />
        {subDesc}
      </Box>}
    </Box>
  );
});
Slide.displayName = 'Slide';
