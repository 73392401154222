export class UseLocalStorage<T> {
  constructor(private keyName: string, private defaultValue: T) {}

  get(): T {
    try {
      const value = localStorage.getItem(this.keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(this.keyName, JSON.stringify(this.defaultValue));
        return { ...this.defaultValue };
      }
    } catch (err) {
      console.warn(err);
      return { ...this.defaultValue };
    }
  }

  set(newValue: T) {
    try {
      localStorage.setItem(this.keyName, JSON.stringify(newValue));
    } catch (err) {
      console.warn(err);
    }
  }

  resetDefault() {
    try {
      localStorage.setItem(this.keyName, JSON.stringify(this.defaultValue));
    } catch (err) {
      console.warn(err);
    }
  }

  delete() {
    try {
      localStorage.removeItem(this.keyName);
    } catch (err) {
      console.warn(err);
    }
  }
}
