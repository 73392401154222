import React, { memo } from 'react';
import { Box } from '@mui/material';

import {
  ribbonLittlePositions,
  ribbonPositions,
  starsLittlePositions,
  starsPositions,
} from '@Components/profChoiceAnimationBackground/stylesSX';
import { ImgBlock } from '@Components/profChoiceAnimationBackground/ImgBlock';
import shine from '@Assets/images/shine.svg';
import cls
  from '@Components/profChoiceAnimationBackground/ProfChoiceAnimationBackground.module.css';
import { DesktopProfChoiceAnimationSX } from '@Components/profChoiceAnimationBackground/DesktopAnimation/stylesSX';
import { useTranslation } from 'react-i18next';

export const DesktopProfChoiceAnimation = memo(() => {
  const { t }= useTranslation()

    return (
      <Box>
        <Box sx={DesktopProfChoiceAnimationSX.backgroundIcons}>
          <ImgBlock dataPositions={starsPositions} type={'star'} />

          <ImgBlock dataPositions={starsLittlePositions} type={'little-star'} />

          <ImgBlock dataPositions={ribbonPositions} type={'ribbon'} />

          <ImgBlock dataPositions={ribbonLittlePositions} type={'ribbonLittle'} />
          <img
            src={shine}
            alt='shine'
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box sx={DesktopProfChoiceAnimationSX.massageBox}>
          <div className={cls.title}>{t('Вы выбрали')}</div>
        </Box>
      </Box>
    );
  },
);


DesktopProfChoiceAnimation.displayName = 'DesktopProfChoiceAnimation';
