export const endBoxPositions = (displayWidth: number) => {
  switch (true) {
    case displayWidth < 1690 && displayWidth > 1459:
      return {
        fall: 218,
        fall1: 220,
        fall2: 342,
        fall3: 429,
      };
    default:
      return {
        fall: 170,
        fall1: 160,
        fall2: 270,
        fall3: 340,
      };
  }
};

export const boxSX = (i: number) => {
  switch (i) {
    case 0:
      return PedestalSX.container.emptyBoxBlock0;
    case 1:
      return PedestalSX.container.emptyBoxBlock1;
    case 2:
      return PedestalSX.container.emptyBoxBlock2;
    default:
      return PedestalSX.container.emptyBoxBlock3;
  }
};

export const boxSXPosition = (i: number, displayWidth: number) => {
  switch (i) {
    case 0:
      if (displayWidth < 1460) {
        return {
          top: '-68%',
          left: '18%',
        };
      } else if (displayWidth < 1690) {
        return {
          top: '-57%',
          left: '27%',
        };
      } else {
        return {
          top: '-35%',
          left: '35%',
        };
      }

    case 1:
      if (displayWidth < 1460) {
        return {
          top: '-67%',
          left: '6%',
        };
      } else if (displayWidth < 1690) {
        return {
          top: '-57%',
          left: '10%',
        };
      } else {
        return {
          top: '-34%',
          left: '14%',
        };
      }
    case 2:
      if (displayWidth < 1460) {
        return {
          top: '-82%',
          left: '28%',
        };
      } else if (displayWidth < 1690) {
        return {
          top: '-65%',
          left: '41%',
        };
      } else {
        return {
          top: '-35%',
          left: '54%',
        };
      }

    default:
      if (displayWidth < 1460) {
        return {
          top: '-92%',
          left: '38%',
        };
      } else if (displayWidth < 1690) {
        return {
          top: '-71%',
          left: '56%',
        };
      } else {
        return {
          top: '-34%',
          left: '74%',
        };
      }
  }
};

export const adaptiveDataPedestal = (displayWidth: number, displayHeight: number) => {
  switch (true) {
    case displayWidth < 1045:
      return {
        width: '50%',
        right: '-41vw',
        bottom: '7vh',
      };
    // case displayWidth < 1180:
    case displayWidth < 1460:
      return {
        width: '50%',
        right: '-36vw',
        bottom: '7vh',
      };
    /*   case displayWidth < 1460:
      return {
        width: '50%',
        right: '-31vw',
        bottom: '11vh',
      };*/

    case displayWidth < 1690:
      if (displayHeight < 930) {
        return {
          width: '75%',
          right: '-15vw',
          bottom: '0px',
        };
      } else {
        return {
          width: '75%',
          right: '-15vw',
          bottom: '11vh',
        };
      }
    default:
      return {
        width: '100%',
        right: '0',
        bottom: '0',
      };
  }
};

export const PedestalSX = {
  container: {
    position: 'absolute',

    emptyBoxBlock0: {
      position: 'absolute',
      animation: '',
      transform: '',
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
        animation: '',
        transition: 'transform .2s',
      },
    },
    emptyBoxBlock1: {
      position: 'absolute',
      animation: '',
      transform: '',
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
        animation: '',
        transition: 'transform .2s',
      },
    },
    emptyBoxBlock2: {
      position: 'absolute',
      animation: '',
      transform: '',
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
        animation: '',
        transition: 'transform .2s',
      },
    },
    emptyBoxBlock3: {
      position: 'absolute',
      animation: '',
      transform: '',
      transition: 'transform 1s',
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
        transition: 'transform 1s',
        animation: '',
      },
    },
  },
};
