export const DesktopResultsSX = {
  container: {
    position: 'absolute',
    backgroundColor: '#FDFE00',
    left: 0,
  },
};

export const DesktopResultsSXMedia = {
  container: (headerHeight: string, isDesktop: boolean) => {
    return isDesktop
      ? {
          top: `calc(${headerHeight} + 3px)`,
          height: `calc(100% - ${headerHeight} - 3px)`,
          overflow: 'hidden',
        }
      : {
          top: `calc(${headerHeight} + 3px)`,
          minHeight: `calc(100% - ${headerHeight})`,
        };
  },
};
