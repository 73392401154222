export const PromoSXMedia = {
  container: (isDesktop: boolean, errMessage: boolean) => {
    const height = () => {
      if (errMessage) {
        return isDesktop ? '186px' : '90px';
      }
      return isDesktop ? '257px' : '121px';
    };
    const marginTop = () => {
      if (errMessage) {
        return isDesktop ? '79px' : '31px';
      }
      return 0;
    };
    return {
      height: height(),
      marginTop: marginTop(),
    };
  },
  backImgBox: (isDesktop: boolean) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    margin: isDesktop ? '38px' : '7px 10px;',
    cursor: 'pointer',
  }),

  messageBox: (isDesktop: boolean) => ({
    paddingTop: isDesktop ? '69px' : '31px',
  }),
};
