export const TextInputSXMedia = {
  container: (isDesktop: boolean, message: string | undefined) => {
    const borderSize = isDesktop ? 3 : 1;
    return {
      position: 'relative',
      height: isDesktop ? '67px' : '37px',
      width: isDesktop ? '455px' : '200px',
      border: message ? `${borderSize}px solid #EB0000` : `${borderSize}px solid #000000`,
      borderRadius: isDesktop ? '16px' : '7px',
      margin: 0,
    };
  },
  clearIconBox: (isDesktop: boolean) => ({
    position: 'absolute',
    cursor: 'pointer',
    top: isDesktop ? '24px' : '4px',
    right: isDesktop ? '24px' : '9px',
  }),
  iconPx: (isDesktop: boolean) => (isDesktop ? '18px' : '9px'),

  messageBox: (isDesktop: boolean) => {
    const desktop = {
      color: '#EB0000',

      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      position: 'absolute',
      top: '152px',
    };
    const mobile = {
      margin: '0 0 6px 0',
      color: '#EB0000',

      fontWeight: 600,
      fontSize: '8px',
      lineHeight: '10px',
      position: 'absolute',
      top: '73px',
      whiteSpace: 'nowrap',
    };
    return isDesktop ? desktop : mobile;
  },
};
