export const iconWidth = (displayWidth: number, displayHeight: number): string => {
  switch (true) {
    case displayHeight < 480:
      return '30px';
    case displayHeight < 540:
      return '36px';
    case displayHeight < 650:
      return '43px';
    case displayHeight < 700:
      return '43px';
    case displayHeight < 930:
      return '50px';
    case displayHeight < 1080:
      return '57px';
    default:
      return '77px';
  }
};
