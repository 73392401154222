import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { IInfoGifs } from '@Pages/Testing';

export const infoGifsApi = createApi({
  reducerPath: 'infoGifs',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['infoGifs'],

  endpoints: build => ({
    getGifs: build.query<IInfoGifs[], string>({
      query: () => ({
        url: `api/v1/get-gifs`,
      }),
      providesTags: ['infoGifs'],
    }),
  }),
});

export const { useLazyGetGifsQuery } = infoGifsApi;
