import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi, authReducer } from './auth/';
import { demoTestingApi } from '@Store/demoTesting';
import { uuidApi } from '@Store/uuid/uuid.api';
import { progressBarApi } from '@Store/progressBar/progressBar.api';
import { resultDemoApi } from '@Store/result/resultDemo.api';
import { registrationApi } from '@Store/registration/registration.api';
import { currentTagApi } from '@Store/CurrentTag';
import { infoGifsApi } from '@Store/startGifs';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [demoTestingApi.reducerPath]: demoTestingApi.reducer,
    [uuidApi.reducerPath]: uuidApi.reducer,
    [progressBarApi.reducerPath]: progressBarApi.reducer,
    [resultDemoApi.reducerPath]: resultDemoApi.reducer,
    [registrationApi.reducerPath]: registrationApi.reducer,
    [currentTagApi.reducerPath]: currentTagApi.reducer,
    [infoGifsApi.reducerPath]: infoGifsApi.reducer,
    auth: authReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      demoTestingApi.middleware,
      uuidApi.middleware,
      progressBarApi.middleware,
      resultDemoApi.middleware,
      registrationApi.middleware,
      currentTagApi.middleware,
      infoGifsApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
