import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { VideoDescriptionSXMedia } from './stylesSX';
import { VideoDescriptionProps } from './VideoDescription.type';
import { ScreenDesktopDimensions } from '@Constants/';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';

export const VideoDescription = memo((props: VideoDescriptionProps) => {
  const { desc, title } = props;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const { height: displayHeight, width: displayWidth } = useWindowDimensions();

  return (
    <Box sx={VideoDescriptionSXMedia.container(isDesktop, displayHeight, displayWidth)}>
      <Box sx={VideoDescriptionSXMedia.desc(isDesktop, displayHeight, displayWidth)}>{desc}</Box>
      <Box sx={VideoDescriptionSXMedia.title(isDesktop, displayHeight, displayWidth)}>{title}</Box>
    </Box>
  );
});

VideoDescription.displayName = 'VideoDescription';
