import { keyframes } from '@mui/system';
import { ResButtonsTypes } from '@Pages/Results/ResButtons/ResButtons.type';

export const ResButtonsSX = {
  btnContent: {
    fontWeight: 700,
    lineHeight: '19px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
  },
  promoBtnBox: {
    display: 'flex',
  },
  promoTitle: {
    marginRight: '12px',
  },
};

export const ResButtonsSXMedia = {
  container: (type: ResButtonsTypes) => ({
    marginLeft: type === 'desktop' ? '9vw' : '0',
    position: 'absolute',
    bottom: type === 'desktop' ? '59px' : '10px',
  }),
  btnBox: (type: ResButtonsTypes, displayHeight: number, displayWidth: number) => {
    const marginTop = () => {
      if (displayWidth < 1140 && displayWidth > 1000 && displayHeight < 1000) {
        return '8px';
      }
      return type === 'desktop' ? '23px' : '8px';
    };
    return {
      marginTop: marginTop(),
    };
  },

  btnUnlockSXData: (displayWidth: number) => ({
    color: 'black',
    backgroundColor: '#FF9400',
    maxWidth: ResButtonsSXMedia.btnMaxWidth(displayWidth),
    height: '6vh',
    borderRadius: '100px',
  }),
  btnPromoSXData: (displayWidth: number, type: ResButtonsTypes) => ({
    color: 'black',
    backgroundColor: type === 'desktop' ? 'transparent' : '#FDFE00',
    maxWidth: ResButtonsSXMedia.btnMaxWidth(displayWidth),
    height: '6vh',
  }),

  btnMaxWidth: (displayWidth: number) => {
    switch (true) {
      case displayWidth < 1366:
        return '200px';
      default:
        return '384px';
    }
  },
  btnFontSize: (displayWidth: number) => {
    switch (true) {
      case displayWidth < 1366:
        return { fontSize: '14px' };
      default:
        return { fontSize: '16px' };
    }
  },
  btnAnimation: (isAnimation: boolean) =>
    isAnimation
      ? {
          animation: `${movement} 2s linear infinite`,
        }
      : {},
};

const movement = keyframes`
  5% {
    transform: rotate(0) translate(0, 0);
  }
  15% {
    transform: rotate(5deg) translate(0, -2px);
  }
  25% {
    transform: rotate(-3deg) translate(0, -2px);
  }
  35% {
    transform: rotate(5deg) translate(0, -2px);
  }
  45% {
    transform: rotate(-3deg) translate(0, -2px);
  }
  55% {
    transform: rotate(5deg) translate(0, -2px);
  }
  65% {
    transform: rotate(-3deg) translate(0, -2px);
  }
  70% {
    transform: rotate(0) translate(0, 0);
  }
`;
