import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { UseLocalStorage } from '@Helpers/';
import { IUuid } from '@Models/';
import { ScreenDesktopDimensions, UUID } from '@Constants/';
import { useLazyUserPriceAnonQuery } from '@Store/registration';
import { DesktopResults } from '@Pages/Results/DesktopResults/DesktopResults';
import { MobileResults } from '@Pages/Results/MobileResults/MobileResults';
import { DesktopResultsSX, DesktopResultsSXMedia } from '@Pages/Results/DesktopResults/stylesSX';
import { headerHeight } from '@Components/headers/lib/headerHeight';
import { HeaderContainer } from '@Components/headers';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const Results = memo(() => {
  const [isPromoModal, setIsPromoModal] = useState(false);
  const [isUnlockModal, setIsUnlockModal] = useState(false);
  const [isAnimationBtn, setIsAnimationBtn] = useState(false);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const { height: displayHeight } = useWindowDimensions();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setIsAnimationBtn(false);
    }, 2000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isAnimationBtn]);

  const uuidLS = useMemo(
    () =>
      new UseLocalStorage<IUuid>(UUID, {
        uuid: '',
      }),
    [],
  );
  const uuid = uuidLS.get().uuid;

  const [getUserPriceAnon, { data: userPriceAnonData }] = useLazyUserPriceAnonQuery();

  useEffect(() => {
    getUserPriceAnon({ uuid });
  }, [isPromoModal]);

  const onUnlockClick = () => {
    setIsUnlockModal(!isUnlockModal);
  };

  const onPromoClick = () => {
    setIsPromoModal(isPromo => !isPromo);
  };

  const onProfClick = () => {
    setIsAnimationBtn(true);
  };

  return (
    <HeaderContainer uuid={uuid}>
      <Box
        sx={[
          DesktopResultsSX.container,
          DesktopResultsSXMedia.container(headerHeight(displayHeight), isDesktop),
        ]}>
        {isDesktop ? (
          <DesktopResults
            uuid={uuid}
            userPriceAnonData={userPriceAnonData}
            onProfClick={onProfClick}
            onPromoClick={onPromoClick}
            onUnlockClick={onUnlockClick}
            isAnimationBtn={isAnimationBtn}
            isUnlockModal={isUnlockModal}
            setIsUnlockModal={setIsUnlockModal}
            isPromoModal={isPromoModal}
            setIsPromoModal={setIsPromoModal}
          />
        ) : (
          <MobileResults
            userPriceAnonData={userPriceAnonData}
            onProfClick={onProfClick}
            onPromoClick={onPromoClick}
            onUnlockClick={onUnlockClick}
            isAnimationBtn={isAnimationBtn}
            uuid={uuid}
            isUnlockModal={isUnlockModal}
            setIsUnlockModal={setIsUnlockModal}
            isPromoModal={isPromoModal}
            setIsPromoModal={setIsPromoModal}
          />
        )}
      </Box>
    </HeaderContainer>
  );
});

Results.displayName = 'Results';
