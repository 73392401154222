import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { IProgressProfsApi } from '@Components/pogressBar/ProgressBar.types';

export const progressBarApi = createApi({
  reducerPath: 'progressBar',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['progressBar'],
  endpoints: build => ({
    fetchProgressBar: build.query<IProgressProfsApi, string>({
      query: uuid => ({
        url: 'api/v1/get-progress-bar',
        params: {
          uuid,
        },
      }),
      providesTags: ['progressBar'],
    }),
  }),
});

export const { useLazyFetchProgressBarQuery } = progressBarApi;
