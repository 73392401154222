import React, { memo } from 'react';
import { Box } from '@mui/material';
import { PlayBlockSX } from './stylesSX';
import { PlayBlockProps } from './PlayBlock.type';
import playIcon from '@Assets/images/icons/play.svg';
import { AppLoader } from '@Components/loader/LoaderContainer/AppLoader/AppLoader';
import { MetrikValues, sendMetrik } from '../../../../../utils/yaMetrica/metriks';

export const PlayBlock = memo((props: PlayBlockProps) => {
  const { playbackControl, isReady, isAnimateMode } = props;

  const playbackControlHandle = () => {
    sendMetrik('reachGoal', MetrikValues.VideoPlayed, {});
    playbackControl();
  };

  return (
    <Box
      sx={PlayBlockSX.container}
      onClick={isReady && !isAnimateMode ? playbackControlHandle : undefined}>
      {isReady ? <img src={playIcon} alt='play icon' /> : <AppLoader isReady={!isReady} />}
    </Box>
  );
});

PlayBlock.displayName = 'PlayBlock';
