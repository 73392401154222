export const ButtonsBlockSX = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6px',
  },
  tagsBox: {
    marginLeft: '8px',
    maxWidth: '75%',
  },
  chooseDirectionBox: {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '5px',
  },
};
export const ButtonsBoxSXMedia = {
  container: (displayWidth: number) => ({
    display: 'flex',
    width: displayWidth < 366 ? '100%' : '366px',
    justifyContent: 'space-between',
  }),
};
