export const ResDataBoxSX = {
  container: {},
  titleTablet: {
    background: 'white',
    border: '4px solid black',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '5px',
    transform: 'translate(-39px, -32px) rotate(20deg)',

    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
  },
  cardCornerBox: {
    position: 'absolute',
    right: '-16px',
    top: '0',
    transform: 'translate(-6px, 6px) rotate(-20deg)',
  },
};

export const resDataContainerWidth = (displayWidth: number) => {
  switch (true) {
    case displayWidth < 1460:
      return '90px';
    case displayWidth < 1690:
      return '100px';
    default:
      return '117px';
  }
};

export const cardCornerWidth = (displayWidth: number) => {
  switch (true) {
    case displayWidth < 1690:
      return '90%';
    default:
      return '100%';
  }
};
