import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { ITagData } from '@Pages/Testing';

export const currentTagApi = createApi({
  reducerPath: 'currentTag',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['currentTag'],
  endpoints: build => ({
    getTag: build.query<ITagData, number>({
      query: tagId => ({
        url: `api/v1/get-tag/${tagId}`,
      }),
      providesTags: ['currentTag'],
    }),
  }),
});

export const { useGetTagQuery } = currentTagApi;
