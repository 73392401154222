import React, { memo, useEffect, useState } from 'react';
import { HeaderContainer } from '@Components/headers/HeaderContainer';
import { Box } from '@mui/material';
import { MobileTestingSXMedia } from '@Pages/Testing/Mobile/stylesSX';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IsShowTooltip, IVideoData, TestingProps } from '@Pages/Testing';
import { NavigateContent } from '@Pages/Testing/Mobile/NavigateContent/NavigateContent';
import { ProfessionContainer } from '@Components/players/DesktopPlayer/ProfessionContainer';
import { CSSTransition } from 'react-transition-group';
import styles from '@Pages/Testing/Desktop/DesktopTesting.module.css';
import { manifestation } from '../../../utils/helpers/animate/animates';
import { ProgressBarContainer } from '@Components/pogressBar';
import { TitleQuestion } from '@Pages/Testing/Desktop/TitleQuestion/TitleQuestion';
import { useChooseProfMutation } from '@Store/demoTesting';
import { ButtonsBlock } from '@Pages/Testing/Mobile/ButtonsBlock/ButtonsBlock';
import { MetrikValues, sendMetrik } from '../../../utils/yaMetrica/metriks';
import { setCurrentNumLike } from '@Components/players/DesktopPlayer/lib/setCurrentNumLike';

export const MobileTesting = memo((props: TestingProps) => {
  const {
    isAnimateMode,
    uuid,
    isProgressBar,
    progressBarData,
    dataProfs,
    isLeftPlayed,
    isRightPlayed,
    playbackControlHandle,
    isAnimateChosenProf,
    setIsAnimateChosenProf,
    stopPlayback,
  } = props;
  const [isLeftPanel, setIsLeftPanel] = useState(true);
  const [currentContent, setCurrentContent] = useState<IVideoData>();
  const [activeTags, setActiveTags] = useState(0);

  const [isShowTooltip, setIsShowTooltip] = useState<IsShowTooltip>({
    isLeft: false,
    isRight: false,
  });
  const [videoWatchedCounter, setVideoWatchedCounter] = useState(3);
  const { width: displayWidth, height: displayHeight } = useWindowDimensions();

  const { left, right } = isAnimateChosenProf;

  const [chooseProf] = useChooseProfMutation();

  useEffect(() => {
    if (dataProfs) {
      isLeftPanel ? setCurrentContent(dataProfs[0]) : setCurrentContent(dataProfs[1]);
    }
  }, [dataProfs, isLeftPanel]);

  useEffect(() => {
    setActiveTags(0);
  }, [currentContent]);

  const onProfClick = () => {


    const animate = isLeftPanel
      ? { ...isAnimateChosenProf, left: !left }
      : { ...isAnimateChosenProf, right: !right };

    setIsAnimateChosenProf(animate);

    // Остановить воспроизведение всех плееров
    stopPlayback();

    // Закрыть тултипы
    setIsShowTooltip({
      isLeft: false,
      isRight: false,
    });

    if (currentContent) {
      chooseProf({ uuid, profId: currentContent.id });
      // Счетчик количества лайков для метрики
      setCurrentNumLike(currentContent.id);
    }
  };

  const colors = dataProfs && dataProfs.map(({ background_color }) => background_color);

  const backgroundColors = (colors: string[] | undefined, isLeftPanel: boolean) => {
    if (isLeftPanel && colors) {
      return [colors[0]];
    }
    if (!isLeftPanel && colors) {
      return [colors[1]];
    }
  };

  return (
    <HeaderContainer
      backgroundColors={backgroundColors(colors, isLeftPanel)}
      uuid={uuid}
      isAnimateMode={isAnimateMode}>
      <CSSTransition
        in={isProgressBar}
        timeout={950}
        mountOnEnter
        unmountOnExit
        classNames={{
          enterActive: styles['title_active'],
          exitActive: styles['title_exit'],
        }}>
        <Box
          sx={[manifestation(isProgressBar), MobileTestingSXMedia.progressBarBox(displayHeight)]}>
          <ProgressBarContainer testingType={'mobile'} progressBarData={progressBarData} />
        </Box>
      </CSSTransition>

      <CSSTransition
        in={!isProgressBar}
        timeout={950}
        mountOnEnter
        unmountOnExit
        classNames={{
          enterActive: styles['title_active'],
          exitActive: styles['title_exit'],
        }}>
        <TitleQuestion />
      </CSSTransition>

      {dataProfs && dataProfs.length > 1 && (
        <Box sx={MobileTestingSXMedia.container(displayHeight)}>
          <Box>
            <ProfessionContainer
              isLeft={isLeftPanel}
              videoData={currentContent}
              isLeftPlayed={isLeftPlayed}
              isRightPlayed={isRightPlayed}
              onPlaybackControlHandle={playbackControlHandle}
              isAnimateChosenProf={isAnimateChosenProf}
              animateChosenProf={setIsAnimateChosenProf}
              isAnimateMode={isAnimateMode}
              uuid={uuid}
              progressBarData={progressBarData}
              testingType={'mobile'}
              setActiveTags={setActiveTags}
              stopPlayback={stopPlayback}
            />

            <NavigateContent
              dataProfs={dataProfs}
              setIsLeftPanel={setIsLeftPanel}
              isLeftPanel={isLeftPanel}
              isAnimateChosenProf={isAnimateChosenProf}
              animateChosenProf={setIsAnimateChosenProf}
              isShowTooltip={isShowTooltip}
              setIsShowTooltip={setIsShowTooltip}
            />
          </Box>

          <ButtonsBlock
            currentContent={currentContent}
            activeTags={activeTags}
            onProfClick={onProfClick}
            isAnimateMode={isAnimateMode}
            videoWatchedCounter={videoWatchedCounter}
            displayWidth={displayWidth}
            displayHeight={displayHeight}
          />
        </Box>
      )}
    </HeaderContainer>
  );
});

MobileTesting.displayName = 'MobileTesting';
