export const NavigateProfBtnSX = {
  container: {
    background: '#FFFFFF',
    border: '2px solid #000000',
    borderRadius: '8px',
    padding: '8px',
    position: 'relative',
    width: '100%',
    margin: '8px',
    whiteSpace: 'nowrap',
    maxWidth: '150px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    overflow: 'hidden',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',

    color: 'neutral.dark',
  },
};
export const NavigateProfBtnSXMedia = {
  container: (disabled: boolean, color: string) => ({
    boxShadow: `3px 3px 0 ${color}`,
    opacity: disabled ? 0.5 : 1,
  }),
  info: () => ({
    position: 'absolute',
    right: '8px',
    background: '#FFFFFF',
    boxShadow: '-8px 0 8px #FFFFFF',
  }),
};
