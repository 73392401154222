import { ActiveModalsTypes } from '@Components/modals/modalsContent/Unlock/AuthModalsContainer.type';

export const AuthModalsSX = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'neutral.dark',
  },
  btnBox: {
    position: 'absolute',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontWeight: 600,
  },
};

export const AuthModalsSXMedia = {
  container: (isDesktop: boolean) => ({
    margin: isDesktop ? '50px' : '15px',
  }),

  btnBox: (isDesktop: boolean, activeTypeModal: ActiveModalsTypes) => {
    const bottom = () => {
      if (activeTypeModal === 'auth') {
        return isDesktop ? '114px' : '74px';
      }
      return isDesktop ? '50px' : '19px';
    };
    return {
      bottom: bottom(),
    };
  },

  btnSxData: (isDesktop: boolean) => ({
    color: '#F0671A',
    maxWidth: isDesktop ? '382px' : '135px',
    height: isDesktop ? '79px' : '32px',
    borderRadius: '100px',
    backgroundColor: 'transparent',
  }),
  title: (isDesktop: boolean) => ({
    fontSize: isDesktop ? '32px' : '18px',
    lineHeight: isDesktop ? '38px' : '25px',
    marginTop: isDesktop ? '0' : '16px',
  }),

  phoneNumberContainer: (
    isDesktop: boolean,
    errMessage: boolean,
    activeTypeModal: ActiveModalsTypes,
  ) => {
    const top = () => {
      if (errMessage) {
        return isDesktop ? '76px' : 0;
      }
      return 0;
    };

    const height = () => {
      if (activeTypeModal === 'auth') {
        return isDesktop ? '304px' : '150px';
      }
      return isDesktop ? '266px' : '110px';
    };

    return {
      marginTop: isDesktop ? 0 : '33px',
      maxWidth: isDesktop ? '100%' : '238px',
      height: height(),
      position: 'relative',
      top: top(),
    };
  },

  description: (isDesktop: boolean) =>
    isDesktop
      ? {
          fontSize: '20px',
          lineHeight: '24px',
          padding: '39px 0 13px 0',
        }
      : {
          position: 'absolute',
          top: '-15px',
          fontSize: '10px',
          lineHeight: '12px',
          padding: '0',
        },
  message: (isDesktop: boolean) => ({
    fontWeight: 400,
    fontSize: isDesktop ? '15px' : '8px',
    lineHeight: isDesktop ? '17px' : '10px',
    textAlign: 'center',
    color: 'neutral.dark',
    position: 'absolute',
    bottom: isDesktop ? '44px' : '17px',
  }),
};
