import React, { memo } from 'react';
import { Box } from '@mui/material';
import { PhoneNumberInput } from '@Components/inputs/PhoneNumberInput/PhoneNumberInput';
import { EnterPhoneProps } from './EnterPhone.type';
import {
  AuthModalsSX,
  AuthModalsSXMedia,
} from '@Components/modals/modalsContent/Unlock/AuthModals/stylesSX';
import { useTranslation } from 'react-i18next';

export const EnterPhone = memo((props: EnterPhoneProps) => {
  const { phoneNumber, setPhoneNumber, errMessage, resetError, isDesktop, activeTypeModal } = props;

  const { t } = useTranslation()

  const onSetPhoneNumber = (value: string) => {
    resetError();
    setPhoneNumber(value);
  };

  return (
    <Box sx={AuthModalsSXMedia.phoneNumberContainer(isDesktop, !!errMessage, activeTypeModal)}>
      {!errMessage && (
        <Box sx={[AuthModalsSX.description, AuthModalsSXMedia.description(isDesktop)]}>{t('Телефон')}</Box>
      )}
      <Box
        sx={{
          top: '76px',
        }}>
        <PhoneNumberInput
          errMessage={errMessage}
          resetError={resetError}
          value={phoneNumber}
          setValue={onSetPhoneNumber}
        />
      </Box>
    </Box>
  );
});

EnterPhone.displayName = 'EnterPhone';
