import { IProgressProfs } from '@Components/pogressBar';
import { sortedLocationIdx } from '@Components/pogressBar/lib/sortedLocationIdx';
import { AnimateTypeData } from '@Components/pogressBar/lib/animationCardsHTML';

export const animationType = (
  cardId: number,
  newCards: IProgressProfs[],
  oldCards: IProgressProfs[],
): AnimateTypeData => {
  const idxOldPosition = sortedLocationIdx(oldCards, cardId);
  const idxNewPosition = sortedLocationIdx(newCards, cardId);

  if (idxOldPosition || idxOldPosition === 0) {
    if (idxOldPosition > idxNewPosition) {
      return {
        type: 'blockMoveToLeft',
        factor: idxOldPosition - idxNewPosition,
      };
    }

    if (idxOldPosition < idxNewPosition) {
      return {
        type: 'blockMoveToRight',
        factor: idxNewPosition - idxOldPosition,
      };
    }
  }

  if (idxOldPosition === -1) {
    return { type: 'newCard', factor: 10 };
  }

  return { type: 'oldPosition', factor: 1 };
};
