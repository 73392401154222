export const PriceLineSXMedia = {
  line: (isDesktop: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: isDesktop ? '424px' : '180px',
    margin: isDesktop ? '18px' : '7px',
  }),
  title: (isDesktop: boolean) => ({
    fontWeight: 400,
    fontSize: isDesktop ? '24px' : '10px',
    lineHeight: isDesktop ? '29px' : '12px',
    color: 'neutral.dark',
  }),
  price: (isDesktop: boolean) => ({
    fontWeight: 600,
    fontSize: isDesktop ? '24px' : '12px',
    lineHeight: isDesktop ? '29px' : '14px',
    color: 'neutral.dark',
  }),
};
