export const EnterSmsSXMedia = {
  container: (isDesktop: boolean, errMessage: boolean) => {
    const heightBlock = () => {
      if (errMessage) {
        return isDesktop ? '190px' : '124px';
      }
      return isDesktop ? '266px' : '152px';
    };
    const marginTop = () => {
      if (errMessage) {
        return isDesktop ? '75px' : '31px';
      }
      return 0;
    };

    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: heightBlock(),
      marginTop: marginTop(),
    };
  },

  description: (isDesktop: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontWeight: 600,
    fontSize: isDesktop ? '20px' : '8px',
    lineHeight: isDesktop ? '24px' : '10px',
    padding: isDesktop ? '39px 0 16px' : '15px 0 6px 0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  }),

  resendCode: (isDesktop: boolean) => ({
    fontWeight: 500,
    fontSize: isDesktop ? '16px' : '10px',
    lineHeight: isDesktop ? '19px' : '12px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    marginTop: isDesktop ? '15px' : '7px',
  }),
};
