import { Dispatch, SetStateAction } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  ActiveModalsTypes,
  EventTypes,
} from '@Components/modals/modalsContent/Unlock/AuthModalsContainer.type';
import { errorHandle } from '@Components/modals/modalsContent/Unlock/lib/errorHandle';

export const queryEffect = (
  isSuccess: boolean,
  isErr: boolean,
  error: FetchBaseQueryError | SerializedError | undefined,
  setErrMessage: Dispatch<SetStateAction<undefined>>,
  onTransition: (type: ActiveModalsTypes) => void,
  eventType: EventTypes,
  activeTypeModal: ActiveModalsTypes,
  setIsPromoModal?: (isPromoModal: boolean) => void,
) => {
  if (isSuccess) {
    setErrMessage(undefined);

    switch (activeTypeModal) {
      case 'phone':
      case 'auth':
        return onTransition('sms');
      case 'sms':
        return eventType === 'registration' && onTransition('payment');
      case 'promo':
        return eventType === 'registration'
          ? onTransition('payment')
          : setIsPromoModal && setIsPromoModal(false);
      default:
        return onTransition('phone');
    }
  }
  if (isErr) {
    errorHandle(error, setErrMessage);
  }
};
