import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DesktopTesting, IVideoData, MobileTesting } from '@Pages/Testing/index';
import { useMediaQuery } from '@mui/material';
import { ScreenDesktopDimensions, UUID } from '@Constants/';
import { useNavigate } from 'react-router-dom';
import { UseLocalStorage } from '@Helpers/';
import { IUuid } from '@Models/';
import { useLazyFetchPairForTestingQuery } from '@Store/demoTesting';
import { useLazyGetResultDemoQuery } from '@Store/result/resultDemo.api';
import { useLazyGetUuidQuery } from '@Store/uuid';
import { useLazyFetchProgressBarQuery } from '@Store/progressBar';
import { RoutePathDemo } from '../../utils/routeConfig/routeConfigDemo';
import { IAnimateChosenProf } from '@Components/players/DesktopPlayer/DesktopPlayer.types';
import { NestedModal } from '@Components/modals';
import { Clue } from '@Components/modals/modalsContent/Сlue/Clue';
import { useLazyGetGifsQuery } from '@Store/startGifs';

const LANDING_URL = 'https://itorient.ru/afterdemo';

export const DemoTest = memo(() => {
  const [dataProfessions, setDataProfessions] = useState<IVideoData[] | undefined>();
  const [isShowStartModal, setIsShowStartModal] = useState(false);
  const [isLeftPlayed, setIsLeftPlayed] = useState(false);
  const [isRightPlayed, setIsRightPlayed] = useState(false);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [isAnimateMode, setIsAnimateMode] = useState(false);
  const [isAnimateChosenProf, setIsAnimateChosenProf] = useState<IAnimateChosenProf>({
    left: false,
    right: false,
  });

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const uuidLS = useMemo(
    () =>
      new UseLocalStorage<IUuid>(UUID, {
        uuid: '',
      }),
    [],
  );
  const uuid = uuidLS.get().uuid;
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const [fetchProfs, { data: dataProfs }] = useLazyFetchPairForTestingQuery();
  const [getResults, { data: results }] = useLazyGetResultDemoQuery();
  const [getUserId, { data: userid }] = useLazyGetUuidQuery();
  const [fetchProgressBarData, { data: progressBarData }] = useLazyFetchProgressBarQuery();
  const [getGifs, { data: dataGifs, isLoading: isLoadingGifs }] = useLazyGetGifsQuery();

  useEffect(() => {
    if (results){
      localStorage.setItem('uuid','')
      window.location.href = LANDING_URL;
    }
  }, [results]);

  useEffect(() => {
    if (uuid) {
      fetchProfs(uuid);
    } else {
      getUserId('');
      getGifs('');
      setTimeout(() => {
        setIsShowStartModal(true);
      },1000)

    }
  }, [fetchProfs, getUserId, uuid]);

  useEffect(() => {
    if (userid) {
      uuidLS.set(userid);
      fetchProfs(userid.uuid);
    }
  }, [fetchProfs, userid, uuidLS]);

  useEffect(() => {
    if (dataProfs && dataProfs.length < 2) {
      getResults(uuid);
    } else if (uuid && !results) {
      if (isAnimateMode) {
        timerRef.current = setTimeout(() => {
          setDataProfessions(dataProfs);
        }, 3000);
      } else {
        setDataProfessions(dataProfs);
      }

      fetchProgressBarData(uuid);
    }
  }, [dataProfs, fetchProgressBarData, getResults, results, uuid]);

  useEffect(() => {
    if (progressBarData) {
      isAnimateMode && setIsProgressBar(true);
    }
  }, [isAnimateMode, progressBarData]);

  useEffect(() => {
    progressBarData && progressBarData.progress_bar.length > 0 && setIsProgressBar(true);
  }, [progressBarData]);

  useEffect(() => {
    const { left, right } = isAnimateChosenProf;

    setIsAnimateMode(left || right);
  }, [isAnimateChosenProf]);

  const playbackControlHandle = useCallback(
    (isLeft: boolean) => {
      if (isLeft) {
        setIsLeftPlayed(!isLeftPlayed);
        setIsRightPlayed(false);
      }
      if (!isLeft) {
        setIsRightPlayed(!isRightPlayed);
        setIsLeftPlayed(false);
      }
    },
    [isLeftPlayed, isRightPlayed],
  );

  const stopPlayback = () => {
    setIsLeftPlayed(false);
    setIsRightPlayed(false);
  };

  return (
    <>
      {isDesktop ? (
        <DesktopTesting
          isAnimateMode={isAnimateMode}
          uuid={uuid}
          isProgressBar={isProgressBar}
          progressBarData={progressBarData}
          dataProfs={dataProfessions}
          isLeftPlayed={isLeftPlayed}
          isRightPlayed={isRightPlayed}
          playbackControlHandle={playbackControlHandle}
          isAnimateChosenProf={isAnimateChosenProf}
          setIsAnimateChosenProf={setIsAnimateChosenProf}
          stopPlayback={stopPlayback}
        />
      ) : (
        <MobileTesting
          isAnimateMode={isAnimateMode}
          uuid={uuid}
          isProgressBar={isProgressBar}
          progressBarData={progressBarData}
          dataProfs={dataProfessions}
          isLeftPlayed={isLeftPlayed}
          isRightPlayed={isRightPlayed}
          playbackControlHandle={playbackControlHandle}
          isAnimateChosenProf={isAnimateChosenProf}
          setIsAnimateChosenProf={setIsAnimateChosenProf}
          stopPlayback={stopPlayback}
        />
      )}

      <NestedModal type='clue' open={isShowStartModal}
                   setIsConfirmModal={setIsShowStartModal}>
        <Clue dataGifs={dataGifs} isLoadingGifs={isLoadingGifs} />
      </NestedModal>
    </>
  );
});

DemoTest.displayName = 'DemoTest';
