import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import {
  AuthModalsSX,
  AuthModalsSXMedia,
} from '@Components/modals/modalsContent/Unlock/AuthModals/stylesSX';
import { EnterPhone } from '@Components/modals/modalsContent/Unlock/EnterPhone';
import { EnterSms } from '@Components/modals/modalsContent/Unlock/EnterSms';
import { Payment } from '@Components/modals/modalsContent/Unlock/Payment';
import { PromoCode } from '@Components/modals/modalsContent/Unlock/Promo';
import { ChooseDirection } from '@Components/btns';
import { AuthModalsProps } from '@Components/modals/modalsContent/Unlock/AuthModals/AuthModals.type';
import { ScreenDesktopDimensions } from '@Constants/';
import { TextInputSXMedia } from '@Components/inputs/TextInput/stylesSX';

export const AuthModals = memo((props: AuthModalsProps) => {
  const {
    title,
    buttonName,
    activeTypeModal,
    forcedType,
    disableBtn,
    onBtnClick,
    errMessage,
    resetError,
    phoneNumber,
    setPhoneNumber,
    smsValue,
    setSmsValue,
    onTransitionHandle,
    promo,
    promoTextValue,
    setPromo,
  } = props;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  return (
    <Box sx={[AuthModalsSX.container, AuthModalsSXMedia.container(isDesktop)]}>
      <Box sx={[AuthModalsSX.title, AuthModalsSXMedia.title(isDesktop)]}>{title}</Box>
      {errMessage && <Box sx={TextInputSXMedia.messageBox(isDesktop)}>{errMessage}</Box>}

      {(activeTypeModal === 'phone' || activeTypeModal === 'auth') && (
        <EnterPhone
          errMessage={errMessage}
          resetError={resetError}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isDesktop={isDesktop}
          activeTypeModal={activeTypeModal}
        />
      )}

      {activeTypeModal === 'sms' && (
        <EnterSms
          phone={phoneNumber}
          smsValue={smsValue}
          setSmsValue={setSmsValue}
          onTransition={onTransitionHandle}
          forcedType={forcedType}
          errMessage={errMessage}
          resetError={resetError}
          isDesktop={isDesktop}
        />
      )}

      {activeTypeModal === 'payment' && (
        <Payment
          promo={promoTextValue ? promoTextValue : promo}
          onTransition={onTransitionHandle}
        />
      )}

      {activeTypeModal === 'promo' && (
        <PromoCode
          isPromoTypeProps={forcedType === 'promo'}
          promo={promo}
          setPromo={setPromo}
          onBack={onTransitionHandle}
          errMessage={errMessage}
          resetError={resetError}
          isDesktop={isDesktop}
        />
      )}

      <Box sx={[AuthModalsSX.btnBox, AuthModalsSXMedia.btnBox(isDesktop, activeTypeModal)]}>
        <ChooseDirection
          onBtnClick={onBtnClick}
          type={'desktop'}
          disabled={disableBtn}
          sxData={AuthModalsSXMedia.btnSxData(isDesktop)}>
          {buttonName}
        </ChooseDirection>
      </Box>

      {activeTypeModal === 'auth' && (
        <Box sx={AuthModalsSXMedia.message(isDesktop)}>
          Если у вас нет учетной записи, то вам необходимо сначала пройти первую часть
          профориентации.
          <br /> После этого мы поможем вам создать учетку и получить доступ ко всему контенту
          сервиса
        </Box>
      )}
    </Box>
  );
});

AuthModals.displayName = 'AuthModals';
