import { keyframes } from '@mui/system';
import { widthOneProgressBar } from '@Components/pogressBar/stylesSX';

export const ProgressBarDemoSX = {
  container: {
    display: 'flex',
    width: '65vw',
    maxWidth: '1440px',
    margin: '0 8px',
  },
  block: {
    minWidth: `${widthOneProgressBar}px`,
    height: '39px',
    backgroundColor: 'neutral.white',
    border: '3px solid',
    borderColor: 'neutral.dark',
    borderRadius: '20px',
    boxShadow: '4px 4px 0px 0px #000000',
  },
  progressBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#AFAFAF',

    height: '100%',
    borderRadius: '17px',
  },
  numBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '35px',
    height: '35px',
    borderRadius: '20px',
    backgroundColor: '#D9D9D9',
    color: 'neutral.white',
    margin: '0 2px',
  },
  iconsBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  iconBox: {
    width: '35px',
    height: '35px',
    marginRight: '8px',
  },
  iconFlag: {
    position: 'absolute',
    left: `${widthOneProgressBar - 40}px`,
  },
  iconFlag2: {
    position: 'absolute',
    top: '7px',
    right: '18px',
  },
  blockTwo: {
    borderRadius: '20px 0 0 20px',
    boxShadow: '4px 4px 0px 0px #000000, 4px 3px 0px 0px #000000',
    width: '100%',
    marginLeft: '16px',
    minWidth: '0',
  },
  archTop: {
    height: '39px',
    width: '50px',
    background:
      'linear-gradient(90deg,#FFF7BE 16px,#FCE557 16px,#FCE557 33px,#FDD105 30px,#FDD105 50px)',
    position: 'absolute',
    right: '2px',
    bottom: '1px',
    zIndex: 1,
    borderRight: '1px solid',
    borderBottom: '2px solid',
  },
  lockBox: {
    display: 'flex',
    height: '36px',
    alignItems: 'center',
    width: '40px',

    lock: {
      marginLeft: '16px',
    },
  },
};

const disappear = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(.1);
  }
`;

const show = keyframes`
  0% {
    transform: scale(.1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

export interface ITwoPointMovement {
  transformAt: string;
  transformTo: string;
  opacityStart?: number;
  opacityEnd?: number;
}

export const twoPointMovement = ({
  transformAt,
  transformTo,
  opacityStart,
  opacityEnd,
}: ITwoPointMovement) => keyframes`
  0% {
    transform: translateX(${transformAt});
    opacity: ${opacityStart ? opacityStart : 1};
  }
  100% {
    transform: translateX(${transformTo});
    opacity: ${opacityEnd ? opacityEnd : 1};
  }
`;

export const ProgressAnimationSX = {
  cardBox: {
    position: 'absolute',
  },
  newCardAnimation: {
    position: 'absolute',
  },

  blockExit: {
    animation: `${disappear} ease-in-out 1s`,
  },
  blockShow: {
    animation: `${show} ease-in-out 1s`,
  },
  blockMoveToRight: {
    animation: `${twoPointMovement({
      transformAt: '0%',
      transformTo: '100%',
    })} ease-in-out 1s`,
  },
  blockMoveToLeft: {
    animation: `${twoPointMovement({
      transformAt: '0%',
      transformTo: '100%',
    })} ease-in-out 1s`,
  },
};
