import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const ProfNameTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: '1px solid black',
    boxShadow: '2px 0px 0 0 black',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    border: '2px solid black',
    boxShadow: '2px 2px 0 0 black',
  },
}));
