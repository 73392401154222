import React, { memo } from 'react';
import { Box } from '@mui/material';
import { cardCornerWidth, ResDataBoxSX, resDataContainerWidth } from './stylesSX';
import { ResDataBoxProps } from './ResDataBox.type';
import cardCorner from '@Assets/images/card_corner.svg';

export const ResDataBox = memo((props: ResDataBoxProps) => {
  const { iconReport, title, index, displayWidth } = props;

  const titleIdxOneTranslate =
    displayWidth < 1460 ? 'translate(-30px, -27px)' : 'translate(-43px, -32px)';

  return (
    <Box
      sx={[
        ResDataBoxSX.container,
        {
          width: resDataContainerWidth(displayWidth),
        },
      ]}>
      <img src={iconReport} alt='prof icon' width={cardCornerWidth(displayWidth)} />
      <Box
        sx={[
          ResDataBoxSX.titleTablet,
          index === 1 && {
            transform: `${titleIdxOneTranslate} rotate(20deg)`,
          },
        ]}>
        {title}
        <Box sx={ResDataBoxSX.cardCornerBox}>
          <img src={cardCorner} alt='card corner' />
        </Box>
      </Box>
    </Box>
  );
});

ResDataBox.displayName = 'ResDataBox';
