import { ActiveModalsTypes } from '@Components/modals/modalsContent/Unlock/AuthModalsContainer.type';

export const isDisabledBtn = (
  activeTypeModal: ActiveModalsTypes,
  phoneNumber: string,
  smsValue: string,
  errMessage: boolean,
): boolean | undefined => {
  switch (activeTypeModal) {
    case 'phone':
    case 'auth':
      return phoneNumber.length < 15 || errMessage;
    case 'sms':
      return smsValue.length < 4;
    default:
      return;
  }
};
