import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { MobileNextBtnSXMedia } from './stylesSX';
import { IMobileNextBtn } from './MobileNextBtn.types';
import mobileNextBtn from '@Assets/images/mobile_next_btn.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ScreenDesktopDimensions } from '@Constants/';

export const MobileNextBtn = memo((props: IMobileNextBtn) => {
  const { onClick, disabled } = props;

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  return (
    <Box
      sx={MobileNextBtnSXMedia.follow_btn(displayWidth, displayHeight, isDesktop)}
      onClick={disabled ? onClick : undefined}>
      <img src={mobileNextBtn} alt='mobileNextBtn' />
    </Box>
  );
});

MobileNextBtn.displayName = 'MobileNextBtn';
