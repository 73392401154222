import React, { memo } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { NavigateProfBtnSX, NavigateProfBtnSXMedia } from './stylesSX';
import { NavigateProfBtnProps } from './NavigateProfBtn.type';
import { ProfInfoMobile } from '@Components/tooltips/ProfInfoMobile/ProfInfoMobile';
import { MobileProfSelectAnimationContainer } from '@Components/players/DesktopPlayer/ProfSelectAnimationContainer/mobile/MobileProfSelectAnimationContainer';
import { MetrikValues, sendMetrik } from '../../../../../utils/yaMetrica/metriks';

export const NavigateProfBtn = memo((props: NavigateProfBtnProps) => {
  const {
    title,
    onBtnClick,
    disabled,
    color,
    description,
    isAnimateChosenProf,
    animateChosenProf,
    isLeft,
    isShowTooltip,
    setIsShowTooltip,
  } = props;

  const onInfoClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isLeft) {
      setIsShowTooltip({
        isLeft: true,
        isRight: false,
      });
    } else {
      setIsShowTooltip({
        isLeft: false,
        isRight: true,
      });
    }
    sendMetrik('reachGoal', MetrikValues.InfoIconClicked, { prof_name: title });
  };

  const handleClickAway = () => {
    if(isShowTooltip.isLeft || isShowTooltip.isRight){
      setIsShowTooltip({
        isLeft: false,
        isRight: false,
      });
    }
  };

  return (
    <MobileProfSelectAnimationContainer
      isAnimateChosenProf={isAnimateChosenProf}
      setIsAnimateChosenProf={animateChosenProf}
      isLeft={isLeft}>
      <Box
        onClick={disabled ? onBtnClick : undefined}
        sx={[NavigateProfBtnSX.container, NavigateProfBtnSXMedia.container(disabled, color)]}>
        <Box sx={NavigateProfBtnSX.title}>{title}</Box>

        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={NavigateProfBtnSXMedia.info()} onClick={disabled ? undefined : onInfoClick}>
            <ProfInfoMobile text={description} isOpen={isShowTooltip} isLeft={disabled} />
          </Box>
        </ClickAwayListener>
      </Box>
    </MobileProfSelectAnimationContainer>
  );
});

NavigateProfBtn.displayName = 'NavigateProfBtn';
