import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './Loader.module.css';

export const Loader = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  /*  const onShowClick = () => {
    setIsLoaderVisible(true);
  };*/
  useEffect(() => {
    setIsLoaderVisible(true);
    setTimeout(() => {
      setIsLoaderVisible(false);
    }, 3000);
  }, []);

  return (
    <div>
      {/* <button onClick={onShowClick}>{isLoaderVisible ? 'hide' : 'show'}</button>*/}
      <div className={styles.wrap}>
        {/*  <Transition
          in={isLoaderVisible}
          timeout={500}
          mountOnEnter
          unmountOnExit
          onEnter={()=>console.log('onEnter')}
          onEntering={()=>console.log('onEntering')}
          onEntered={()=>console.log('onEntered')}
          onExit={()=>console.log('onExit')}
          onExiting={()=>console.log('onExiting')}
          onExited={()=>console.log('onExited')}
        >
          {state =><div className={`${styles.circle} ${styles[state]}`} />}
        </Transition>*/}
        <CSSTransition
          in={isLoaderVisible}
          timeout={500}
          mountOnEnter
          unmountOnExit
          classNames={{
            enterActive: styles['circle-active'],
            enterDone: styles['circle-done'],
            exitActive: styles['circle-exit-active'],
            exitDone: styles['circle-exit-done'],
          }}>
          <div className={styles.circle} />
        </CSSTransition>
      </div>
    </div>
  );
};
