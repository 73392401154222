import styles
  from '@Components/animatePlayersDeck/PlayersDeck/PlayersDeckAnimate.module.css';

export const exitActiveClass = (displayHeight: number) => {
  switch (true) {
    case displayHeight < 500:
      return styles['player_block_exit_active_mobile_500'];
    case displayHeight < 600:
      return styles['player_block_exit_active_mobile_600'];
    case displayHeight < 700:
      return styles['player_block_exit_active_mobile_700'];
    case displayHeight < 800:
      return styles['player_block_exit_active_mobile_800'];
    default:
      return styles['player_block_exit_active'];
  }
};
