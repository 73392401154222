import { adaptiveDimensionsPlayer } from '@Components/animatePlayersDeck/lib/adaptiveDimensionsPlayer';
import { mobileMode } from '@Constants/';

export const TagsSX = {
  container: {
    fontWeight: 400,
    textAlign: 'center',
    textDecorationLine: 'underline',

    color: 'neutral.dark',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 155,
  },
  text: {
    paddingRight: '5px',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
};

export const TagsMobileSXMedia = {
  container: (displayWidth: number, displayHeight: number, isDesktop: boolean) => {
    const containerWidth = adaptiveDimensionsPlayer(displayWidth, displayHeight, isDesktop);
    return {
      width: isDesktop ? containerWidth.width : '100%',
      fontSize: displayWidth < 1000 || displayHeight < mobileMode.height ? '12px' : '16px',
      lineHeight: displayWidth < 1000 || displayHeight < mobileMode.height ? '14px' : '19px',
      margin: isDesktop && displayHeight < 500 ? '0 19px 0 0' : '19px',
      marginLeft: 0,
    };
  },
};
