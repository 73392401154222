import { IProgressProfs } from '@Components/pogressBar';
import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import {
  ProgressAnimationSX,
  twoPointMovement,
} from '@Components/pogressBar/ProgressbarDemo/stylesSX';
import { Card } from '@Components/pogressBar/Card/Card';

export type AnimationType = 'blockMoveToRight' | 'blockMoveToLeft' | 'newCard' | 'oldPosition';

export interface AnimateTypeData {
  type: AnimationType;
  factor: number;
}

export const animationCardsHTML = (
  data: IProgressProfs,
  animationData: AnimateTypeData,
  profBlockWidth: string,
): ReactElement => {
  const { type, factor } = animationData;

  const withInBlock = factor > 1 ? '-60px' : profBlockWidth;
  switch (type) {
    case 'blockMoveToRight':
      return (
        <Box
          key={data.id}
          sx={[
            {
              animation: `${twoPointMovement({
                transformAt: `calc(${factor} * ${withInBlock})`,
                transformTo: '0px',
              })} ease-in-out 1s`,
            },
          ]}>
          <Card card={data} />
        </Box>
      );
    case 'blockMoveToLeft':
      return (
        <Box
          key={data.id}
          sx={[
            {
              animation: `${twoPointMovement({
                transformAt: `calc(${factor} * ${profBlockWidth})`,
                transformTo: '0px',
              })} ease-in-out 1s`,
            },
          ]}>
          <Card card={data} />
        </Box>
      );
    case 'newCard':
      return (
        <Box
          key={data.id}
          sx={[
            {
              position: 'absolute',
              marginTop: '3px',
              animation: `${twoPointMovement({
                transformAt: `calc(${factor} * -${profBlockWidth})`,
                transformTo: '-59px',
                opacityStart: 0,
                opacityEnd: 1,
              })} ease-in-out 1s forwards`,
            },
          ]}>
          <Card card={data} />
        </Box>
      );
    case 'oldPosition':
      return (
        <Box key={data.id}>
          <Card card={data} />
        </Box>
      );

    default:
      return (
        <Box key={data.id} sx={ProgressAnimationSX.blockShow}>
          <Card card={data} />
        </Box>
      );
  }
};
