import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PlayersDeckSX } from '@Components/animatePlayersDeck/PlayersDeck/stylesSX';
import ReactPlayer from 'react-player';
import { PlayerContainerProps } from './PlayerContainer.type';
import { VideoDescription } from './VideoDescription';
import { PlayBlock } from './PlayBlock';
import { OnProgressProps } from 'react-player/base';
import { resetLocalData } from '@Components/players/DesktopPlayer/lib/resetLocalData';

export const PlayersContainer = memo((props: PlayerContainerProps) => {
  const {
    src,
    id,
    desc,
    title,
    isPlayed,
    playbackControl,
    index,
    transformValue,
    dimensionsPlayer,
    onEnded,
    videosLength,
    isAnimateMode,
    onPausePlayer,
    isLeft,
  } = props;

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      isLeft
        ? 'leftCurrentVideoId'
        : 'rightCurrentVideoId'
      , JSON.stringify(id));
    return resetLocalData();
  }, [id, isLeft, src]);

  const onReadyVideo = () => {
    setIsReady(true);
  };

  const onEndedHandle = () => {
    onEnded('auto');
  };

  const setProgress = (progress: OnProgressProps) => {
    if (progress.playedSeconds > 0) {
      localStorage.setItem(
        isLeft
          ? 'leftVideoProgress'
          : 'rightVideoProgress'
        , JSON.stringify(progress.playedSeconds));
    }
  };

  const setCurrentVideoTotalTime = (progress: number) => {
    localStorage.setItem(
      isLeft
        ? 'leftVideoTotalTime'
        : 'rightVideoTotalTime'
      , JSON.stringify(progress));
  };

  return (
    <Box
      onClick={isPlayed ? playbackControl : undefined}
      sx={[
        PlayersDeckSX.container.playerBox,
        {
          zIndex: 100 + videosLength - index,
          transform: transformValue(3, 10, index),
        },
      ]}>
      {!isPlayed && (
        <PlayBlock
          isAnimateMode={isAnimateMode}
          isReady={isReady}
          playbackControl={playbackControl}
        />
      )}

      <ReactPlayer
        url={src}
        controls={false}
        onEnded={onEndedHandle}
        width={dimensionsPlayer.width}
        height={dimensionsPlayer.height}
        playing={index === 0 && isPlayed}
        onReady={onReadyVideo}
        onPause={onPausePlayer}
        // возвращает общее время видео
        onDuration={progress => setCurrentVideoTotalTime(progress)}
        /*      onDuration={
                progress => {
                  setTotalVideosTime([
                    ...totalVideosTime,
                    {
                      id: id,
                      progress: progress,
                    },
                  ]);
                }}*/
        // onProgress={progress => onProgressCurrentVideo(progress,id)}

        // возвращает количество просмотренных секунд
        onProgress={progress => setProgress(progress)}
        /*     onProgress={progress => {
               const currentVideo = totalVideosTime.filter(video => video.id === id)[0];
               if (currentVideo) {
                 setViewedSeconds(progress.playedSeconds);
                 setCurrentVideoTotalTime(currentVideo.progress);
                 if (Math.trunc(progress.playedSeconds) === Math.trunc(currentVideo.progress / 2)) {
                   setVideoWatch();
                 }
               }
             }}*/
        playsinline={true}
      />
      <VideoDescription desc={desc} title={title} />
    </Box>
  );
});

PlayersContainer.displayName = 'PlayerContainer';
