import React, { memo, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { EnterSmsProps } from './Payment.type';
import { PriceLine } from './PriceLine';
import { useLazyUserPriceQuery } from '@Store/registration';
import { useNavigate } from 'react-router-dom';
import { useActions } from '@Hooks/';
import { ConfirmPromo } from '@Components/modals/modalsContent/Unlock/Payment/ConfirmPromo/ConfirmPromo';
import { ConfirmPromoSXMedia } from '@Components/modals/modalsContent/Unlock/Payment/ConfirmPromo/stylesSX';
import { ScreenDesktopDimensions } from '@Constants/';

export const Payment = memo((props: EnterSmsProps) => {
  const { onTransition, promo } = props;
  const navigate = useNavigate();
  const { setIsPaid } = useActions();

  const [getUserPrice, { data: userPriceData }] = useLazyUserPriceQuery();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  useEffect(() => {
    getUserPrice('');
  }, [getUserPrice]);

  useEffect(() => {
    if (userPriceData) {
      setIsPaid(userPriceData.paid);
      userPriceData.paid && navigate('/');
    }
  }, [navigate, setIsPaid, userPriceData]);

  const onPromoClick = () => {
    onTransition('promo');
  };

  const price = userPriceData ? userPriceData.price : 900;
  const totalPrice = userPriceData ? userPriceData.total_price : 900;
  const sale = userPriceData && userPriceData.sale;

  return (
    <Box sx={ConfirmPromoSXMedia.wrapper(!!sale, isDesktop)}>
      {userPriceData?.active_promo ? (
        <ConfirmPromo isDesktop={isDesktop} promo={promo} isSale={!!sale} />
      ) : (
        <Box sx={ConfirmPromoSXMedia.promo(isDesktop, !!sale)} onClick={onPromoClick}>
          У меня есть промокод
        </Box>
      )}

      {sale && (
        <>
          <PriceLine isDesktop={isDesktop} title={'Стоимость'} price={price} />
          <PriceLine isDesktop={isDesktop} title={'Скидка по промокоду'} price={sale ? sale : 0} />
        </>
      )}
      <Box sx={ConfirmPromoSXMedia.totalPriceBox(!!sale, isDesktop)}>
        <PriceLine isDesktop={isDesktop} title={'Итоговая стоимость'} price={totalPrice} />
      </Box>
    </Box>
  );
});

Payment.displayName = 'Payment';
