import { translateYAnimation } from '@Pages/Results/DesktopResults/Pedestal/lib/translateYAnimation';
import { endBoxPositions, PedestalSX } from '@Pages/Results/DesktopResults/Pedestal/stylesSX';

export const animationSX = (displayWidth: number, isStartAnimation: boolean) => {
  const coordinates = endBoxPositions(displayWidth);
  if (isStartAnimation) {
    const fall = translateYAnimation('fall', 'start', coordinates);
    const fall1 = translateYAnimation('fall1', 'start', coordinates);
    const fall2 = translateYAnimation('fall2', 'start', coordinates);
    const fall3 = translateYAnimation('fall3', 'start', coordinates);

    PedestalSX.container.emptyBoxBlock0.animation = `${fall} 1.5s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock1.animation = `${fall1} 1.5s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock2.animation = `${fall2} 1.5s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock3.animation = `${fall3} 1.5s ease-in forwards`;
  }
  if (!isStartAnimation) {
    const fallFocus = translateYAnimation('fall', 'focus', coordinates);
    const fall1Focus = translateYAnimation('fall1', 'focus', coordinates);
    const fall2Focus = translateYAnimation('fall2', 'focus', coordinates);
    const fall3Focus = translateYAnimation('fall3', 'focus', coordinates);

    PedestalSX.container.emptyBoxBlock0.animation = '';
    PedestalSX.container.emptyBoxBlock0.transform = `translateY(${coordinates.fall}%)`;
    PedestalSX.container.emptyBoxBlock0['&:hover'].animation = `${fallFocus} .2s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock1.animation = '';
    PedestalSX.container.emptyBoxBlock1.transform = `translateY(${coordinates.fall1}%)`;
    PedestalSX.container.emptyBoxBlock1['&:hover'].animation = `${fall1Focus} .2s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock2.animation = '';
    PedestalSX.container.emptyBoxBlock2.transform = `translateY(${coordinates.fall2}%)`;
    PedestalSX.container.emptyBoxBlock2['&:hover'].animation = `${fall2Focus} .2s ease-in forwards`;
    PedestalSX.container.emptyBoxBlock3.animation = '';
    PedestalSX.container.emptyBoxBlock3.transform = `translateY(${coordinates.fall3}%)`;
    PedestalSX.container.emptyBoxBlock3['&:hover'].animation = `${fall3Focus} .2s ease-in forwards`;
  }
};
