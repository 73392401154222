import React, { memo, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { TagsMobileSXMedia, TagsSX } from './stylesSX';
import { TagsProps } from './Tags.type';
import { NestedModal } from '@Components/modals';
import { InFullVersionMessage } from '@Components/modals/modalsContent/InFullVersionMessage/InFullVersionMessage';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { ITag } from '@Pages/Testing';
import { ScreenDesktopDimensions } from '@Constants/';

export const Tags = memo((props: TagsProps) => {
  const { tags } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState<ITag | undefined>();

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const onTagClick = (id: number) => {
    const tag = tags.find(tag => tag.id === id);
    setCurrentTag(tag);
    setIsOpen(prev => !prev);
  };

  return (
    <Box
      sx={[TagsSX.container, TagsMobileSXMedia.container(displayWidth, displayHeight, isDesktop)]}>
      {tags.map(({ text, id }) => {
        return (
          <Box key={id} onClick={() => onTagClick(id)} sx={TagsSX.text}>
            #{text}
          </Box>
        );
      })}
      {currentTag && (
        <NestedModal open={isOpen} setIsConfirmModal={setIsOpen}>
          <InFullVersionMessage tag={currentTag} setIsOpen={setIsOpen} />
        </NestedModal>
      )}
    </Box>
  );
});

Tags.displayName = 'TagsMobile';
