import { adaptiveDimensionsPlayer } from '@Components/animatePlayersDeck/lib/adaptiveDimensionsPlayer';

export const MobileNextBtnSXMedia = {
  follow_btn: (displayWidth: number, displayHeight: number, isDesktop: boolean) => {
    const playerWidth = adaptiveDimensionsPlayer(displayWidth, displayHeight, isDesktop).width;

    return {
      cursor: 'pointer',
      transform: `translateX(calc(${playerWidth} / 2 - 35px))`,
    };
  },
};
