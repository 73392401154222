import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material';

import { App } from './App';
import { store } from './store';

import './i18n';
import { Loader } from '@Components/loader/Loader/Loader';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface SimplePaletteColorOptions {
    white?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0c237c',
    },
    secondary: {
      main: '#d21532',
    },
    neutral: {
      main: '#FF9400',
      light: '#000AFF',
      dark: '#000000',
      white: '#FFFFFF',
    },
  },
  status: {
    danger: orange[500],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.PUBLIC_URL + '/'}>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
