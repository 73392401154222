import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProgressBarProps } from '../ProgressBar.types';
import { ProgressBarSX } from './stylesSX';
import { CSSTransition } from 'react-transition-group';

export const ProgressBar = memo((props: ProgressBarProps) => {
  const { newCard, progressValue, animationBlock } = props;

  return (
    <Box sx={ProgressBarSX.container}>
      <CSSTransition in={!!newCard} timeout={1500} mountOnEnter unmountOnExit>
        {newCard}
      </CSSTransition>

      <Box sx={[ProgressBarSX.block, ProgressBarSX.blockTwo]}>
        <Box sx={[ProgressBarSX.progressBox, { width: `${progressValue ? progressValue : 0}%` }]}>
          <Box sx={ProgressBarSX.numBlock}>1</Box>
          <Box sx={ProgressBarSX.blockNumTwo}>
            <Box sx={[ProgressBarSX.numBlock, { color: '#8B8B8B' }]}>2</Box>
          </Box>

          <Box sx={ProgressBarSX.iconsBlock}>
            <Box sx={ProgressBarSX.iconsBlock}>{animationBlock}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

ProgressBar.displayName = 'ProgressBar';
