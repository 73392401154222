import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ConfirmPromoSXMedia } from './stylesSX';
import checkGreen from '@Assets/images/icons/check_green.svg';
import { ConfirmPromoProps } from './ConfirmPromo.type';

export const ConfirmPromo = memo(({ promo, isDesktop, isSale }: ConfirmPromoProps) => (
  <Box sx={ConfirmPromoSXMedia.container(isDesktop)}>
    <Box sx={ConfirmPromoSXMedia.title(isDesktop)}>Промокод</Box>
    <Box sx={ConfirmPromoSXMedia.promo(isDesktop, isSale)}>
      {promo} <img src={checkGreen} alt='check' />
    </Box>
  </Box>
));

ConfirmPromo.displayName = 'ConfirmPromo';
