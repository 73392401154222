export const ResDescriptionsSX = {
  titleBox: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '7px',
  },
};

export const ResDescriptionsSXMedia = {
  title: (isDesktop: boolean) => ({
    textAlign: 'center',
    fontWeight: 700,
    fontSize: isDesktop ? '40px' : '20px',
    lineHeight: isDesktop ? '44px' : '24px',
    color: 'neutral.dark',

    maxWidth: '1101px',
  }),

  inFullDescriptionsBox: (displayWidth: number, displayHeight: number, isDesktop: boolean) => {
    const marginData = () => {
      if (isDesktop) {
        const top = () => {
          if (isDesktop && displayHeight < 800) {
            return '1vh';
          }
          return displayHeight < 1080 ? '5vh' : '10vh';
        };
        const bottom = displayHeight < 920 || displayWidth < 1140 ? '1vh' : '5vh';

        return `${top()} 0 ${bottom} 9vw`;
      } else {
        return '11px 13px 90px';
      }
    };

    return isDesktop
      ? { margin: marginData() }
      : {
          margin: marginData(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        };
  },

  info: (displayWidth: number, displayHeight: number, isDesktop: boolean) => ({
    fontWeight: 400,
    fontSize: isDesktop && displayHeight > 700 ? '16px' : '11px',
    lineHeight: isDesktop && displayHeight > 700 ? '21px' : '13px',
    color: 'neutral.dark',
    textAlign: isDesktop ? 'left' : 'center',
    maxWidth: displayWidth < 1140 ? '429px' : '636px',
    marginBottom: displayWidth < 1140 || displayHeight < 815 ? '2vh' : '5vh',
  }),

  inFullVersionBox: (displayWidth: number, isDesktop: boolean, displayHeight: number) => {
    const fontSize = () => {
      if (isDesktop) {
        return displayWidth < 1140 ? '14px' : '16px';
      } else {
        return '11px';
      }
    };
    const lineHeight = () => {
      if (
        (isDesktop && displayHeight < 740) ||
        (displayWidth < 1140 && isDesktop && displayHeight < 985)
      ) {
        return '16px';
      }
      return isDesktop ? '28px' : '13px';
    };
    const paddingBottom = () => {
      switch (true) {
        case displayWidth === 1280 && displayHeight === 746:
          return '4px';
        default:
          return isDesktop ? '29px' : '0';
      }
    };
    return {
      paddingBottom: paddingBottom(),
      fontWeight: 700,
      lineHeight: lineHeight(),
      maxWidth: displayWidth < 1140 ? '429px' : '636px',
      fontSize: fontSize(),
    };
  },

  inFullVersion: (isDesktop: boolean, displayHeight: number) => {
    const marginBottom = () => {
      if (isDesktop && displayHeight < 770) {
        return '14px';
      }
      return isDesktop ? '29px' : '11px';
    };
    return {
      marginBottom: marginBottom(),
    };
  },
  other: (isDesktop: boolean, displayHeight: number) => {
    const paddingTop = () => {
      if (displayHeight < 755) {
        return '10px';
      }
      return isDesktop ? '21px' : '2px';
    };
    return {
      fontWeight: 500,
      fontSize: isDesktop ? '16px' : '11px',
      lineHeight: isDesktop ? '19px' : '13px',
      paddingTop: paddingTop(),
    };
  },
};
