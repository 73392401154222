import { keyframes } from '@mui/system';

const disappearanceFrame = keyframes`
  25% {
    opacity: .75;
  }
  100% {
    display: none;
    opacity: 0;
  }
`;

const show = keyframes`
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const disappearance = (isAnimateMode: boolean | undefined) => {
  return { animation: isAnimateMode ? `${disappearanceFrame} .5s ease-in forwards` : '' };
};

export const manifestation = (isAnimateMode: boolean | undefined) => {
  return { animation: isAnimateMode ? `${show} 1s ease-in forwards` : '' };
};
