export const ConfirmPromoSXMedia = {
  wrapper: (sale: boolean, isDesktop: boolean) => {
    const height = () => {
      if (isDesktop) {
        return sale ? '349px' : '265px';
      }
      return sale ? '146px' : '121px';
    };

    return {
      height: height(),
    };
  },
  container: (isDesktop: boolean) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    margin: isDesktop ? '27px 0 0' : '13px 0',
  }),
  title: (isDesktop: boolean) => ({
    fontWeight: 700,
    fontSize: isDesktop ? '20px' : '11px',
    lineHeight: isDesktop ? '24px' : '13px',
    color: '#8F8F8F',
    mr: '8px',
  }),
  promo: (isDesktop: boolean, isSale: boolean) => {
    const marginTop = () => {
      if (isSale) {
        return isDesktop ? '40px' : '0';
      }
      return isDesktop ? '40px' : '13px';
    };
    return {
      fontWeight: 700,
      fontSize: isDesktop ? '20px' : '11px',
      lineHeight: isDesktop ? '24px' : '13px',
      display: 'flex',
      justifyContent: 'center',
      textDecorationLine: 'underline',
      cursor: 'pointer',
      marginTop: marginTop(),
    };
  },
  totalPriceBox: (sale: boolean, isDesktop: boolean) => {
    const marginTop = () => {
      if (isDesktop) {
        return sale ? '0' : '66px';
      }
      return sale ? '0' : '15px';
    };

    return {
      marginTop: marginTop(),
    };
  },
};
