import { ActiveModalsTypes } from '@Components/modals/modalsContent/Unlock/AuthModalsContainer.type';
import { useTranslation } from 'react-i18next';

export const useBtnName = (activeTypeModal: ActiveModalsTypes): string => {
  const { t } = useTranslation()

  switch (activeTypeModal) {
    case 'phone':
    case 'auth':
    case 'sms':
      return t('Далее');
    case 'payment':
      return t('Оплатить');
    case 'promo':
      return t('Применить');
    default:
      return '';
  }
};
