import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ClueSXMedia } from './stylesSX';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { ScreenDesktopDimensions } from '@Constants/';
import { IInfoGifs } from '@Pages/Testing';
import { isDesc } from '@Components/modals/modalsContent/Сlue/lib/isDesc';
import { useTranslation } from 'react-i18next';
import { Slide } from '@Components/modals/modalsContent/Сlue/Slide/Slide';

export interface ClueProps {
  dataGifs?: IInfoGifs[];
  isLoadingGifs: boolean;
}

type gifNum = 1 | 2 | 3 | 4 | 5 | 6;

export const Clue = memo((props: ClueProps) => {
  const { dataGifs, isLoadingGifs } = props;
  const { t } = useTranslation();
  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const slideGifs = (type: gifNum) =>
    dataGifs?.filter(({ name }) => {
      return name === `gif_${type}_desc_1` || name === `gif_${type}_desc_2`;
    });
  const isDescMode = () => {
    return isDesc(t('gif_1_desc_1'), t('gif_1_desc_2'))
      || isDesc(t('gif_2_desc_1'), t('gif_2_desc_2'))
      || isDesc(t('gif_3_desc_1'), t('gif_3_desc_2'))
      || isDesc(t('gif_4_desc_1'), t('gif_4_desc_2'))
      || isDesc(t('gif_5_desc_1'), t('gif_5_desc_2'))
      || isDesc(t('gif_6_desc_1'), t('gif_6_desc_2'));
  };
  return (
    <Box sx={[
      ClueSXMedia.container(isDesktop),
      !isDescMode() && ClueSXMedia.desc(isDesktop, displayHeight, displayWidth),
    ]}>
      <Swiper
        spaceBetween={1}
        slidesPerView={1}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        rewind
        navigation={isDesktop}
        pagination={{ clickable: true }}>
        {slideGifs(1)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(1)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_1_desc_1')}
            subDesc={t('gif_1_desc_2')}
          />
        </SwiperSlide>}
        {slideGifs(2)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(2)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_2_desc_1')}
            subDesc={t('gif_2_desc_2')}
          />
        </SwiperSlide>
        }
        {slideGifs(3)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(3)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_3_desc_1')}
            subDesc={t('gif_3_desc_2')}
          />
        </SwiperSlide>
        }
        {slideGifs(4)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(4)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_4_desc_1')}
            subDesc={t('gif_4_desc_2')}
          />
        </SwiperSlide>
        }
        {slideGifs(5)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(5)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_5_desc_1')}
            subDesc={t('gif_5_desc_2')}
          />
        </SwiperSlide>
        }
        {slideGifs(6)?.length === 2 &&
        <SwiperSlide>
          <Slide
            displayWidth={displayWidth}
            displayHeight={displayHeight}
            isDesktop={isDesktop}
            gifs={slideGifs(6)}
            isLoadingGifs={isLoadingGifs}
            desc={t('gif_6_desc_1')}
            subDesc={t('gif_6_desc_2')}
          />
        </SwiperSlide>
        }
      </Swiper>
    </Box>
  );
});

Clue.displayName = 'Clue';
