import * as React from 'react';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { ICustomTooltip } from './ProfInfoMobile.types';
import question from '@Assets/images/icons/question.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const ProfInfoMobile = memo((props: ICustomTooltip) => {
  const { text, isOpen, isLeft } = props;
  const { width } = useWindowDimensions();
  const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: `${width - 24}px`,
      background: '#FFFFFF',
      border: '2px solid #000000',
      borderRadius: '10px',
      fontWeight: 400,
      fontSize: '14px',
      color: '#000000',
      padding: '12px',
      margin: '8px',
    },
  });

  const placement = isLeft ? 'top-end' : 'top-start';

  return (
    <TooltipComponent
      open={isLeft ? isOpen.isLeft : isOpen.isRight}
      title={text}
      placement={placement}>
      <img src={question} alt='question' />
    </TooltipComponent>
  );
});

ProfInfoMobile.displayName = 'ProfInfoMobile';
