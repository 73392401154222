export const MobileResultsSX = {
  container: {},

  buttonsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    bottom: '10px',
  },
};
