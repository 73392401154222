import React, { memo } from 'react';
import { DesktopTestingSX, DesktopTestingSXMedia } from './stylesSX';
import { Box } from '@mui/material';
import { HeaderContainer } from '@Components/headers';
import { manifestation } from '../../../utils/helpers/animate/animates';
import styles from './DesktopTesting.module.css';
import { CSSTransition } from 'react-transition-group';
import { ProgressBarContainer } from '@Components/pogressBar/ProgressBarContainer';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ProfessionContainer } from '@Components/players/DesktopPlayer/ProfessionContainer';
import { TitleQuestion } from '@Pages/Testing/Desktop/TitleQuestion/TitleQuestion';
import { TestingProps } from '@Pages/Testing';

export const DesktopTesting = memo((props: TestingProps) => {
  const {
    isAnimateMode,
    uuid,
    isProgressBar,
    progressBarData,
    dataProfs,
    isLeftPlayed,
    isRightPlayed,
    playbackControlHandle,
    isAnimateChosenProf,
    setIsAnimateChosenProf,
    stopPlayback,
  } = props;

  const { height: displayHeight } = useWindowDimensions();

  const colors = () => {
    return dataProfs?.map(({ background_color }) => background_color);
  };

  const backgroundColors = colors();

  return (
    <HeaderContainer backgroundColors={backgroundColors} isAnimateMode={isAnimateMode} uuid={uuid}>
      <Box sx={DesktopTestingSX.container}>
        <CSSTransition
          in={isProgressBar}
          timeout={950}
          mountOnEnter
          unmountOnExit
          classNames={{
            enterActive: styles['title_active'],
            exitActive: styles['title_exit'],
          }}>
          <Box
            sx={[
              manifestation(isProgressBar),
              DesktopTestingSXMedia.progressBarBox(displayHeight),
            ]}>
            <ProgressBarContainer progressBarData={progressBarData} />
          </Box>
        </CSSTransition>

        <CSSTransition
          in={!isProgressBar}
          timeout={950}
          mountOnEnter
          unmountOnExit
          classNames={{
            enterActive: styles['title_active'],
            exitActive: styles['title_exit'],
          }}>
          <TitleQuestion />
        </CSSTransition>

        <Box sx={DesktopTestingSXMedia.players_block(displayHeight)}>
          {dataProfs &&
            dataProfs.length > 1 &&
            dataProfs.map((video, idx) => {
              const isLeft = idx % 2 === 0;
              return (
                <ProfessionContainer
                  key={video.id}
                  isLeft={isLeft}
                  videoData={video}
                  isLeftPlayed={isLeftPlayed}
                  isRightPlayed={isRightPlayed}
                  onPlaybackControlHandle={playbackControlHandle}
                  isAnimateChosenProf={isAnimateChosenProf}
                  animateChosenProf={setIsAnimateChosenProf}
                  isAnimateMode={isAnimateMode}
                  uuid={uuid}
                  progressBarData={progressBarData}
                  stopPlayback={stopPlayback}
                />
              );
            })}
        </Box>
      </Box>
    </HeaderContainer>
  );
});

DesktopTesting.displayName = 'DesktopTesting';
