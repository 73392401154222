import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export const NotFoundPage = memo(() => {
  const { t } = useTranslation();
  return <Box>{t('pages.not_found')}</Box>;
});

NotFoundPage.displayName = 'NotFoundPage';
