import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProfessionSX } from './StylesSX';
import { IDesktopPlayerProps } from './DesktopPlayer.types';
import { VideoBlock } from './PlayerBlock';
import { ButtonsBlock } from '@Components/players/DesktopPlayer/ButtonsBlock/ButtonsBlock';

export const Profession = memo((props: IDesktopPlayerProps) => {
  const {
    videoData,
    isLeft,
    isIconInfo,
    isAnimateChosenProf,
    animateChosenProf,
    isAnimateMode,
    videos,
    onNextClick,
    isPlayed,
    onPlaybackControl,
    onProfHandle,
    videoWatchedCounter,
    isNextBtnActive,
    currentVideoIdx,
    testingType,
    onPausePlayer,
  } = props;

  const { title, desc, icon_image, icon_color } = videoData;

  const tags = () => {
    if (videos.length > 0) {
      const idx = currentVideoIdx === videos.length ? 0 : currentVideoIdx;
      return videos[idx].tags;
    }
  };

  return (
    <Box sx={[ProfessionSX.container, testingType === 'desktop' && { width: '100%' }]}>
      <VideoBlock
        isAnimateChosenProf={isAnimateChosenProf}
        animateChosenProf={animateChosenProf}
        isLeft={isLeft}
        isIconInfo={isIconInfo}
        title={title}
        icon_image={icon_image}
        videos={videos}
        onNextClick={onNextClick}
        isPlayed={isPlayed}
        onPlaybackControl={onPlaybackControl}
        desc={desc}
        icon_color={icon_color}
        testingType={testingType}
        isAnimateMode={isAnimateMode}
        onPausePlayer={onPausePlayer}
      />

      <ButtonsBlock
        onProfHandle={onProfHandle}
        videoWatchedCounter={videoWatchedCounter}
        isAnimateMode={isAnimateMode}
        icon_color={icon_color}
        isNextBtnActive={isNextBtnActive}
        onNextClick={onNextClick}
        videosList={videos}
        testingType={testingType}
        tags={tags()}
      />
    </Box>
  );
});

Profession.displayName = 'DesktopPlayer';
