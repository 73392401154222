import { ModalTypes } from '@Components/modals/NestedModal/NestedModal.type';

export const NestedModalSX = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'neutral.white',

    pt: 2,
    px: 4,
  },
  closeIconBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    zIndex: 10000,
  },
};

export const NestedModalSXMedia = {
  closeIconMargin: (isDesktop: boolean) => ({
    margin: isDesktop ? '30px 38px' : '5px 15px;',
  }),
  modal: (isDesktop: boolean, type?: ModalTypes) => {
    const modalWidth = () => {
      if (isDesktop) {
        return type === 'clue' ? '80vw' : '784px';
      } else {
        return type === 'clue' ? '269px' : '238px';
      }
    };

    return isDesktop
      ? {
          width: type === 'clue' ? '100%' : '46%',
          maxWidth: modalWidth(),
          minWidth: '656px',
          border: '5px solid #000000',
          borderRadius: '27px',
          boxShadow: ' 7px 7px 0px 0px #000000',
          pb: type === 'clue' ? '43px' : 3,
        }
      : {
          width: modalWidth(),
          // height: '196px',
          border: '2px solid #000000',
          borderRadius: '12px',
          boxShadow: ' 3px 4px 0px 0px #000000',
          pt: 0,
          px: 0,
          pb: 0,
        };
  },
};
