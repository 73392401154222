import React, { memo, useState } from 'react';
import { Box } from '@mui/material';
import logo from '@Assets/images/logo.svg';
import { HeaderMobileSX } from './stylesSX';
import { headerHeight } from '@Components/headers/lib/headerHeight';
import { IHeaderDesktop } from '../Header.type';
import { MetrikValues, sendMetrik } from '../../../utils/yaMetrica/metriks';

export const HeaderMobile = memo((props: IHeaderDesktop) => {
  const { uuid, displayHeight } = props;
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isSignInModal, setIsSignInModal] = useState(false);

  const onMenuClick = () => {
    setIsShowSidebar(prev => !prev);
  };

  return (
    <Box sx={HeaderMobileSX.block}>
      <Box sx={[HeaderMobileSX.contentBox, { height: headerHeight(displayHeight) }]}>
        <img
          src={logo}
          alt='logo'
          style={HeaderMobileSX.img}
          onClick={() => sendMetrik('reachGoal', MetrikValues.LoginClicked)}
        />
{/*        <Box onClick={onMenuClick} sx={HeaderMobileSX.menu}>
          <img src={isShowSidebar ? closeL : menu} alt='menu' />
        </Box>
        <Sidebar
          isShowSidebar={isShowSidebar}
          setIsShowSidebar={setIsShowSidebar}
          setIsSignInModal={setIsSignInModal}
        />

        <NestedModal open={isSignInModal} setIsConfirmModal={setIsSignInModal}>
          <AuthModalsContainer uuid={uuid} forcedType={'auth'} />
        </NestedModal>*/}
      </Box>
    </Box>
  );
});

HeaderMobile.displayName = 'HeaderMobile';
