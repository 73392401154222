import React, { memo } from 'react';
import styles from './PlayersDeckAnimate.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box } from '@mui/material';
import { PlayersDeckSX } from './stylesSX';
import { PlayersDeckProps } from './PlayersDeck.type';
import { PlayersContainer } from '@Components/animatePlayersDeck/PlayersDeck/PlayerContainer/PlayersContainer';
import { exitActiveClass } from '@Components/animatePlayersDeck/PlayersDeck/lib/exitActiveClass';

export const PlayersDeck = memo((props: PlayersDeckProps) => {
  const {
    videos,
    onEnded,
    isPlayed,
    playbackControl,
    transformValue,
    dimensionsPlayer,
    isAnimateMode,
    onPausePlayer,
    isLeft,
    displayHeight,
  } = props;

  const videosLength = videos.length;

  return (
    <Box sx={PlayersDeckSX.container}>
      <TransitionGroup className={styles.transition_group}>
        {videos.map(({ src, id, desc, title }, index) => {
          return (
            <CSSTransition
              key={id}
              timeout={950}
              mountOnEnter
              unmountOnExit
              classNames={{
                enterActive: styles['player_block_enter_active'],
                exitActive: exitActiveClass(displayHeight),
              }}>
              <PlayersContainer
                src={src}
                id={id}
                desc={desc}
                title={title}
                index={index}
                onEnded={onEnded}
                isPlayed={isPlayed}
                playbackControl={playbackControl}
                transformValue={transformValue}
                dimensionsPlayer={dimensionsPlayer}
                videosLength={videosLength}
                isAnimateMode={isAnimateMode}
                onPausePlayer={onPausePlayer}
                isLeft={isLeft}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </Box>
  );
});

PlayersDeck.displayName = 'PlayersDeck';
