import React, { memo } from 'react';
import { Box } from '@mui/material';
import { PriceLineSXMedia } from './stylesSX';
import { PriceLineProps } from './PriceLine.type';

export const PriceLine = memo((props: PriceLineProps) => {
  const { title, price, isDesktop } = props;

  return (
    <Box sx={PriceLineSXMedia.line(isDesktop)}>
      <Box sx={PriceLineSXMedia.title(isDesktop)}>{title}</Box>
      <Box sx={PriceLineSXMedia.price(isDesktop)}>{price}₽</Box>
    </Box>
  );
});

PriceLine.displayName = 'PriceLine';
