import lightningImg from '@Assets/images/lightning.svg';

export const mainBackground = (isDesktop: boolean, backgroundColors: string[] | undefined) => {
  const leftColor = backgroundColors ? backgroundColors[1] : '#F8BBED';
  const rightColor = backgroundColors ? backgroundColors[0] : '#998CEC';
  if (isDesktop)
    return {
      background: `url(${lightningImg}) no-repeat, linear-gradient(-75deg, ${leftColor} 51%, ${rightColor} 50%)`,
    };
  if (!isDesktop && backgroundColors) {
    return {
      background: backgroundColors[0],
    };
  }
  return {};
};
