export const PhoneNumberInputSX = {
  container: {
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    zIndex: 1,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
};

export const PhoneNumberInputSXMedia = {
  mask: (isDesktop: boolean) => {
    return {
      top: 0,
      left: isDesktop ? '21px' : '10px',
      fontSize: isDesktop ? '17px' : '12px',
      lineHeight: isDesktop ? '19px' : '14px',
    };
  },
};
