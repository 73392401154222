import React, { memo } from 'react';
import { Box } from '@mui/material';
import cloud from '@Assets/images/cloud.svg';
import { ICloud } from './Cloud.type';

export const Cloud = memo((props: ICloud) => {
  const { top, right, bottom, left, size } = props;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: `${top}`,
        right: `${right}`,
        bottom: `${bottom}`,
        left: `${left}`,
      }}>
      <img src={cloud} alt='cloud' width={size} />
    </Box>
  );
});

Cloud.displayName = 'Cloud';
