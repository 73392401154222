import * as React from 'react';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import question from '@Assets/images/icons/question.svg';
import { Box } from '@mui/material';
import { TooltipSX } from '@Components/tooltips/profInfo/stylesSX';
import { IProfInfo } from './ProfInfo.types';

export const ProfInfo: FC<IProfInfo> = ({ text, isOpen }) => {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: '218px',
      background: '#FFFFFF',
      border: '2px solid #000000',
      borderRadius: '10px',
      fontWeight: 400,
      fontSize: '16px',
      color: '#000000',
      padding: '12px',
    },
  });
  return (
    <CustomTooltip open={isOpen} title={text} placement={'bottom-end'}>
      <Box sx={TooltipSX.imgBox}>
        <img src={question} alt='info' style={TooltipSX.infoIcon} />
      </Box>
    </CustomTooltip>
  );
};
