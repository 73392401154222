export const ProfIconSX = {
  profIconBox: {
    width: '100%',
    height: '100%',
    border: '2px solid #000000',
    borderRadius: '10px',
    transform: 'translateX(2px)',
    backgroundColor: '',
    zIndex: 1,
  },
};
