import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ButtonsBlockSX, ButtonsBoxSXMedia } from './stylesSX';
import { ButtonsBlockProps } from './ButtonsBlock.type';
import { Tags } from '@Pages/Testing/Mobile/Tags/Tags';
import { ChooseDirection } from '@Components/btns';
import { HeartInCircle } from '@Components/btns/ChooseDirection/ChooseDirectionContents/heartInCircle';
import { heartInCircleAdaptive } from '@Components/players/DesktopPlayer/lib/heartInCircleAdaptive';
import { disappearance } from '../../../../utils/helpers/animate/animates';
import { ScreenDesktopDimensions } from '@Constants/';

export const ButtonsBlock = memo((props: ButtonsBlockProps) => {
  const {
    currentContent,
    activeTags,
    onProfClick,
    isAnimateMode,
    videoWatchedCounter,
    displayWidth,
    displayHeight,
  } = props;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const heartMaxWidth = heartInCircleAdaptive(displayHeight, displayWidth, isDesktop).maxWidth;
  const heartHeight = heartInCircleAdaptive(displayHeight, displayWidth, isDesktop).height;

  return (
    <Box sx={[ButtonsBlockSX.container, disappearance(isAnimateMode)]}>
      <Box sx={ButtonsBoxSXMedia.container(displayWidth)}>
        {currentContent && currentContent.videosList.length > 0 && (
          <Box sx={ButtonsBlockSX.tagsBox}>
            <Tags tags={currentContent?.videosList[activeTags].tags} />
          </Box>
        )}

        <Box sx={ButtonsBlockSX.chooseDirectionBox}>
          <ChooseDirection
            onBtnClick={onProfClick}
            type={'mobile'}
            disabled={isAnimateMode}
            sxData={{
              color: currentContent ? currentContent?.icon_color : 'black',
              maxWidth: heartMaxWidth,
              height: heartHeight,
              borderRadius: '50%',
              backgroundColor: 'neutral.white',
            }}>
            <HeartInCircle videoWatchedCounter={videoWatchedCounter} />
          </ChooseDirection>
        </Box>
      </Box>
    </Box>
  );
});

ButtonsBlock.displayName = 'ButtonsBlock';
