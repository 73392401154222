import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { IChooseProf, IGetNextVideos, IVideoData, IVideosList } from '@Pages/Testing';

export const demoTestingApi = createApi({
  reducerPath: 'pairForTesting',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: ['pairForTesting'],
  endpoints: build => ({
    fetchPairForTesting: build.query<IVideoData[], string>({
      query: (uuid: string) => ({
        url: `api/v1/get-profession`,
        params: {
          uuid,
        },
      }),
      providesTags: ['pairForTesting'],
    }),
    chooseProf: build.mutation<IVideoData, IChooseProf>({
      query: ({ uuid, profId }) => ({
        url: `api/v1/choice-profession`,
        params: {
          uuid,
          profession_id: profId,
        },
      }),
      invalidatesTags: ['pairForTesting'],
    }),
    getNextVideos: build.query<IVideosList[], IGetNextVideos>({
      query: ({ uuid, profId, cerebroId }) => ({
        url: `api/v1/get-videos`,
        params: {
          uuid,
          profession_id: profId,
          current_video_cerebro_id: cerebroId,
        },
      }),
    }),
  }),
});

export const { useLazyGetNextVideosQuery, useLazyFetchPairForTestingQuery, useChooseProfMutation } =
  demoTestingApi;
