import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProgressBarProps } from '../ProgressBar.types';
import { ProgressBarDemoMobileSX } from './stylesSX';
import lock from '@Assets/images/icons/lock_L.svg';
import { ProfNameTooltip } from '@Components/tooltips/profName/ProfNameTooltip';

export const ProgressBarDemoMobile = memo((props: ProgressBarProps) => {
  const { progressValue } = props;

  return (
    <Box sx={ProgressBarDemoMobileSX.container}>
      <Box
        sx={[
          ProgressBarDemoMobileSX.block,
          {
            margin: '0 8px',
          },
        ]}>
        <Box
          sx={[
            ProgressBarDemoMobileSX.progressBox,
            {
              width: `${progressValue ? progressValue : 0}%`,
              transition: '1s',
            },
          ]}>
          <Box sx={ProgressBarDemoMobileSX.numBlock}>1</Box>
        </Box>
      </Box>

      <Box sx={[ProgressBarDemoMobileSX.block, ProgressBarDemoMobileSX.blockTwo]}>
        <Box sx={[ProgressBarDemoMobileSX.progressBox, { width: '0%' }]}>
          <Box sx={[ProgressBarDemoMobileSX.numBlock, { color: '#8B8B8B' }]}>2</Box>
          <Box sx={ProgressBarDemoMobileSX.iconsBlock}>
            <ProfNameTooltip title={'После приобретения подписки'} placement={'top-start'}>
              <Box sx={ProgressBarDemoMobileSX.lockBox}>
                <img src={lock} alt='lock' style={ProgressBarDemoMobileSX.lockBox.lock} />
              </Box>
            </ProfNameTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

ProgressBarDemoMobile.displayName = 'ProgressBarDemoMobile';
