import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import { useAuth } from '@Hooks/';
import { redirectAuthPage } from '@Constants/';

export const NoAuthorizedWrapper = () => {
  const { isPaid } = useAuth();
  const outlet = useOutlet();

  if (isPaid) {
    return <Navigate to={redirectAuthPage} />;
  }

  return <>{outlet}</>;
};
