import React, { memo, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { InFullVersionMessageSXMedia } from './stylesSX';
import { ScreenDesktopDimensions } from '@Constants/';
import { InFullVersionMessageProps } from './InFullVersionMessage.type';
import { ChooseDirection } from '@Components/btns';
import { useGetTagQuery } from '@Store/CurrentTag';
import { ThemeLoader } from '@Components/loader/LoaderContainer/AppLoader/AppLoader';
import { LoaderContainer } from '@Components/loader/LoaderContainer/LoaderContainer';
import { MetrikValues, sendMetrik } from '../../../../utils/yaMetrica/metriks';
import { useAppSelector } from '@Hooks/';

export const InFullVersionMessage = memo((props: InFullVersionMessageProps) => {
  const { tag, setIsOpen } = props;
  const tagId = tag.id;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);
  const { data: tagData, isLoading } = useGetTagQuery(tagId);
  const { isLogged } = useAppSelector(state => state.auth);

  useEffect(() => {
    // Отправить метрику
    if (tagData) {
      sendMetrik('reachGoal', MetrikValues.HashTagClicked, { ...tagData, registered: isLogged });
    }
  }, [isLogged, tagData]);

  const onClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LoaderContainer isLoading={isLoading} theme={ThemeLoader.Dark}>
        <Box sx={InFullVersionMessageSXMedia.container(isDesktop)}>
          <Box sx={InFullVersionMessageSXMedia.title(isDesktop)}>#{tagData?.text}</Box>

          <Box sx={InFullVersionMessageSXMedia.message(isDesktop)}>
            Пройдите профориентацию до конца, чтобы получить доступ к {tagData?.count_video} видео
            от {tagData?.count_speaker} профессионалов с тегом #{tagData?.text}!
          </Box>

          <Box sx={InFullVersionMessageSXMedia.btnBox(isDesktop)}>
            <ChooseDirection
              type={'desktop'}
              onBtnClick={onClick}
              sxData={InFullVersionMessageSXMedia.btnData(isDesktop)}>
              <Box sx={InFullVersionMessageSXMedia.btnContent(isDesktop)}>Понятно</Box>
            </ChooseDirection>
          </Box>
        </Box>
      </LoaderContainer>
    </>
  );
});

InFullVersionMessage.displayName = 'InFullVersionMessage';
