import React, { FC, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './AppLoader.module.css';
import { Box, useMediaQuery } from '@mui/material';
import { ScreenDesktopDimensions } from '@Constants/';

export interface VideoLoaderProps {
  isReady: boolean;
  theme?: ThemeLoader;
}

export enum ThemeLoader {
  Light = 'light',
  Dark = 'dark',
}

export enum SizeWrapper {
  M = '196px',
  XL = '400px',
}

export const AppLoader: FC<VideoLoaderProps> = ({ isReady, theme = ThemeLoader.Light }) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  useEffect(() => {
    setIsLoaderVisible(isReady);
  }, [isReady]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: isDesktop ? SizeWrapper.XL : SizeWrapper.M,
      }}>
      <div className={styles.wrap}>
        <CSSTransition
          in={isLoaderVisible}
          timeout={500}
          mountOnEnter
          unmountOnExit
          classNames={{
            enterDone: styles['circle-done'],
            exitDone: styles['circle-exit-done'],
          }}>
          <div className={`${styles.circle} ${styles[theme]}`} />
        </CSSTransition>
      </div>
    </Box>
  );
};
