export enum MetrikValues {
  HashTagClicked = 'HashTagClicked',
  LikeButtonClicked = 'LikeButtonClicked',
  VideoPlayed = 'VideoPlayed',
  VideoPlayClicked = 'VideoPlayClicked',
  LoginClicked = 'LoginClicked',
  InfoIconClicked = 'InfoIconClicked',
  DemoResultReached = 'DemoResultReached',
}

export const sendMetrik = (type: string, value: string, params?: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  ym(90771065, type, value, params);
};
