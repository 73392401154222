import React, { memo, useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { ProfTitleSizeMSX, ProfTitleSizeMSXMedia } from './StylesSX';
import { ProfInfo } from '@Components/tooltips/profInfo/ProfInfo';
import { ProfTitleSizeLProps } from './ProfTitleSizeM.types';
import { ProfIcon } from '@Components/profIcon';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { MetrikValues, sendMetrik } from '../../../utils/yaMetrica/metriks';

export const ProfTitleSizeM = memo((props: ProfTitleSizeLProps) => {
  const { title, icon, description, color, isIconInfo } = props;
  const [isShowInfo, setIsShowInfo] = useState(false);

  const { width: displayWidth, height: displayHeight } = useWindowDimensions();
  const profIconShadowSize = { bot: 0, right: 4 };

  const hideInfo = () => {
    setIsShowInfo(false);
  };

  const handleInfoClick = () => {
    setIsShowInfo(prev => !prev);
    sendMetrik('reachGoal', MetrikValues.InfoIconClicked, { prof_name: title });
  };

  const isInTwoLines = title.length > 15;

  return (
    <Box sx={ProfTitleSizeMSX.titleBlock}>
      <Box sx={ProfTitleSizeMSXMedia.iconBox(displayHeight)}>
        <ProfIcon icon={icon} color={color} shadowSize={profIconShadowSize} />
      </Box>
      <Box sx={ProfTitleSizeMSXMedia.titleBox(color, displayWidth, displayHeight)}>
        <Box sx={ProfTitleSizeMSXMedia.title(isInTwoLines)}>{title}</Box>

        <ClickAwayListener onClickAway={hideInfo}>
          <Box onClick={ isIconInfo ? handleInfoClick : undefined}>
            <ProfInfo isOpen={isShowInfo} text={description} />
          </Box>
        </ClickAwayListener>
      </Box>
    </Box>
  );
});

ProfTitleSizeM.displayName = 'ProfTitleSizeM';
