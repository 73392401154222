import React, { memo, useEffect, useRef, useState } from 'react';
import { IDesktopPlayerContainerProps, IonNextType } from './DesktopPlayer.types';
import { Profession } from '@Components/players';
import { useChooseProfMutation, useLazyGetNextVideosQuery } from '@Store/demoTesting';
import { MetrikValues, sendMetrik } from '../../../utils/yaMetrica/metriks';
import { setCurrentNumLike } from '@Components/players/DesktopPlayer/lib/setCurrentNumLike';
import { resetLocalData } from '@Components/players/DesktopPlayer/lib/resetLocalData';

export const ProfessionContainer = memo((props: IDesktopPlayerContainerProps) => {
  const {
    videoData,
    isLeft,
    isLeftPlayed,
    isRightPlayed,
    onPlaybackControlHandle,
    isAnimateChosenProf,
    animateChosenProf,
    isAnimateMode,
    uuid,
    progressBarData,
    testingType = 'desktop',
    setActiveTags,
    stopPlayback,
  } = props;

  const videosList = videoData?.videosList;
  const id = videoData?.id;

  const [currentVideoIdx, setCurrentVideoIdx] = useState(0);
  const [videos, setVideos] = useState(videosList);
  const [isNextBtnActive, setIsNextBtnActive] = useState(true);
  const [videoWatchedCounter, setVideoWatchedCounter] = useState(3);
  const [isIconInfo, setIsIconInfo] = useState(true);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const [chooseProf] = useChooseProfMutation();
  const [getNextVideos, { data: newVideos, isSuccess }] = useLazyGetNextVideosQuery();

  const { left, right } = isAnimateChosenProf;
  const currentVideoIdxLength = videosList && videosList.length;
  const isPlayed = isLeft ? isLeftPlayed : isRightPlayed;

  const secondsPlayed =()=> localStorage.getItem(
    isLeft
      ? 'leftVideoProgress'
      : 'rightVideoProgress',
  );

  const ymData = (type?:string) => ({
    prof_name: videoData?.title,
    video_id: localStorage.getItem(
      isLeft
        ? 'leftCurrentVideoId'
        : 'rightCurrentVideoId'),
    seconds_played: secondsPlayed() ? secondsPlayed() : 0,
    total_seconds: localStorage.getItem(
      isLeft
        ? 'leftVideoTotalTime'
        : 'rightVideoTotalTime',
    ),
    is_next_btn_press: type === 'click',
  });

  useEffect(() => {
    if (isSuccess && newVideos) {
      setVideos(newVideos);
    }
  }, [isSuccess, newVideos]);

  useEffect(() => {
    videoData && setVideos(videosList);
  }, [videoData, videosList]);

  useEffect(() => {
    progressBarData?.progress_bar.map(prof => {
      if (prof.id === id) setVideoWatchedCounter(3);
    });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [id, progressBarData]);

  useEffect(() => {
    !isNextBtnActive &&
    (timerRef.current = setTimeout(() => {
      setIsNextBtnActive(true);
    }, 1000));
  }, [isNextBtnActive]);

  useEffect(() => {
    !isIconInfo && (timerRef.current = setTimeout(() => {
      setIsIconInfo(true);
    }));
  }, [isIconInfo]);

  const onPausePlayer = () => {
    sendMetrik(
      'reachGoal',
      MetrikValues.VideoPlayClicked,
      ymData(),
    );
  };

  const onProfHandle = () => {
    // Счетчик количества лайков для метрики
    setCurrentNumLike(id);

    // Обнулить счетчик текущего видео профессии
    setCurrentVideoIdx(0);

    // Убрать иконку информации перед анимацией выбора профессии
    setIsIconInfo(false);
    // Запустить анимацию вывода в центр блока выбранной профессии
    // Таймаут чтобы убрать иконку информации
    const animate = isLeft
      ? { ...isAnimateChosenProf, left: !left }
      : { ...isAnimateChosenProf, right: !right };

    setTimeout(() => {
      animateChosenProf(animate);
    });

    // Остановить воспроизведение видео
    stopPlayback();
    // Запросить новую пару профессий
    id && chooseProf({ uuid, profId: id });
  };

  const onNext = (type: IonNextType) => {
    // отправить метрику
    if (type === 'click') {
      // console.log(ymData('click'), isLeft);
      sendMetrik('reachGoal', MetrikValues.VideoPlayClicked, {
        ...ymData(),
        is_next_btn_press: type === 'click',
      });
    }
    // Очистить просмотренное время по видео профессии в localStorage
    resetLocalData(isLeft ? 'leftVideoProgress' : 'rightVideoProgress')

    // отключить срабатывание кнопки
    setIsNextBtnActive(false);
    setActiveTags && setActiveTags(currentVideoIdx);

    const currentVideoIdxValue =
      currentVideoIdxLength && currentVideoIdx === currentVideoIdxLength - 1
        ? 0
        : currentVideoIdx + 1;
    setCurrentVideoIdx(currentVideoIdxValue);

    // Удалить из массива с видео текущий видос(для срабатывания анимации перелистывания видео)
    const newList = videosList &&
      videos && [...videos.filter(video => video.id !== videosList[currentVideoIdx].id)];
    videos && setVideos(newList);

    // Запросить следующую пару видео
    const cerebroId = videos && videos[0].cerebro_id;
    const profId = id;
    profId && cerebroId && getNextVideos({ uuid, profId, cerebroId });
  };

  const onPlaybackControl = () => {
    onPlaybackControlHandle(isLeft);
  };

  return (
    <>
      {videoData && videos && (
        <Profession
          videoData={videoData}
          isLeft={isLeft}
          isIconInfo={isIconInfo}
          isAnimateChosenProf={isAnimateChosenProf}
          animateChosenProf={animateChosenProf}
          isAnimateMode={isAnimateMode}
          videos={videos}
          onNextClick={onNext}
          isPlayed={isPlayed}
          onPlaybackControl={onPlaybackControl}
          onProfHandle={onProfHandle}
          videoWatchedCounter={videoWatchedCounter}
          isNextBtnActive={isNextBtnActive}
          currentVideoIdx={currentVideoIdx}
          testingType={testingType}
          onPausePlayer={onPausePlayer}
        />
      )}
    </>
  );
});

ProfessionContainer.displayName = 'DesktopPlayerContainer';
