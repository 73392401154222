import { IsTables } from '../../../../utils/helpers/isTables';

export const ButtonsBlockSX = {
  nextBtn: {
    position: 'absolute',
    zIndex: 151,
    transition: 'all 0.5s',
  },
};

export const ButtonsBlockSXMedia = {
  container: (displayHeight: number, displayWidth: number, isDesktop: boolean, top: string, width: string) => {
    const topValue = () => {
      const corrector = IsTables(displayHeight, displayWidth) ? '0px' : '30px';
      return `calc(${top} + ${corrector})`;
    };

    return ({
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      zIndex: 151,
      top: isDesktop ? top : topValue(),
      width: width,
    });
  },
  chooseDirectionBox: (displayHeight: number, isDesktop: boolean) => {
    const translateX = (): string => {
      switch (true) {
        case displayHeight < 500:
          return '-45px';
        case displayHeight < 540:
          return '-35px';
        case displayHeight < 630:
          return '-25px';
        default:
          return '0';
      }
    };
    return {
      transform: displayHeight < 700 && isDesktop ? `translate( ${translateX()},13px)` : '',
      zIndex: 155,
    };
  },
  nextBtn: (displayHeight: number) => {
    if (displayHeight > 700) {
      return {
        height: '112px',
        width: '223px',
        transform: displayHeight > 794 ? 'translate(85%, -2px)' : 'translate(85%, 4px)',
        '&:hover': {
          transform: displayHeight > 794 ? 'translate(88%,-2px)' : 'translate(88%,4px)',
        },
      };
    } else {
      return {
        transform: 'translate(5%, 29px)',
        '&:hover': {
          transform: 'translate(8%,29px)',
        },
      };
    }
  },
  tagsBlock: (
    buttonsBlockTop: string,
    heartHeight: string,
    displayHeight: number,
    isDesktop: boolean,
  ) => {
    const correct = (): string => {
      switch (true) {
        case isDesktop && displayHeight < 500:
          return '20px';
        case isDesktop && displayHeight < 700:
          return '10px';
        default:
          return '-3px';
      }
    };
    return {
      top: `calc(${correct()} + ${buttonsBlockTop} + ${heartHeight})`,
      position: 'absolute',
      width: '50%',
      maxHeight: '59px',
      display: '-webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical',
    };
  },
  tagsBox: (isAnimateMode: boolean) => ({
    display: isAnimateMode ? 'none' : 'flex',
    justifyContent: 'center',
  }),
};
