import { SlideSXMedia } from '@Components/modals/modalsContent/Сlue/Slide/stylesSX';

export const ClueSXMedia = {
  container: (isDesktop: boolean) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: isDesktop ? '5px' : '0',
  }),
  desc: (isDesktop: boolean, displayHeight: number, displayWidth: number) => {
    const heightCorrector = isDesktop ? '90px' : '63px';
    // Вариант без текста
    return ({
      maxHeight: `calc(${SlideSXMedia.gifMaxHeight(displayHeight, displayWidth, isDesktop)} + ${heightCorrector})`,
    });
  },
};
