export const iconBoxWidth = (displayHeight: number) => {
  switch (true) {
    case displayHeight < 650:
      return '50px';
    case displayHeight < 800:
      return '60px';
    case displayHeight < 900:
      return '70px';
    default:
      return '80px';
  }
};

export const ProfTitleSizeMSX = {
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 151,
    position: 'absolute',
    top: '15px',
  },
};

export const ProfTitleSizeMSXMedia = {
  titleBox: (color: string, displayWidth: number, displayHeight: number) => {
    const fontSize = () => {
      switch (true) {
        case displayHeight < 650:
          return '.7vw';
        case displayHeight < 730:
          return '.9vw';
        default:
          return displayWidth > 1800 ? '18px' : '1vw';
      }
    };

    const maxWidth = () => {
      switch (true) {
        case displayHeight < 650:
          return '110px';
        case displayHeight < 730:
          return '140px';
        case displayHeight < 840:
          return '170px';
        case displayHeight < 880:
          return '200px';
        default:
          return '231px';
      }
    };
    return {
      boxShadow: `4px 4px 0px 0px ${color}`,
      fontSize: fontSize(),
      maxWidth: maxWidth(),
      height: `calc(${iconBoxWidth(displayHeight)} - 29px)`,
      fontWeight: '700',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'neutral.dark',
      width: '15vw',
      backgroundColor: 'neutral.white',
      borderRadius: '0 16px 16px 0',
      border: '2px solid #000000',
      zIndex: 1,
      transform: 'translate(4px)',
    };
  },
  iconBox: (displayHeight: number) => ({
    width: iconBoxWidth(displayHeight),
    height: iconBoxWidth(displayHeight),
    transform: 'translateY(2px)',
  }),
  title: (isInTwoLines: boolean | undefined) => ({
    lineHeight: '16px',
    paddingLeft: '18px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '37px',
    display: isInTwoLines
      ? '-webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical'
      : 'flex',
    alignItems: 'center',
    whiteSpace: isInTwoLines ? 'nowrap' : 'normal',
  }),
};
