import React, { FC, PropsWithChildren, useCallback } from 'react';
import styles from './DesktopPlayerAnimate.module.css';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { IProfSelectAnimation } from './ProfSelectAnimationContainer.types';

export const ProfSelectAnimationContainer: FC<PropsWithChildren<IProfSelectAnimation>> = props => {
  const {
    children,
    isLeft,
    isAnimateChosenProf,
    setIsAnimateChosenProf,
  } = props;
  const { left, right } = isAnimateChosenProf;

  // Запустить анимацию не выбранного блока
  const styleContainer = useCallback(() => {
    if (!isLeft && left) return styles.transition_left_box_none;
    if (isLeft && right) return styles.transition_right_box_none;
    return styles.transition_box;
  }, [left, right]);

  const activeStyle = styleContainer();

  // Отрисовать блоки после анимации выбора
  const showBlocks = () => {
    setIsAnimateChosenProf({ left: false, right: false });
  };

  const activeAnimateClass = isLeft ? styles['container-left'] : styles['container-right'];

  return (
    <div className={activeStyle}>
      <SwitchTransition>
        <CSSTransition
          key={isLeft ? left : right}
          timeout={3000}
          mountOnEnter
          unmountOnExit
          onExited={showBlocks}
          classNames={{
            exitActive: activeAnimateClass,
          }}>
          {children}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
