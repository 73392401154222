export const containerWidth = (displayWidth: number, displayHeight: number, isDesktop: boolean) => {
  switch (true) {
    case !isDesktop:
      return '100%';
    case displayHeight < 700:
      return '50%';
    case displayWidth < 800:
      return '15%';
    case displayWidth < 880:
      return '20%';
    case displayWidth < 980:
      return '23%';
    case displayWidth < 1010:
      return '25%';
    case displayWidth < 1113:
      return '30%';
    case displayWidth < 1230:
      return '35%';
    case displayWidth < 1295:
      return '40%';
    case displayWidth < 1370:
      return '42%';
    case displayWidth < 1550:
      return '45%';
    default:
      return '50%';
  }
};
