import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { NestedModalSX, NestedModalSXMedia } from './NestedModalSX';
import closeIconXL from '@Assets/images/icons/closeXL.svg';
import closeIconL from '@Assets/images/icons/closeL.svg';
import { INestedModal } from './NestedModal.type';
import { useMediaQuery } from '@mui/material';
import { ScreenDesktopDimensions } from '@Constants/';

export const NestedModal: FC<PropsWithChildren<INestedModal>> = props => {
  const { children, open, setIsConfirmModal, type } = props;

  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  const onCloseClick = () => {
    setIsConfirmModal(!open);
  };

  return (
    <Modal
      open={open}
      onClose={onCloseClick}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'>
      <Box sx={[NestedModalSX.modal, NestedModalSXMedia.modal(isDesktop, type)]}>
        <Box
          onClick={onCloseClick}
          sx={[NestedModalSX.closeIconBox, NestedModalSXMedia.closeIconMargin(isDesktop)]}>
          <img
            src={isDesktop ? closeIconXL : closeIconL}
            alt='close'
            style={{
              width: isDesktop ? '30px' : '13px',
            }}
          />
        </Box>
        {children}
      </Box>
    </Modal>
  );
};
