import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from './app-selector';
import { useActions } from './actions';
import { redirectAuthPage, redirectPage } from '@Constants/';
import { IAuthParams, ILocationState } from '@Models/';
import { useLoginMutation } from '@Store/auth';

export const useAuth = () => {
  const { setTokens, deleteTokens } = useActions();
  const { isLogged, isPaid } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const { state } = useLocation() as { state: ILocationState };
  const [login] = useLoginMutation();

  const handleLogin = async (params: IAuthParams) => {
    const tokens = await login(params).unwrap();
    setTokens(tokens);
    navigate(state?.path || redirectAuthPage);
  };

  const handleLogout = async () => {
    await deleteTokens();
    navigate(redirectPage, { replace: true });
  };

  return useMemo(
    () => ({
      isLogged,
      isPaid,
      handleLogin,
      handleLogout,
    }),
    [isLogged, isPaid],
  );
};
