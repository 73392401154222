import React, { memo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ProfChoiceAnimationBackgroundSX } from './stylesSX';
import { IProfChoiceAnimationBackground } from './ProfChoiceAnimationBackground.type';
import Lottie from 'lottie-react';
import sparks from '@Assets/jsonAnimations/animation_sparks.json';
import stars from '@Assets/jsonAnimations/animation_stars.json';
import cls from './ProfChoiceAnimationBackground.module.css';
import { ScreenDesktopDimensions } from '@Constants/';
import { DesktopProfChoiceAnimation } from '@Components/profChoiceAnimationBackground/DesktopAnimation/DesktopAnimation';
import { DesktopProfChoiceAnimationSX } from '@Components/profChoiceAnimationBackground/DesktopAnimation/stylesSX';
import { useTranslation } from 'react-i18next';

export const ProfChoiceAnimationBackground = memo((props: IProfChoiceAnimationBackground) => {
  const { isAnimateMode } = props;

  const { t }= useTranslation()
  const isDesktop = useMediaQuery(ScreenDesktopDimensions.minWidth);

  return (
    <Box
      sx={
        isAnimateMode
          ? ProfChoiceAnimationBackgroundSX.backgroundAnimate
          : ProfChoiceAnimationBackgroundSX.none
      }>
      {!isDesktop && (
        <Box
          sx={[
            DesktopProfChoiceAnimationSX.massageBox,
            DesktopProfChoiceAnimationSX.mobileMessageBox,
          ]}>
          <div className={cls.mobile_title}>{t('Вы выбрали')}</div>
        </Box>
      )}

      <Lottie animationData={stars} className={cls.lotti} />
      <Lottie animationData={sparks} className={cls.lotti} />

      {isDesktop && <DesktopProfChoiceAnimation />}
    </Box>
  );
});

ProfChoiceAnimationBackground.displayName = 'ProfChoiceAnimationBackground';
