import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { Dispatch, SetStateAction } from 'react';

export const errorHandle = (
  error: FetchBaseQueryError | SerializedError | undefined,
  setErrMessage: Dispatch<SetStateAction<undefined>>,
) => {
  if (error && 'data' in error) {
    const stringErr = JSON.stringify(error.data);
    setErrMessage(JSON.parse(stringErr).detail);
  }
};
