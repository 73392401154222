export const SlideSX = {
  slideBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
};
export const SlideSXMedia = {
  gifMaxHeight: (displayHeight: number, displayWidth: number, isDesktop: boolean) => {
    const desktop = () => {
      switch (true) {
        case displayHeight < 720:
          return '290px';
        case displayHeight < 740:
          return '310px';
        case displayHeight < 760:
          return '330px';
        case displayHeight < 780:
          return '350px';
        case displayHeight < 800:
          return '370px';
        case displayHeight < 820:
          return '390px';
        case displayHeight < 850:
          return '420px';
        case displayHeight < 880:
          return '450px';
        case displayHeight < 910:
          return '480px';
        default:
          return '509px';
      }
    };
    const mobile = () => {
      switch (true) {
        case displayHeight < 490:
          return '286px';
        default:
          return '324px';
      }
    };
    return isDesktop ? desktop() : mobile();
  },
  description: (displayHeight: number, displayWidth: number, isDesktop: boolean) => {
    const margin = () => {
      if (isDesktop) {
        return '0 0 40px';
      }
      return '5px';
    };
    const fontData = () => {
      const desktop = () => {
        switch (true) {
          case displayHeight < 750:
            return {
              fontSize: '18px',
              lineHeight: '20px',
            };
          case displayHeight < 760:
            return {
              fontSize: '20px',
              lineHeight: '22px',
            };
          case displayHeight < 770:
            return {
              fontSize: '22px',
              lineHeight: '24px',
            };
          default:
            return {
              fontSize: '24px',
              lineHeight: '26px',
            };
        }
      };
      const mobile = {
        fontSize: '10px',
        lineHeight: '12px',
      };
      return isDesktop ? desktop() : mobile;
    };
    return {
      textAlign: 'center',
      color: 'neutral.dark',
      fontWeight: 700,
      fontSize: fontData().fontSize,
      lineHeight: fontData().lineHeight,
      margin: margin(),
    };
  },
  gif: (isDesktop: boolean, displayHeight: number) => {
    const margin = () => {
      switch (true) {
        case displayHeight < 800:
          return '20px';
        default:
          return '50px';
      }
    };
    return {
      margin: isDesktop ? `50px 0 ${margin()}` : '25px 0 15px',
      padding: isDesktop ? '10px' : 0,
      display: 'flex',
      justifyContent: 'center',
    };
  },
};
