export const InFullVersionBlockSXMedia = {
  paragraphBox: (displayHeight: number, isDesktop: boolean) => {
    return {
      display: 'flex',
      paddingBottom: displayHeight < 890 ? '5px' : '26px',
      fontWeight: isDesktop ? 700 : 400,
    };
  },
  paragraph: (isDesktop: boolean) => ({
    color: '#000000',
    paddingLeft: isDesktop ? '32px' : '17px',
  }),
};
