import React from 'react';
import './App.css';
import { AppRouter } from '@Components/AppRouter/AppRouter';

export const App = () => {
  return (
    <>
      <AppRouter />
    </>
  );
};
