import React, { memo } from 'react';
import { Box } from '@mui/material';
import { PlayerBlockProps } from './PlayerBlock.type';
import { ProfSelectAnimationContainer } from '@Components/players/DesktopPlayer/ProfSelectAnimationContainer/desktop/ProfSelectAnimationContainer';
import { ProfessionSX } from '@Components/players/DesktopPlayer/StylesSX';
import { ProfTitleSizeM } from '@Components/titleProfs/ProfTitleSizeM';
import { PlayersDeckContainer } from '@Components/animatePlayersDeck/PlayersDeckContainer';
import { disappearance } from '../../../../utils/helpers/animate/animates';

export const VideoBlock = memo((props: PlayerBlockProps) => {
  const {
    isAnimateChosenProf,
    animateChosenProf,
    isLeft,
    isIconInfo,
    title,
    icon_image,
    videos,
    onNextClick,
    isPlayed,
    onPlaybackControl,
    desc,
    icon_color,
    testingType,
    isAnimateMode,
    onPausePlayer,
  } = props;

  return (
    <Box>
      <ProfSelectAnimationContainer
        isAnimateChosenProf={isAnimateChosenProf}
        setIsAnimateChosenProf={animateChosenProf}
        isLeft={isLeft}
      >
        <Box sx={ProfessionSX.video_block}>
          {testingType === 'desktop' &&
          <ProfTitleSizeM isIconInfo={isIconInfo} title={title} icon={icon_image}
                          description={desc}
                          color={icon_color} />
          }
        </Box>
      </ProfSelectAnimationContainer>


      <Box sx={disappearance(isAnimateMode)}>
        <PlayersDeckContainer
          videos={videos}
          onEnded={onNextClick}
          isPlayed={isPlayed}
          playbackControl={onPlaybackControl}
          isAnimateMode={isAnimateMode}
          onPausePlayer={onPausePlayer}
          isLeft={isLeft}
        />
      </Box>
    </Box>
  );
});

VideoBlock.displayName = 'PlayerBlock';
